/* eslint-disable import/no-anonymous-default-export */
import React, { useState, Fragment, useEffect, Suspense } from "react";
import "./app.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardPage from "./containers/DashboardPage/dashboardPage";
import ProjectPage from "./containers/ProjectPage/projectPage";
import Analytics from "./containers/Analytics";
import AuthPage from "./containers/AuthPage";
import PO from "./containers/PO/PO.js";
import SuggestiveItems from "./containers/SuggestiveItems/SuggestiveItems.js";
import { UserContext } from "./Context/UserContext";
import { RemoteConfigContextProvider } from "./Context/RemoteConfig";
import { AnalyticsContextProvider } from "./Context/Analytics";
import { ToastContainer } from "react-toast";
import { Toaster } from "react-hot-toast";
import NotificationsView from "./components/Notifications";
import ProjectAnalytics from "./containers/ProjectAnalytics";
import UnreleasedItems from "./containers/ProjectPage/unreleasedItems";
import ReleasedOrders from "./containers/ProjectPage/releasedOrders";
import TimelineView from "./containers/ProjectPage/timelineView";
import OrdersQuotations from "./containers/OrdersQuotations/OrdersQuotations";
import finance from "./containers/Finance/finance";
import Archived from "./containers/Archived/archivedProjects";
import AuthService from "./api/authService";
import FinanceReqRec from "./containers/FinanceReqRec/FinanceReqRec";
import POAnalytics from "./containers/POAnalytics/POAnalytics";


export default () => {

  const [user, setUser] = useState(undefined);

  const onAuthChanged = (data) => setUser(data);

  const checkNotificationPermission = async () => {

    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("GRANTED");
      } else {
      }
    }
    
  };

  useEffect(() => {
    checkNotificationPermission();
    const _user =
      sessionStorage.getItem("User") || localStorage.getItem("User");
    if (_user) {
      let temp = JSON.parse(_user);
      const authService = new AuthService();
      authService
        .getEmailByEmail(temp.email)
        .then((res) => {
          onAuthChanged(res.payload[0]);
        })
        .catch((e) => {
          onAuthChanged(temp);
        });
    } else {
      onAuthChanged(null);
    }
  }, []);

  return (
    <div>
      <AnalyticsContextProvider>
        <RemoteConfigContextProvider>
          <UserContext.Provider value={{ user, setUser }}>
            <Router>
              <Switch>
                {user === undefined ? null : user ? (
                  <Fragment>
                    <Route
                      path="/"
                      exact
                      render={(props) => (
                        <DashboardPage
                          onAuthChanged={onAuthChanged}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/ProjectPage/:ProjectId"
                      exact
                      component={ProjectPage}
                    />
                    <Route
                      path="/UnreleasedItems/:ProjectId"
                      exact
                      render={(props) => <UnreleasedItems {...props} />}
                    />
                    <Route
                      path="/ReleasedOrders/:ProjectId"
                      exact
                      render={(props) => <ReleasedOrders {...props} />}
                    />
                    <Route
                      path="/Timeline/:ProjectId"
                      exact
                      render={(props) => <TimelineView {...props} />}
                    />
                    <Route
                      path="/OrdersQuotations/:ProjectId"
                      exact
                      render={(props) => <OrdersQuotations {...props} />}
                    />
                    <Route
                      path="/Finance/:ProjectId"
                      exact
                      component={finance}
                    />
               
                    <Route
                      path="/Archived"
                      exact
                      render={(props) => (
                        <Archived onAuthChanged={onAuthChanged} {...props} />
                      )}
                    />
                    <Route
                      path="/Analytics"
                      exact
                      render={(props) => (
                        <Analytics onAuthChanged={onAuthChanged} {...props} />
                      )}
                    />
                    <Route path="/PO" exact component={PO} />
                    <Route
                      path="/SuggestiveItems"
                      exact
                      render={(props) => (
                        <SuggestiveItems
                          onAuthChanged={onAuthChanged}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/FinanceRecords"
                      exact
                      render={(props) => (
                        <FinanceReqRec
                          onAuthChanged={onAuthChanged}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/POAnalytics"
                      exact
                      render={(props) => (
                        <POAnalytics
                          onAuthChanged={onAuthChanged}
                          {...props}
                        />
                      )}
                    />
                    <Route path="/PO/:OrderId" exact component={PO} />
                    <Route
                      path="/Analytics/:ProjectId"
                      exact
                      render={(props) => (
                        <ProjectAnalytics
                          onAuthChanged={onAuthChanged}
                          {...props}
                        />
                      )}
                    />
                  </Fragment>
                ) : user == null ? (
                  <Fragment>
                    <Route
                      path="/"
                      exact
                      render={(props) => (
                        <AuthPage onAuthChanged={onAuthChanged} {...props} />
                      )}
                    />
                  
                    <Route path="/PO/:OrderId" exact component={PO} />
                  </Fragment>
                ) : null}
              </Switch>
              <ToastContainer delay={2000} position="bottom-center" />
              <Toaster />
              {user && <NotificationsView user={user} />}
            </Router>
          </UserContext.Provider>
        </RemoteConfigContextProvider>
      </AnalyticsContextProvider>
    </div>
  );
};
