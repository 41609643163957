/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from "react";
import "./index.scss";
import { FaBell, FaChevronRight } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import firebase from "../../config/firebase";
import getDateDiffernce from "../../helpers/getDateDiffernce";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { useHistory, useLocation } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import { requestForToken, onMessageListener } from "../../config/firebase";

export default ({ user }) => {
  const [containerWidth, setContainerWidth] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState("General");
  const history = useHistory();
  const readNotificationCount = useRef(null);
  const { pathname } = useLocation();
  const [notificationLabel, setNotificationLabel] = useState(0);

  const [messageNotifications, setMessageNotifications] = useState([]);

  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />, { position: "top-right" });
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    // checkNotificationPermission();
    const unSubscribenotificaiton = firebase
      .messaging()
      .onMessage((payload) => {
        console.log("payload", payload);
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
        // resolve(payload);
      });

    return () => {
      unSubscribenotificaiton();
    };
  }, []);
  useEffect(() => {
    if (notification?.title) {
      notify();
      setMessageNotifications((prev) => {
        return [notification, ...prev];
      });
    }
  }, [notification]);



  useEffect(() => {
    // console.log("user", user);
    if (user && !pathname?.includes("PO")) {
      firebase
        .database()
        .ref(
          "BuildNotifications/" +
            `${user.Firm}` +
            "/" +
            `${user.accountType}` +
            "/" +
            "notifications/"
        )
        .limitToLast(30)
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            setNotifications([]);
            setNotifications(() => [
              ...Object.values(snapshot.val()).reverse(),
            ]);
          }
        });
    }
  }, [user]);

  useEffect(() => {
     if (notifications.length > 0) {
      firebase
        .database()
        .ref(
          "BuildNotifications/" +
            `${user.Firm}` +
            "/" +
            `${user.accountType}` +
            "/" +
            "count/"
        )
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            readNotificationCount.current = snapshot.val().read;
          }
        });
    }
  }, [notifications]);

  useEffect(() => {
    if (notifications) {
      setNotificationLabel(
        notifications.length - readNotificationCount.current
      );
    }
  }, [readNotificationCount.current, notifications]);

  useEffect(() => {
    if (containerWidth) {
      firebase
        .database()
        .ref(
          "BuildNotifications/" +
            `${user.Firm}` +
            "/" +
            `${user.accountType}` +
            "/" +
            "count/"
        )
        .set({ read: notifications.length });
    }
  }, [containerWidth]);

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  return (
    <div id={pathname?.includes("PO") ? "displayNone" : "notification"}>
      <div
        id="notification_icon"
        onClick={() => setContainerWidth((prevSt) => !prevSt)}
      >
        {containerWidth ? (
          <RiCloseLine color="white" size={25} />
        ) : (
          <IconButton aria-label={notificationsLabel(100)}>
            <Badge
              badgeContent={notificationLabel != 0 ? notificationLabel : null}
              color="secondary"
            >
              <FaBell color="white" />
            </Badge>
          </IconButton>
        )}
      </div>
      {containerWidth && (
        <div
          id="notification_container"
          style={{ width: 340, padding: !!containerWidth && 20 }}
        >
          <div>Notifications</div>
          <div>
            <div
              className={filter === "General" ? "active" : ""}
              onClick={() => setFilter("General")}
            >
              <div>General</div>
              <div>{notifications.length}</div>
            </div>
            <div
              className={filter === "Messages" ? "active" : ""}
              onClick={() => setFilter("Messages")}
            >
              <div>Messages</div>
              <div>
                {/* {
                  notifications.filter((not) => not.sectionSource === "BOQ")
                    .length
                } */}
              </div>
            </div>
            {/* <div
              className={filter === "Finance" ? "active" : ""}
              // onClick={() => setFilter("Finance")}
            >
              <div>Finance</div>
              <div>
                {
                  notifications.filter((not) => not.sectionSource === "Finance")
                    .length
                }
              </div>
            </div> */}
          </div>
          <div>
            <div
              className="title"
              // onClick={() => {
              //   firebase
              //     .database()
              //     .ref("notifications")
              //     .orderByChild("destSource")
              //     .equalTo(user.Firm)
              //     .once("value", (snapshot) => {
              //       snapshot.forEach((snap) => {
              //         let clear = snap.key;
              //         if (clear) {
              //           firebase
              //             .database()
              //             .ref("notifications")
              //             .child(clear)
              //             .remove();
              //         }
              //       });
              //     });
              // }}
            >
              {/* Clear All */}
            </div>
            {filter === "General" &&
              notifications
                // .filter((not) => {
                //   if (
                //     filter === "General" ||
                //     (filter === "BOQ" && not.sectionSource === "BOQ") ||
                //     (filter === "Finance" && not.sectionSource === "Finance")
                //   )
                //     return true;
                //   return false;
                // })
                .map((notification, index) => {
                  let str = notification.message;
                  // const words = str.split(" ");
                  // console.log('words[6]', words[6]);
                  return (
                    <div
                      key={index}
                      className="notification_card"
                      onClick={() => {
                        if (notification.sectionSource === "Request") {
                          history.push("/FinanceRecords", {
                            data: notification,
                          });
                        }
                        // window.open(`/PO/${words[6]}`);
                      }}
                    >
                      <div>
                        <div>{notification.sectionSource}</div>
                        <FaChevronRight />
                        <div>{notification.projectName}</div>
                      </div>
                      <div>{notification.message}</div>
                      <div>
                        <div>
                          {getDateDiffernce(
                            new Date(Number(notification.time))
                          )}
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  );
                })}
            {filter === "Messages" &&
              messageNotifications.map((message, index) => {
                return (
                  <div
                    key={index}
                    className="notification_card"
                    // onClick={() => {
                    //   if (notification.sectionSource === "Request") {
                    //     history.push("/FinanceRecords", { data: notification });
                    //   }
                    //   // window.open(`/PO/${words[6]}`);
                    // }}
                  >
                    <div>
                      {/* <div>{notification.sectionSource}</div> */}
                      <FaChevronRight />
                      <div>{message.title}</div>
                    </div>
                    <div>{message.body}</div>
                    <div>
                      <div>
                        {/* {getDateDiffernce(new Date(Number(notification.time)))} */}
                      </div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};
