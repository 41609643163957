import React, { useContext, useEffect, useState } from "react";
import OpportunityService from "../../api/opportunityService";
import { UserContext } from "../../Context/UserContext";
import Req_Rec_Card from "./Req_Rec_Card";
import { filterData } from "./Requests_Fin";
import { CircularProgress } from "@material-ui/core";
import { Tabs } from "../../components";

const styleButton = {
  border: "none",
  background: "none",
  cursor: "pointer",
  fontWeight: "bold",
  fontSize: "1em",
  padding: "10px",
  borderRadius: "10px",
};
const RequestFinAnalytics = ({
  isRecord,
  loading,
  daysFilter,
  datatoDisplay,
  setDataToDisplay,
  setDaysFilter,
}) => {
    return (
    <>
      <DaysFilter
        daysFilter={daysFilter}
        setDaysFilter={setDaysFilter}
        isRecord={isRecord}
      />
      <div style={{ height: "67vh", overflow: "scroll" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{ width: "30px", height: "30px", color: "#fdd34d" }}
            />
          </div>
        ) : (
          <>
            {datatoDisplay.length === 0 ? (
              <h4 style={{ textAlign: "center", margin: "1em" }}>
                Nothing to show
              </h4>
            ) : ( datatoDisplay.map((request, rIndex) => (
                // eslint-disable-next-line react/jsx-pascal-case
                <Req_Rec_Card
                  key={rIndex}
                  request={request}
                  index={rIndex}
                  isRecord={true}
                  setDataToDisplay={setDataToDisplay}
                />
              ))
            )}
          </>
        )}
      </div>
    </>
  );
};

const DaysFilter = ({ daysFilter, setDaysFilter, isRecord }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "93%",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "1em 2.5em",
      }}
    >
      <button
        style={{
          ...styleButton,
          color: "green",
          border: `${daysFilter === "24" ? "1px solid green" : "none"}`,
        }}
        onClick={() => {
          setDaysFilter((prev) => {
            if (prev === "24") {
              return "All";
            }
            return "24";
          });
        }}
      >
        {isRecord ? "Paid" : "Approved"} WithIn 24HRS
      </button>
      <button
        style={{
          ...styleButton,
          color: "steelblue",
          border: `${daysFilter === "48" ? "1px solid darkgray" : "none"}`,
        }}
        onClick={() => {
          setDaysFilter((prev) => {
            if (prev === "48") {
              return "All";
            }
            return "48";
          });
        }}
      >
        {" "}
        {isRecord ? "Paid" : "Approved"} WithIn 48 HRS{" "}
      </button>
      <button
        style={{
          ...styleButton,
          color: "red",
          border: `${daysFilter === "72" ? "1px solid red" : "none"}`,
        }}
        onClick={() => {
          setDaysFilter((prev) => {
            if (prev === "72") {
              return "All";
            }
            return "72";
          });
        }}
      >
        {isRecord ? "Paid" : "Approved"} After 48 HRS
      </button>
    </div>
  );
};

const FinanceReqRecAnalytics = () => {
  const [daysFilter, setDaysFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("Requests");
  const [rawData, setRawData] = useState([]);
  const [DateRangeValue, setDateRangeValue] = useState([null, null]);
  const [datatoDisplay, setDataToDisplay] = useState([]);
  const userContext = useContext(UserContext);

  const getFinanceAnalytics = async (isRequest) => {
    setLoading(true);
    const opportunityService = new OpportunityService();
    const financeReqRecAnalytics = await opportunityService
      .getFinanceReqRecAnalytics(userContext.user.Firm, isRequest)
      .then((res) => {
        console.log(res);
        if (res.status === 200 && res.results) {
          setRawData(res.results);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const daysFiltering = (selectedDaysFilter) => {
    let filteredData = [];
    if (selectedDaysFilter === "24") {
      console.log("filtering 24");
      filteredData = rawData.filter((request) => {
        console.log(request.houred);
        return request.houred < 24;
      });
    } else if (selectedDaysFilter === "48") {
      filteredData = rawData.filter((request) => {
        return request.houred < 48 && request.houred > 24;
      });
    } else if (selectedDaysFilter === "72") {
      filteredData = rawData.filter((request) => {
        return request.houred > 48;
      });
    } else {
    }
    console.log("filtered Data", filteredData);
    const newDataFiltered = filterData(
      filteredData,
      "All",
      "All",
      [null, null],
      "All"
    );

    setDataToDisplay(newDataFiltered);
  };

  useEffect(() => {
    if (daysFilter === "All") {
      const newDataFiltered = filterData(
        rawData,
        "All",
        "All",
        [null, null],
        "All"
      );
      setDataToDisplay(newDataFiltered);
    } else {
      daysFiltering(daysFilter);
    }
  }, [daysFilter]);

  useEffect(() => {
    setDaysFilter("All");
    if (activeTab === "Requests") {
      getFinanceAnalytics(true);
    } else {
      getFinanceAnalytics(false);
    }
  }, [activeTab]);
  useEffect(() => {
    if (rawData) {
      const filteredData = filterData(
        rawData,
        "All",
        "All",
        DateRangeValue,
        "All"
      );
      console.log("project wiser Data", filteredData);
      setDataToDisplay(filteredData);
    }
  }, [rawData, DateRangeValue]);

  return (
    <div>
      <Tabs
        tabs={["Approvals", "Records"]}
        activeTab={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
        }}
      >
        {activeTab === "Requests" ? (
          <RequestFinAnalytics
            daysFilter={daysFilter}
            setDaysFilter={setDaysFilter}
            loading={loading}
            setDataToDisplay={setDataToDisplay}
            datatoDisplay={datatoDisplay}
          />
        ) : (
          <RequestFinAnalytics
            isRecord={true}
            daysFilter={daysFilter}
            setDaysFilter={setDaysFilter}
            loading={loading}
            setDataToDisplay={setDataToDisplay}
            datatoDisplay={datatoDisplay}
          />
        )}
      </Tabs>
    </div>
  );
};

export default FinanceReqRecAnalytics;
