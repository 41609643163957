// import "antd/dist/antd.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Req_Rec_Card.scss"; // some styles here
import Req_Rec_Card from "./Req_Rec_Card";
import OpportunityService from "../../api/opportunityService";
import { UserContext } from "../../Context/UserContext";
import { useLocation } from "react-router-dom";
// import { DatePicker, Space } from "antd";
import CustomDateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { DropDownMUI } from "../../components";
import config from "../../config/config";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
  View,
  Text,
  Link,
} from "@react-pdf/renderer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const isDateLiesCheck = (currentItemDate, DateRangeValue) => {
  console.log(
    "checking date for curent elemt",
    new Date(currentItemDate).getTime(),
    new Date(DateRangeValue[0]).getTime(),
    new Date(DateRangeValue[1]).getTime()
  );
  if (
    new Date(currentItemDate).getTime() >=
    new Date(DateRangeValue[0]).getTime() &&
    new Date(currentItemDate).getTime() <= new Date(DateRangeValue[1]).getTime()
  ) {
    console.log("current elemnt lies in btw");
    return true;
  }
  return false;
};

const SelectedRequestsPDF = ({ selectedRequests, sumOfSelectedRequests }) => {
  const styles = StyleSheet.create({
    page: {
      marginTop: 20,
      fontSize: 12,
      width: "95%",
      display: "flex",
      // margin: "auto",
      // alignItems: "center",
      flexDirection: "column",
    },
    title: {
      backgroundColor: "#26428b",
      padding: 2,
      fontWeight: "bold",
      width: "100%",
    },
    // header: {
    //   display: "flex",
    //   width: "100%",
    //   flexDirection: "row",
    //   justifyContent: "space-between",
    //   borderStyle: "solid",
    //   borderBottomWidth: 3,
    //   borderBottomColor: "#26428b",
    //   marginLeft: 2,
    //   marginRight: 2,
    //   marginBottom: 3,
    // },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    // requestCard: {
    //   // padding: "1px 5px",
    //   // paddingVertical: 1,
    //   // paddingHorizontal: 5,
    //   // position: "relative",
    //   // margin: "4px 16px",
    //   // marginVertical: 4,
    //   // marginHorizontal: 16,
    //   // borderRadius: 10,
    //   // backgroundColor: "#d9d9d9",
    //   // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    // },
    table: {
      display: "table",
      width: "100%",
    },
    row: {
      flexDirection: "row",
    },
    cell: {
      fontSize: 10,
      flexGrow: 1,
      padding: 5,
      // fontWeight: "400",
      fontWeight: 400,
      // grid-template-columns:
      //   minmax(0px, 4fr) minmax(0px, 4fr) minmax(0px, 4.5fr) minmax(0px, 3fr) minmax(0px, 3fr)
      //   minmax(0px, 2.5fr);
    },
    cell1: {
      flex: 5.5,
    },
    cell2: {
      flex: 5.5,
    },
    cell3: {
      flex: 4.5,
    },
    cell4: {
      flex: 3,
    },
    cell5: {
      flex: 3,
    },
    cell6: {
      flex: 2.5,
    },
    header: {
      backgroundColor: "#d9d9d9",
      borderStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: "#26428b",
      marginTop: 4,
    },
    TotalAmountContainer: {
      marginTop: 15,

      alignSelf: "flex-end",
      width: "16%",
      textAlign: "center",
    },
    totalAmountheader: {
      borderStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: "#26428b",
    },
  });
  return (
    <Document>
      <Page
        size="A4"
        orientation="landscape"
        style={{ width: "90vh", display: "flex", alignItems: "center" }}
      >
        <View style={styles.page}>
          <View style={styles.title}>
            <Text style={{ textAlign: "center", color: "white" }}>
              Payments ({new Date().toLocaleDateString("en-GB")})
            </Text>
          </View>
          <View style={styles.mainContainer}>
            <View style={styles.table}>
              <View style={[styles.row, styles.header]}>
                <View style={[styles.cell, styles.cell1]}>
                  <Text>Site</Text>
                </View>
                <View style={[styles.cell, styles.cell2]}>
                  <Text>Category</Text>
                </View>
                <View style={[styles.cell, styles.cell3]}>
                  <Text>Vendor</Text>
                </View>
                <View style={[styles.cell, styles.cell4]}>
                  <Text>Vendor Mobile</Text>
                </View>
                <View style={[styles.cell, styles.cell5]}>
                  <Text>PO Amount</Text>
                </View>
                <View style={[styles.cell, styles.cell6]}>
                  <Text>Requested</Text>
                </View>
              </View>
              {Object.values(selectedRequests).map((request, index) => {
                // let History = JSON.parse(request?.History);
                // let latestEDIT =
                //   History &&
                //   Object.values(History)[Object.values(History).length - 1];
                return (
                  <View
                    key={request.uuid}
                    style={[
                      {
                        backgroundColor: `${index % 2 === 0 ? "#f1eeee" : "white"
                          }`,
                      },
                    ]}
                  >
                    <View style={styles.row}>
                      <View style={[styles.cell, styles.cell1]}>
                        <Text>
                          {Object.values(JSON.parse(request.ProjectDetails))[0]}
                        </Text>
                      </View>
                      <View style={[styles.cell, styles.cell2]}>
                        <Text>{request.Category || "\u2013\u2013"}</Text>
                      </View>
                      <View style={[styles.cell, styles.cell3]}>
                        <Text>{request.VendorName || "\u2013\u2013"}</Text>
                      </View>
                      <View style={[styles.cell, styles.cell4]}>
                        <Text>{request.VendorMobile || "\u2013\u2013"}</Text>
                      </View>
                      <View style={[styles.cell, styles.cell5]}>
                        <Text>
                          {request.OrderTotalAmount || "\u2013\u2013"}
                        </Text>
                        {/* <Text>
                          <Link
                            src={`https://startstaging.web.app/PO/${request.OrderId}`}
                          >
                            link
                          </Link>
                        </Text> */}
                      </View>
                      <View
                        style={[
                          styles.cell,
                          styles.cell6,
                          { fontWeight: "700" },
                        ]}
                      >
                        <Text>
                          {request.AmountRequested || "--"}
                          {"/- "}
                        </Text>
                      </View>
                    </View>
                    {/* <View style={styles.row}>
                      <View
                        style={[styles.headerCell, styles.cell, styles.cell1]}
                      >
                        <Text>Requested By</Text>
                      </View>
                      <View
                        style={[styles.headerCell, styles.cell, styles.cell2]}
                      >
                        <Text>Requested On</Text>
                      </View>
                      <View
                        style={[styles.headerCell, styles.cell, styles.cell3]}
                      >
                        <Text>Vendor Mobile</Text>
                      </View>
                      
                      <View style={[styles.cell, styles.cell4]}>
                     
                      </View>
                      <View style={[styles.cell, styles.cell5]}>
                        <Text></Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View
                        style={[styles.headerCell, styles.cell, styles.cell1]}
                      >
                        <Text>{Object.values(History)[0].By}</Text>
                      </View>

                      <View
                        style={[styles.headerCell, styles.cell, styles.cell2]}
                      >
                        <Text>
                          {new Date(request.DOFReq).toLocaleDateString("en-IN")}
                        </Text>
                      </View>
                      <View style={[styles.cell, styles.cell3]}>
                        <Text>{request.VendorMobile || "\u2013\u2013"}</Text>
                      </View>
                    

                      <View style={[styles.cell, styles.cell4]}>
                      
                      </View>
                      <View style={[styles.cell, styles.cell5]}>
                        <Text></Text>
                      </View>
                    </View> */}
                  </View>
                );
              })}
            </View>
            <View style={styles.TotalAmountContainer}>
              <Text style={styles.totalAmountheader}>Total Amount</Text>
              <Text>{sumOfSelectedRequests}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const filterData = (
  resultsArr,
  statusFilter,
  projectFilter,
  DateRangeValue,
  categoryFilter
) => {
  // console.log("results aarr from api", resultsArr);
  // let tempSet = resultsArr.reduce((newArr, item) => {
  //   const ProjectName = Object.values(JSON.parse(item.ProjectDetails))[0];

  //   // console.log("check here", Object.values(JSON.parse(item.History))[0]);
  //   // if (
  //   //   Object.values(JSON.parse(item.History))[
  //   //     Object.values(JSON.parse(item.History)).length - 1
  //   //   ].status === statusFilter ||
  //   //   statusFilter === "All"
  //   // ) {
  //   // console.log("current Elemctn", item);
  //   if (
  //     (item.Status === statusFilter || statusFilter === "All") &&
  //     (DateRangeValue.every((date) => date === null) ||
  //       isDateLiesCheck(item.DOFReq, DateRangeValue))
  //   ) {
  //     if (!newArr[ProjectName]) {
  //       newArr[ProjectName] = [];
  //     }
  //     newArr[ProjectName].push(item);
  //   }
  //   return newArr;
  // }, {});
  // console.log("tempset by project wise", tempSet);

  let tempSet = resultsArr.filter(
    (item) =>
      (item.Status === statusFilter || statusFilter === "All") &&
      (DateRangeValue.every((date) => date === null) ||
        isDateLiesCheck(item.DOFReq, DateRangeValue)) &&
      (item.ProjectDetails.includes(projectFilter) ||
        projectFilter === "All") && // here projectDetails is stingified JSON {ProjectId:projectName} // parse and check for more accurate filters
      (item.Category === categoryFilter || categoryFilter === "All")
  );

  return tempSet;
};

const Requests_Fin = () => {
  // const styleButton = {
  //   border: "none",
  //   background: "none",
  //   cursor: "pointer",
  //   fontWeight: "bold",
  //   fontSize: "1em",
  //   border: "none",
  //   padding: "10px",
  //   borderRadius: "10px",
  // };
  const location = useLocation();
  const data = location?.state?.data;
  const userContext = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const [finRequestsData, setFinRequestsData] = useState([]); //data to display
  const [statusFilter, setStatusFilter] = useState("All");
  const [rawData, setRawData] = useState([]);
  const [DateRangeValue, setDateRangeValue] = useState([null, null]);
  const [highlightdivuuid, setHighlightdivuuid] = useState(0);
  const [selectedRequests, setSelectedRequests] = useState({});
  const [generateSelectedReqPDF, setGenerateSelectedReqPDF] = useState({
    modal: false,
    downloadable: false,
  });
  const [projectFilterName, setProjectFilterName] = useState("All");
  const [categoryFilter, setCategoryFilter] = useState("All");

  const getFinanceRequest = async () => {
    const opportunityService = new OpportunityService();
    const reqData = await opportunityService.getFinanceRequest(
      userContext.user.Firm
    );
    // console.log("reqData", reqData);
    setRawData(reqData.results);
    setLoading(false);
    console.log("amount added here", reqData.results)
  };


  useEffect(() => {
    if (location.state?.data) {
      console.log("changed", location, location.state);
      let data = location.state.data;
      setHighlightdivuuid(data?.requestId);
    }
  }, [location.state]);

  useEffect(() => {
    if (highlightdivuuid) {
      const cartid = document.getElementById(`${highlightdivuuid}`);
      cartid?.focus();
      cartid?.classList.add("highlightdiv");
      // console.log(cartid, "should focus this");
    }
  }, [finRequestsData, highlightdivuuid]);

  useEffect(() => {
    //fetch the request data
    getFinanceRequest();
  }, []);

  useEffect(() => {
    if (rawData) {
      const filteredData = filterData(
        rawData,
        statusFilter,
        projectFilterName,
        DateRangeValue,
        categoryFilter
      );
      // console.log("filteredData Data", filteredData);
      setFinRequestsData(filteredData);
      data && setHighlightdivuuid(data.requestId);
    }
  }, [
    rawData,
    statusFilter,
    DateRangeValue,
    projectFilterName,
    categoryFilter,
  ]);


  // const calculateTotalRequestedAmount = () => {
  //   return (
  //     rawData?.reduce((sum, item) => {
  //       console.log("added value of sum is", sum)
  //       if (item.AmountRequested !== "undefined" && item.AmountRequested !== null) {
  //         console.log("requeste amount is", item.AmountRequested)
  //         console.log("sum amount is", sum + Number(item.AmountRequested))
  //         return (sum + Number(item.AmountRequested));
  //       }
  //       return 0;
  //     }, 0) || 0
  //   );
  // };

  const calculateTotalRequestedAmount = () => {
    let totalAmount = 0;

    if (rawData || finRequestsData) {
      for (let i = 0; i < finRequestsData.length; i++) {
        const item = finRequestsData[i];
        console.log("added value of totalAmount is", totalAmount);
        if (item.AmountRequested !== "undefined" && item.AmountRequested !== null) {
          console.log("requested amount is", item.AmountRequested);
          totalAmount += Number(item.AmountRequested);
          console.log("total amount is", totalAmount);
        }
      }
    }

    return totalAmount;
  };


  console.log("added amount to requested", calculateTotalRequestedAmount())

  const receivedAmount = useMemo(
    () => calculateTotalRequestedAmount(),
    [rawData, finRequestsData]
  );


  const getProjectNamesFromReq = () => {
    const projectNamesObj = rawData?.reduce((accumulator, item) => {
      const projectDetails = JSON.parse(item.ProjectDetails);

      if (
        typeof projectDetails === "object" &&
        Object.keys(projectDetails).length > 0
      ) {
        const projectName = Object.values(projectDetails)[0];
        accumulator[projectName] = true;
      }

      return accumulator;
    }, {});

    return ["All", ...Object.keys(projectNamesObj || {})];
  };

  const projectNamesToFilter = useMemo(
    () => getProjectNamesFromReq(),
    [rawData]
  );

  const getCategoryNamesFromReq = () => {
    const categorySet = new Set();
    return (
      rawData?.reduce(
        (accumulator, item) => {
          const category = item.Category;

          if (!categorySet.has(category) && category) {
            categorySet.add(category);
            accumulator.push(category);
          }

          return accumulator;
        },
        ["All"]
      ) || ["All"]
    );
  };

  const categoryNamesToFilter = useMemo(
    () => getCategoryNamesFromReq(),
    [rawData]
  );

  const sumOfSelectedRequests = useMemo(
    () =>
      Object.values(selectedRequests).reduce(
        (sum, request) => sum + Number(request.AmountRequested),
        0
      ),
    [selectedRequests]
  );

  const handleCheckboxChange = (request) => {
    setSelectedRequests((prevSelected) => {
      const isSelected = prevSelected[request.uuid];

      // Create a copy of the selected requests object
      const newSelected = { ...prevSelected };

      if (isSelected) {
        // If the request is already in the object, remove it
        delete newSelected[request.uuid];
      } else {
        // If the request is not in the object, add it
        newSelected[request.uuid] = request;
      }

      return newSelected;
    });
  };

  return (
    <>
      <div
        className="requests-filtersContainer"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "80%",
          gap: "5%",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <DropDownMUI
          placeholder={"Project"}
          disabeSorting={true}
          data={projectNamesToFilter}
          value={projectFilterName}
          onChange={(value) => {
            setProjectFilterName(value);
          }}
          size={"small"}
          custStyles={{ width: "340px" }}
        />
        <DropDownMUI
          placeholder={"Category"}
          disabeSorting={true}
          data={categoryNamesToFilter}
          value={categoryFilter}
          onChange={(value) => {
            setCategoryFilter(value);
          }}
          size={"small"}
        />
        <div className="status-dateFilters">
          <DropDownMUI
            placeholder={"Status"}
            disabeSorting={true}
            data={["All", "Requested", "Approved", "Declined", "On hold"]}
            value={statusFilter === "Request" ? "Requested" : statusFilter}
            onChange={(value) => {
              setStatusFilter(() => {
                switch (value) {
                  case "Requested":
                    return "Request";
                  default:
                    return value;
                }
              });
            }}
            size={"small"}
          />
          <div
          >
            <CustomDateRangePicker
              DateRange={DateRangeValue}
              setDateRange={setDateRangeValue}
            />
          </div>
        </div>
      </div>
      <div className="fin-req-selected-items-details">
        <div
          style={{
            display: "flex",
            width: "75%",
            justifyContent: "space-around",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "40%",
              alignItems: "center",
              gap: "15%",
              flexWrap: "wrap",
            }}
          >
            <div>
              <p className="transparentText">Amount Selected</p>
              <p style={{ fontWeight: 700 }}>{sumOfSelectedRequests.toFixed(2)}/-</p>
            </div>
            {Object.keys(selectedRequests).length > 0 && (
              <button
                className="req-card-btn"
                style={{ color: "black" }}
                onClick={async () => {
                  setGenerateSelectedReqPDF({
                    modal: true,
                    downloadable: true,
                  });
                }}
              >
                Generate PDF
              </button>
            )}
          </div>
          <div>
            <p className="transparentText">Amount Requested</p>
            <p style={{ fontWeight: 700 }}>{receivedAmount.toFixed(2)}/-</p>
          </div>
        </div>
      </div>

      <div style={{ height: "75vh", overflow: "scroll" }}>
        {
          isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ width: "30px", height: "30px", color: "#fdd34d" }}
              />
            </div>
          ) : finRequestsData.length > 0 ? (

            finRequestsData.map((request, rIndex) => (
              <Req_Rec_Card
                // projectkey={project}
                key={request.uuid}
                request={request}
                rIndex={rIndex}
                setFinRequestsData={setFinRequestsData}
                setRawData={setRawData}
                selectedRequests={selectedRequests}
                // setSelectedRequests={setSelectedRequests}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))
          ) : (
            <h4 style={{ textAlign: "center" }}>Nothing to show here!</h4>
          )
          //     </div>
          //   );
          // })
        }
        {generateSelectedReqPDF.modal && (
          <Modal
            open={generateSelectedReqPDF.modal}
            onClose={() =>
              setGenerateSelectedReqPDF({ modal: false, downloadable: false })
            }
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                minWidth: "40%",
                maxHeight: "90%",
                maxWidth: "4%",
              }}
            >
              <div>
                {generateSelectedReqPDF.downloadable && (
                  <PDFDownloadLink
                    document={
                      <SelectedRequestsPDF
                        selectedRequests={selectedRequests}
                        sumOfSelectedRequests={sumOfSelectedRequests}
                      />
                    }
                    fileName={`payments-${new Date().toLocaleDateString(
                      "en-GB"
                    )}`}
                  >
                    {({ url, loading }) => {
                      return loading
                        ? "Loading document..."
                        : url
                          ? "Download now!"
                          : "Loading...";
                    }}
                  </PDFDownloadLink>
                )}
              </div>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};
export { Requests_Fin, filterData };
