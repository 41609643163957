import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { display } from "@mui/system";

export default function ViewDetails({
  viewDetailsModal,
  setViewDetailsModal,
  viewDetailsItem,
  viewDetailsMilestones,
}) {
  // console.log('viewDetailsItem : ', viewDetailsItem);

  return (
    <Modal
      open={viewDetailsModal}
      onClose={() => setViewDetailsModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "50%",
          maxHeight: "90%",
          maxWidth: "50%",
        }}
      >
        {
          // JSON.parse(transactionDetailsData.Data).data.map()
        }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          <p style={{ marginBottom: 25 }}>
            {viewDetailsItem && viewDetailsItem.VendorName} (
            {viewDetailsItem && viewDetailsItem.VendorMobile}) |{" "}
            {viewDetailsItem && viewDetailsItem.Category}
          </p>
          {JSON.parse(viewDetailsItem.Data).data.map((dataItem, dataIndex) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  marginBottom: 25,
                  marginTop: 25,
                }}
              >
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {dataItem.specification || dataItem.description}
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#CDCDCD",
                  }}
                >
                  {dataItem["Unit Name"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  height: 2,
                  width: "100%",
                  backgroundColor: "#EAEAEA",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
              <div style={{ display: "flex", flexDirection: "row", gap: 70 }}>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#C4C4C4",
                    width: "20%",
                    maxWidth: "20%",
                    minWidth: "20%",
                  }}
                >
                  Payment Id
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#C4C4C4",
                    Width: "15%",
                    maxWidth: "15%",
                    minWidth: "15%",
                  }}
                >
                  Amount
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#C4C4C4",
                    Width: "15%",
                    maxWidth: "15%",
                    minWidth: "15%",
                  }}
                >
                  Status
                </p>
                <p
                  style={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#C4C4C4",
                    Width: "15%",
                    maxWidth: "15%",
                    minWidth: "15%",
                  }}
                >
                  Mode
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  height: 2,
                  width: "100%",
                  backgroundColor: "#EAEAEA",
                  marginTop: 10,
                  marginBottom: 25,
                }}
              ></div>
              {/* {dataItem.milestones.map((mItem, mIndex) => ( */}
              {viewDetailsMilestones.map((mItem, mIndex) => (
                <>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      color: "#C4C4C4",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    {mItem.name}
                  </p>
                  {mItem.payments ? (
                    "payments" in mItem &&
                    mItem.payments.map(
                      (pItem, pIndex) => (
                        console.log(
                          "pItem : ",
                          new Date(pItem.date).toString().substring(4, 15)
                        ),
                        (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 70,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                width: "20%",
                                maxWidth: "20%",
                                minWidth: "20%",
                              }}
                            >
                              {pItem.paymentID}
                            </p>
                            {/* <p style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', color: "#353535", Width: '15%', maxWidth: '15%', minWidth: '15%', }}>{new Date(pItem.date).toString().substring(4, 15)}</p> */}
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              {pItem.amount}
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#43A047",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              {pItem.status}
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              {pItem.mode}
                            </p>
                          </div>
                        )
                      )
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 70,
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#353535",
                          width: "20%",
                          maxWidth: "20%",
                          minWidth: "20%",
                        }}
                      >
                        -
                      </p>
                      {/* <p style={{ fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: 400, fontSize: '14px', color: "#353535", Width: '15%', maxWidth: '15%', minWidth: '15%', }}>-</p> */}
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#353535",
                          Width: "15%",
                          maxWidth: "15%",
                          minWidth: "15%",
                        }}
                      >
                        -
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#353535",
                          Width: "15%",
                          maxWidth: "15%",
                          minWidth: "15%",
                        }}
                      >
                        -
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#353535",
                          Width: "15%",
                          maxWidth: "15%",
                          minWidth: "15%",
                        }}
                      >
                        -
                      </p>
                    </div>
                  )}
                </>
              ))}
            </>
          ))}
        </div>
      </Box>
    </Modal>
  );
}
