import React, { useState, useEffect } from "react";
import { Button1, TextArea, Input } from "../../components";

export default ({ onClose, onSave, project }) => {
  const [projectFirm, setProjectFirm] = useState({
    projectName: "",
    projectAccessNumber: "0",
    projectAddress: "",
    seCode: "",
  });

  useEffect(() => {
    if (project) {
      console.log("project", project);
      setProjectFirm((st) => ({
        ...st,
        projectName: project.clientName || project.ClientName,
      }));
      setProjectFirm((st) => ({
        ...st,
        projectAccessNumber: project.clientNumber || project.ClientNumber,
      }));
      setProjectFirm((st) => ({
        ...st,
        projectAddress: project.address || project.Address,
      }));
      setProjectFirm((st) => ({
        ...st,
        seCode: project.siteEngineersId || project.SiteEngineersId,
      }));
    }
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(2px)",
        backgroundColor: "rgba(0,0,0,0.1)",
        left: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose && onClose}
    >
      <div
        style={{
          width: 300,
          backgroundColor: "white",
          boxShadow: "1px 1px 3px grey",
          borderRadius: 4,
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: 20,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ textAlign: "center" }}>Project Details</div>
        <Input
          placeholder="Project name *"
          value={projectFirm.projectName}
          onChange={(value) => {
            setProjectFirm((st) => ({ ...st, projectName: value }));
          }}
        />
        <Input
          placeholder="Project access number"
          value={projectFirm.projectAccessNumber}
          onChange={(value) => {
            setProjectFirm((st) => ({ ...st, projectAccessNumber: value }));
          }}
          inputProps={{ maxLength: 10 }}
        />
        <TextArea
          placeholder="Project address"
          value={projectFirm.projectAddress}
          onChange={(value) => {
            setProjectFirm((st) => ({ ...st, projectAddress: value }));
          }}
        />
        <Input
          placeholder="Site engineer code"
          value={projectFirm.seCode}
          onChange={(value) => {
            setProjectFirm((st) => ({ ...st, seCode: value }));
          }}
        />
        <Button1
          content="SAVE"
          onClick={(event) => {
            if (projectFirm.projectName.length == 0) {
              alert("Please Provide Project Name");
              return;
            }
            if (onSave) {
              event.stopPropagation();
              onSave(projectFirm);
            }
          }}
        />
      </div>
    </div>
  );
};
