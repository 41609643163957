import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useRef, useState } from "react";
import AuthService from "../../api/authService";
import { CircularProgress } from "@material-ui/core";
import { ordersMap } from "../Finance/VendorStatement";
import { usePDF, PDFDownloadLink } from "@react-pdf/renderer";
import VendorPDF from "./VendorPDF";

const VendorAccStatement = ({
  showVendorStatement,
  setShowVendorStatement,
  selectedVendorDetails,
}) => {
  const [projectwiseOrders, setProjectWiseOrders] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [totalVendorDetails, setVendorTotalDetails] = useState({
    ProjectstotalBalance: 0,
    Projectstotalpaid: 0,
    TotalOrdersValue: 0,
    isDownloadable: false,
  });

  // const [instance, updateInstance] = usePDF({
  //   document: (
  //     <VendorPDF
  //       projectwiseOrders={projectwiseOrders}
  //       selectedVendorDetails={selectedVendorDetails}
  //       totalVendorDetails={totalVendorDetails}
  //       image={sessionStorage.getItem("firmLogoURL")}
  //     />
  //   ),
  // });
  const [isPdfModal, setPdfPopup] = useState({ popup: false, button: false });

  useEffect(() => {
    if (isPdfModal.popup) {
      setTimeout(() => {
        setPdfPopup({ popup: true, button: true });
      }, 1000);
    }
  }, [isPdfModal.popup]);

  const fetchVendorOrders = async () => {
    const authService = new AuthService();
    const response = await authService.getAllOrdersForVendorAccStatement(
      selectedVendorDetails.Mobile
    );
    // console.log('reso',response)
    setProjectWiseOrders(() => {
      if (response.payload) {
        // arranging the project with orders as { Project1 : [{o1},{o2}...],project2:[{o1},{2}..]...}
        let tempSet = response.payload.reduce((newArr, item) => {
          const ProjectId = JSON.parse(item.Data).ClientName;
          if (!newArr[ProjectId]) {
            newArr[ProjectId] = [];
          }
          newArr[ProjectId].push(item);
          return newArr;
        }, {});
        return tempSet;
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchVendorOrders();
  }, []);

  useEffect(() => {
    if (projectwiseOrders && Object.keys(projectwiseOrders).length > 0) {
      setTimeout(() => {
        let Projectstotalpaid = 0;
        let ProjectstotalBalance = 0;
        let TotalOrdersValue = 0;
        const paidElementsArr =
          document.getElementsByClassName("order-paidAmount");
        const balanceElementsArr = document.getElementsByClassName(
          "order-balanceAmount"
        );
        const totalorderAmount =
          document.getElementsByClassName("order-totalamount");
        // console.log(paidElementsArr, paidElementsArr.length);

        for (let i = 0; i < totalorderAmount.length; i++) {
          TotalOrdersValue += Number(totalorderAmount[i].innerHTML);
        }

        for (let i = 0; i < paidElementsArr.length; i++) {
          Projectstotalpaid += Number(paidElementsArr[i].innerHTML);
        }
        for (let i = 0; i < balanceElementsArr.length; i++) {
          // console.log(".item", balanceElementsArr[i]);
          if (
            Number(balanceElementsArr[i].innerHTML) !== NaN &&
            balanceElementsArr[i].innerHTML.length > 0 &&
            balanceElementsArr[i].innerHTML !== "NaN"
          ) {
            ProjectstotalBalance += Number(balanceElementsArr[i].innerHTML);
          }
        }
        // console.log("totalPAid", Projectstotalpaid);
        setVendorTotalDetails({
          ProjectstotalBalance: ProjectstotalBalance.toFixed(2),
          Projectstotalpaid: Projectstotalpaid.toFixed(2),
          TotalOrdersValue: TotalOrdersValue.toFixed(2),
          isDownloadable: true,
        });
      }, 4000);
    }
  }, [projectwiseOrders]);


  const testFUntion = () => {
    // setTimeout(() => {
    //   return <div>Testing the timeout</div>;
    // }, 1000);
    return (
      <PDFDownloadLink
        document={
          <VendorPDF
            projectwiseOrders={projectwiseOrders}
            selectedVendorDetails={selectedVendorDetails}
            totalVendorDetails={totalVendorDetails}
            image={sessionStorage.getItem("firmLogoURL")}
          />
        }
        fileName={`${selectedVendorDetails.Name}-statement`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
        <button disabled={!totalVendorDetails.isDownloadable}>Download</button>
      </PDFDownloadLink>
    );
  };
  return (
    <Modal
      sx={{}}
      open={showVendorStatement}
      onClose={() => setShowVendorStatement(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="transactionDetailsModal"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxHeight: "90%",
          maxWidth: "80%",
        }}
      >
        <div className="vendor-acc-statement-project-wise-container">
          {/* <PDFDownloadLink
            document={
              <VendorPDF
                projectwiseOrders={projectwiseOrders}
                selectedVendorDetails={selectedVendorDetails}
                totalVendorDetails={totalVendorDetails}
                image={sessionStorage.getItem("firmLogoURL")}
              />
            }
            fileName={`${selectedVendorDetails.Name}-statement`}
          >

            <button disabled={!totalVendorDetails.isDownloadable}>
              Download
            </button>
          </PDFDownloadLink> */}
          <div>
            <button
              style={{
                display: "flex",
                fontWeight: "700",
                backgroundColor: "rgb(253, 211, 77)",
                // width: "100%",
                justifyContent: "center",
                padding: "14px",
                borderRadius: "5px",
                cursor: "pointer",
                border: "none",
              }}
              disabled={!totalVendorDetails.isDownloadable}
              onClick={() => setPdfPopup({ popup: true, button: false })}
            >
              Export As PDF
              {!totalVendorDetails.isDownloadable && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    color: "rgb(70 155 240)",
                  }}
                />
              )}
            </button>
          </div>

          {/* <a href={instance.url} download="testtt.pdf">
            {" "}
            FFFF
          </a> */}
          <div className="vendor-acc-statement-project-wise-header">
            <div className="vendor-statement-details-container">
              <div className="vendor-statement-vendor-details">
                <h2>
                  {selectedVendorDetails.Name}
                  <span>{`(${selectedVendorDetails.Mobile})`}</span>
                </h2>
                <h4>{selectedVendorDetails.Company}</h4>
              </div>
              <div className="vendor-statement-project-acc-summary">
                <div className="project-acc-summary-header">
                  <p>Account Summary</p>
                  <hr />
                </div>
                <div className="project-acc-summary-body">
                  <dl className="detail-pair">
                    <dt>Total Amount</dt>
                    <dd>{`${totalVendorDetails.TotalOrdersValue}`}</dd>
                  </dl>
                  <dl className="detail-pair">
                    <dt>Total Paid</dt>
                    <dd>{`${totalVendorDetails.Projectstotalpaid}`}</dd>
                  </dl>
                  <dl className="detail-pair">
                    <dt style={{ color: "darkseagreen" }}>Balance</dt>
                    <dd>{`${totalVendorDetails.ProjectstotalBalance}`}</dd>
                  </dl>

                  {/* <div className="project-acc-summary-values"> */}

                  {/* </div> */}
                </div>
              </div>
            </div>
            {/* <h2
              style={{ textAlign: "center" }}
            >{`${selectedVendorDetails.Name.toUpperCase()}-(${
              selectedVendorDetails.Mobile
            })`}</h2>
            <div
              style={{
                textAlign: "end",
                fontWeight: "bold",
                marginBottom: "2em",
              }}
            >
              <p>TotalPaid : {`${totalVendorDetails.Projectstotalpaid}`}</p>
              <p>Balance : {`${totalVendorDetails.ProjectstotalBalance}`}</p>
            </div> */}
          </div>
          <div className="vendor-acc-statement-project-wise-project-container">
            {isLoading ? (
              <CircularProgress style={{ display: "flex" }} />
            ) : (
              <div className="project-container-header">
                {projectwiseOrders ? (
                  Object.keys(projectwiseOrders).map((projectItem, pIndex) => {
                    return (
                      <div
                        style={{
                          background: "#f0f0f1",
                          padding: " 0.5em",
                          marginBottom: " 1em",
                        }}
                        key={pIndex}
                      >
                        <h4 style={{ textDecoration: "underline" }}>
                          <span>{pIndex + 1}.</span>
                          {projectItem.toUpperCase()}:
                        </h4>
                        {/* <VendorStatement allOrders={projectwiseOrders[projectItem]} fromVendorAccStatement={true}/> */}
                        {ordersMap(projectwiseOrders[projectItem])}
                      </div>
                    );
                  })
                ) : (
                  <p>No Orders Assigned</p>
                )}
              </div>
            )}
          </div>
          {/* {isPdfModal && (
            <Modal open={isPdfModal}>
              <Box
              // className="transactionDetailsModal"
              // sx={{
              //   position: "relative",
              //   top: "50%",
              //   left: "50%",
              // }}
              ></Box>
              <div>Testing </div>
            </Modal>
          )} */}
        </div>
        {isPdfModal.popup && (
          <Modal
            open={isPdfModal.popup}
            onClose={() => setPdfPopup({ popup: false, button: false })}
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                minWidth: "40%",
                maxHeight: "90%",
                maxWidth: "4%",
              }}
            >
              <div
              // onLoad={() => {
              //   console.log("loadeddiv");

              //   setPdfPopup({ popup: true, button: true });
              // }}
              >
                {/* Text */}
                {isPdfModal.button && (
                  <PDFDownloadLink
                    document={
                      <VendorPDF
                        projectwiseOrders={projectwiseOrders}
                        selectedVendorDetails={selectedVendorDetails}
                        totalVendorDetails={totalVendorDetails}
                        image={sessionStorage.getItem("firmLogoURL")}
                      />
                    }
                    fileName={`${selectedVendorDetails.Name}-statement`}
                  >
                    {({ blob, url, loading, error }) => {
                      return loading
                        ? "Loading document..."
                        : url
                        ? "Download now!"
                        : "Loading...";
                      // return (
                      //   <div>
                      //     {loading && "Loading Wait"}
                      //     {url && (
                      //       <a href={url} download="tes.pdf">
                      //         Download
                      //       </a>
                      //     )}
                      //   </div>
                      // );
                      // loading
                      //   ? "Loading Wait"
                      //   : url && (
                      //       <a href={url} download="tes.pdf">
                      //         Download
                      //       </a>
                      //     );
                    }}
                    {/* <button disabled={!totalVendorDetails.isDownloadable}>
                      Download
                    </button> */}
                  </PDFDownloadLink>
                )}
              </div>
            </Box>

            {/* {testFUntion()} */}
          </Modal>
        )}
      </Box>
    </Modal>
  );
};
export default VendorAccStatement;
