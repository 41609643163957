import { useEffect, useState } from "react";
import "./dashboardPage.scss";
import { Button1, SearchBar, PopOverView } from "../../components";
import DrawerHOC from "../../hoc/drawerTemplate";
import ProjectDetailsModal from "./projectDetailsModal";
import ProjectService from "../../api/projectService";
import { toast } from "react-toast";
import firebase from "../../config/firebase";
import ProjectRow from "../../components/ProjectRowArchived/projectRow";
import AuthService from "../../api/authService";
import addNotification from "react-push-notification";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import CircularProgress from "@material-ui/core/CircularProgress";

const DashboardPage = () => {
  const { record } = useAnalyticsContext();

  const [showProjectDetailsModal, toggleProjetDetailsModal] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);
  const projectService = new ProjectService();
  const [projects, setProjects] = useState([]);
  const [copyProjects, setCopyProjects] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showLoadMore, SetShowLoadMore] = useState(true);
  const [spinner, setSpinner] = useState(true);

  const buttonClick = () => {
    addNotification({
      title: "Message",
      subtitle: "This is a subtitle",
      message: "New Project Created",
      theme: "darkblue",
      native: true,
    });
  };

  useEffect(() => {
    getProjects();
  }, [paginationCount]);

  const getProjects = () => {
    projectService
      .get10Projects(paginationCount)
      .then((res) => {
        if (res.status === 200) {
          if (res.payload.length != 10) {
            {
              SetShowLoadMore(false);
            }
          }
          setSearchInput("");
          if (paginationCount == 0) {
            let tempProjects = [...res.payload];
            let archivedProjects = [];
            for (let index = 0; index < tempProjects.length; index++) {
              console.log(
                "tempProjects[index][isArchived] : ",
                tempProjects[index]["isArchived"]
              );
              if (tempProjects[index].hasOwnProperty("isArchived")) {
                if (tempProjects[index]["isArchived"] == 1) {
                  archivedProjects.push(tempProjects[index]);
                } else {
                }
              } else {
                // archivedProjects.push(tempProjects[index]);
              }
            }
            setProjects(archivedProjects);
            setCopyProjects(archivedProjects);
            setSpinner(false);
          } else {
            console.log("st => [...st, ...res.payload] : ", [...res.payload]);

            let newTempProjects = [...res.payload];
            let oldProjects = projects;

            for (let index = 0; index < newTempProjects.length; index++) {
              // write the code here
              if (newTempProjects[index].hasOwnProperty("isArchived")) {
                if (newTempProjects[index]["isArchived"] == 1) {
                  oldProjects.push(newTempProjects[index]);
                } else {
                }
              } else {
              }
            }
            setProjects(oldProjects);
            setCopyProjects(oldProjects);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getProjectBySearch = (text) => {
    projectService
      .searchProject(text)
      .then((res) => {
        if (res.status == 200) {
          setProjects(res.payload);
        } else {
          getProjects();
        }
      })
      .catch(() => {
        alert("somthing went wrong");
      });
  };

  const DuplicateCard = async (project) => {
    let clientName = project.ClientName;
    let clientNumber = project.ClientNumber;
    let address = project.Address;
    let amountReceived = JSON.parse(project.Finance).AmountReceived;
    let SiteEngineersId = project.SiteEngineersId;
    let rooms = JSON.parse(project.Rooms).map((room) => {
      return {
        ...room,
        Units: room.Units.map((unit) => {
          return {
            ...unit,
            Components: unit.Components.map((component) => {
              let _tempComponent = { ...component };
              _tempComponent["Work"] = _tempComponent.Work.map((work) => ({
                ...work,
                status: "",
                OrderId: new Date().getTime(),
              }));
              _tempComponent["Material"] = _tempComponent.Material.map(
                (material) => ({
                  ...material,
                  status: "",
                  OrderId: new Date().getTime(),
                })
              );
              return _tempComponent;
            }),
          };
        }),
      };
    });
    let duration = project.duration;

    const response = await projectService.addProject(
      clientName + "_Copy",
      clientNumber,
      address,
      amountReceived,
      SiteEngineersId,
      rooms,
      duration
    );
    console.log(response);
    if (response.status == 200) {
      updateFBRDB(response.payload);
      alert("Duplicate project created successfully!");
    } else {
      alert("Error while duplicating project!");
    }
    setPaginationCount(0);
  };

  const renderProjects = () => {
    return projects
      .sort((a, b) => b.lastOpen - a.lastOpen)
      .map((project, index) => {
        return (
          <ProjectRow
            key={index}
            project={project}
            DuplicateCard={DuplicateCard}
          />
        );
      });
  };

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ")
      .child(payload.ProjectId)
      .set({ ...payload, rooms: JSON.stringify(payload.Rooms) });

  const makeNewProject = async ({
    projectName,
    projectAccessNumber,
    projectAddress,
    seCode,
  }) => {
    const authService = new AuthService();
    authService
      .getSiteEngineerById(`${seCode}`)
      .then((res) => {
        if (res.status == 200) {
          const projectService = new ProjectService();
          projectService
            .addProject(
              projectName,
              projectAccessNumber,
              projectAddress,
              "",
              seCode,
              [],
              0
            )
            .then((res1) => {
              if (res1.status == 200) {
                updateFBRDB(res1.payload);
                toggleProjetDetailsModal(false);
                buttonClick();
                toast.success("Project created successfully", {
                  backgroundColor: "rgb(255, 211, 77)",
                  color: "black",
                });
                getProjects();
              }
            });
        } else {
          alert("Please Enter Register SE");
        }
      })
      .catch(() => {
        alert("somthing went wrong");
      });
  };

  return (
    <>
      {spinner ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#fdd34d" }} />
        </div>
      ) : (
        <div id="DashboardPage">
          <div id="dpmain">
            <div id="dpmHeading">
              <div id="dpmhTitle">Archived Projects</div>
              {/* <Button1
                                content="+ New Project"
                                onClick={() => {
                                    toggleProjetDetailsModal(true)
                                    // record(TAGS["@new_project_button_click_success", { description: "New project button click success" }])
                                    record("@new_project_button_click_success", { description: "New project button click success" })
                                }}
                            /> */}
            </div>
            <div id="dpmFilterArea">
              <SearchBar
                onChange={(text) => {
                  getProjectBySearch(text);
                }}
              />
              <PopOverView
                position={"left"}
                component={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      fontSize: 12,
                      color: "grey",
                      alignItems: "flex-start",
                      cursor: "pointer",
                      width: 300,
                    }}
                  >
                    <div>Stage</div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      {["ALL", "BOQ", "Purchase", "Archive"].map(
                        (tempFiler) => (
                          <div
                            style={{
                              borderRadius: 10,
                              padding: "4px 12px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                            }}
                          >
                            {tempFiler}
                          </div>
                        )
                      )}
                    </div>
                    <div>Date</div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 10 }}
                    >
                      {["ALL", "Latest", "Last month", "Oldest"].map(
                        (tempFiler) => (
                          <div
                            style={{
                              borderRadius: 10,
                              padding: "4px 12px",
                              backgroundColor: "rgba(0,0,0,0.1)",
                            }}
                          >
                            {tempFiler}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                }
              ></PopOverView>
            </div>

            <div id="projectsHeader">
              <div>NAME</div>

              {/* <div>BUDGET</div>
                            <div>ACTUAL</div> */}

              <div style={{ maxWidth: "45px", marginLeft: 10 }}>LIVE</div>

              <div>STAGE</div>
            </div>

            <div id="projectsRow">
              {renderProjects()}
              {showLoadMore && (
                <div
                  className="projectRow"
                  style={{ cursor: "pointer" }}
                  onClick={() => setPaginationCount((st) => st + 10)}
                >
                  <div className="projectHeader">
                    <div>Load More</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {showProjectDetailsModal && (
            <ProjectDetailsModal
              onClose={() => toggleProjetDetailsModal(false)}
              onSave={makeNewProject}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DrawerHOC(DashboardPage);
