/* eslint-disable eqeqeq */
import { useState } from "react";
import "./input.scss";
import ContentEditable from "react-contenteditable";
import TextField from "@material-ui/core/TextField";
import { Fragment } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useRemoteConfig } from "../../Context/RemoteConfig";
import encryptPass from "encrypt-with-password";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  placeholder,
  type,
  onChange,
  onRemove,
  value,
  wrap,
  wrapActive,
  onBlur,
  disabled,
  securedEntry,
  encrypt,
  style,
  placeHolderAlwaysOnTop,
  inputProps,
  inputComponent,
  hideRedLine,
  autoFocus,
  InputField
}) => {
  const [showPassword, setShowPassword] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { theme, text } = useRemoteConfig();

  const _user = localStorage.getItem("User");

  let password = "password";

  if (_user) {
    const user = JSON.parse(_user);
    if (user.password) {
      password = user.password;
    }
  }

  const handleKeyPress = (event) => {
    console.log('event.key : ', event.key)
    if (event.key === 'Enter' || event.key == '-') {
      if(placeholder === 'Qty' || placeholder === 'Rate' || placeholder === 'GST(%)' || placeholder === 'GST (%)' || placeholder === 'Unit' ){
        event.preventDefault();
      }
    }
  };

  const [enteredValue,setEnteredValue] = useState(null);

  return wrap ? (
    <ContentEditable
      className="wrapInput"
      disabled={disabled}
      style={{
        backgroundColor: wrapActive ? "rgba(0,0,0,0.3)" : "#ebebeb",
        display: "flex",
        flexDirection: "row-reverse",
      }}
      contentEditable
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
      html={value}
    />
  ) : placeHolderAlwaysOnTop ? (
    <div>
      <small style={{ color: disabled && "grey" }}>{placeholder}</small>
      <TextField
        multiline
        error={value ? false : true}
        maxRows={4}
        inputProps={{
          maxLength:
            (placeholder == "Enter Client's Number" ||
              placeholder == "Vendor Mobile" ||
              placeholder == "Contact Person Number" ||
              placeholder == "Contact Person Number *" ||
              placeholder == "Site Engineer Number *" ||
              placeholder == "Project access number" ||
              placeholder == "Firm Phone Number" ||
              placeholder == "Firm Contact Person Number") &&
            10,
        }}
        id="standard-basic"
        style={{ minWidth: 80, width: "100%" }}
        // label={placeholder}
        type={securedEntry ? (showPassword ? "text" : "password") : "text"}
        disabled={disabled}
        onBlur={(e) => {
          let val = e.currentTarget.value
          // console.log('here is the onBlur first cond: ',val,"onblurfnc",e.target.value)
          val = val.replace(/\n/g, "<new-line>");
          val = val.replace(/'/g, "<single-quote>");
          val = val.replace(/"/g, "<double-quote>");
          val = val.replace(/₹/g, "<rupee-symbol>");
          val = val.replace(/\\/g, "");
          val = val.replace(/\t/g, "");
          if (encrypt) {
                val = encryptPass.encrypt(val, password);
          }
          // onBlur && onBlur(e.target.value)
          onChange(val);
          setEnteredValue(null);
          // console.log('entered value when blurred ' , enteredValue)
        }}
        onChange={(e) => {
          // console.log('entered value = ',enteredValue)
          let val = e.currentTarget.value;
          // console.log('here in the onchange first',val,'target valeu: ',e.target.value)
          val = val.replace(/\n/g, "<new-line>");
          val = val.replace(/'/g, "<single-quote>");
          val = val.replace(/"/g, "<double-quote>");
          val = val.replace(/₹/g, "<rupee-symbol>");
          val = val.replace(/\\/g, "");
          val = val.replace(/\t/g, "");
          if (encrypt) {
            val = encryptPass.encrypt(val, password);
          }
          if (InputField == "rate" || InputField == "quantity" || InputField == "gst") {
            // console.log('changeing the values')
            val = val.replace(/(?!-)[^0-9.]/g, "");
          }
          if (InputField == "unit") {
            val = val.replace(/[0-9.]/g, "");
          }
          setEnteredValue(val)
          // console.log('entered value ',enteredValue)
          // onChange(val);
        }}
        value={
          
          enteredValue == null ?

          (encrypt
            ? value.length !== 0
              ? encryptPass.decrypt(value, password)
              : value
            : value &&
              value
                .toString()
                .replace(/<new-line>/g, "\n")
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .replace(/<rupee-symbol>/g, "₹"))
          :
          (encrypt
            ? enteredValue.length !== 0
              ? encryptPass.decrypt(enteredValue, password)
              : enteredValue
            : enteredValue &&
            enteredValue
                .toString()
                .replace(/<new-line>/g, "\n")
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .replace(/<rupee-symbol>/g, "₹"))
        }
        autoComplete="off"
        inputRef={autoFocus}
        onKeyPress={handleKeyPress}
      />
    </div>
  ) : (
    <div
      className="input"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
      }}
    >
      <TextField
        // error={(value || hideRedLine) ? false : true}
        inputProps={{
          maxLength:
            (placeholder == "Enter Client's Number" ||
              placeholder == "Vendor Mobile" ||
              placeholder == "Contact Person Number" ||
              placeholder == "Contact Person Number *" ||
              placeholder == "Site Engineer Number *" ||
              placeholder == "Project access number" ||
              placeholder == "Firm Phone Number" ||
              placeholder == "Firm Contact Person Number") &&
            10,
        }}
        InputLabelProps={{ className: "input__label" }}
        id="standard-basic"
        style={
          style ? style : { minWidth: 80, paddingRight: "10px", width: "100%" }
        }
        label={placeholder}
        type={securedEntry ? (showPassword ? "text" : "password") : "text"}
        disabled={disabled}
        // onBlur={(e) => onBlur && onBlur(e.target.value)}
        onBlur={(e) => {
          let val = e.currentTarget.value
          val = val.replace(/\n/g, "<new-line>");
          val = val.replace(/'/g, "<single-quote>");
          val = val.replace(/"/g, "<double-quote>");
          val = val.replace(/₹/g, "<rupee-symbol>");
          val = val.replace(/\\/g, "");
          val = val.replace(/\t/g, "");
          if (encrypt) {
                val = encryptPass.encrypt(val, password);
              }
          onChange(val);
          setEnteredValue(null)
          // console.log('here is the onBlur other case ',e.currentTarget.value)
        }}
        onChange={(e) => {
          // console.log('enterd value in 2nd cond: ',enteredValue)
          let val = e.currentTarget.value;
          // console.log('here in the change ',e.currentTarget.value,'target Value ',e.target.value)
          val = val.replace(/\n/g, "<new-line>");
          val = val.replace(/'/g, "<single-quote>");
          val = val.replace(/"/g, "<double-quote>");
          val = val.replace(/₹/g, "<rupee-symbol>");
          val = val.replace(/\\/g, "");
          val = val.replace(/\t/g, "");
          if (encrypt) {
            val = encryptPass.encrypt(val, password);
          }
          if (InputField == "rate" || InputField == "quantity" || InputField == "gst") {
            val = val.replace(/(?!-)[^0-9.]/g, "");
          }
          if (InputField == "unit") {
            val = val.replace(/[0-9.]/g, "");
          }
          // val = val.replace(/(?!-)[^0-9.]/g, "")
          setEnteredValue(val)
          // console.log('entered bvalue 2nd cond :',enteredValue)
          // onChange(val);
        }}
        value={
          // enteredValue
          
          enteredValue == null ?

          (encrypt
            ? value.length !== 0
              ? encryptPass.decrypt(value, password)
              : value
            : value &&
              value
                .toString()
                .replace(/<new-line>/g, "\n")
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .replace(/<rupee-symbol>/g, "₹"))
          :
          (encrypt
            ? enteredValue.length !== 0
              ? encryptPass.decrypt(enteredValue, password)
              : enteredValue
            : enteredValue &&
            enteredValue
                .toString()
                .replace(/<new-line>/g, "\n")
                .replace(/<single-quote>/g, "'")
                .replace(/<double-quote>/g, '"')
                .replace(/<rupee-symbol>/g, "₹"))
        }
        autoComplete="off"
        inputRef={autoFocus}
      />
      {securedEntry && (
        <Fragment>
          {showPassword ? (
            <AiFillEye
              style={{
                position: "absolute",
                right: "5%",
                color: "gray",
                marginBottom: "5px",
              }}
              onClick={() => setShowPassword(!showPassword)}
              size={30}
            />
          ) : (
            <AiFillEyeInvisible
              style={{
                position: "absolute",
                right: "5%",
                color: "gray",
                marginBottom: "5px",
              }}
              onClick={() => setShowPassword(!showPassword)}
              size={30}
            />
          )}
        </Fragment>
      )}
      {onRemove && (
        <abbr title="Delete">
          <div
            onClick={onRemove}
            style={{
              color: theme.DeleteButton.style.color,
              width: theme.DeleteButton.style.width,
              cursor: "pointer",
            }}
          >
            <DeleteOutlinedIcon />
          </div>
        </abbr>
      )}
    </div>
  );
};
