export default (data) => {
  let workCategories = [];
  let materialCategories = [];

  const { rooms } = data;
  rooms &&
    (typeof rooms === "object" ? rooms : JSON.parse(rooms)).forEach((room) => {
      const { Units } = room;
      Units.forEach((Unit) => {
        const { Components } = Unit;
        if (Components) {
          Components.forEach((component) => {
            const { Material, Work } = component;
            Material.forEach((material) => {
              materialCategories.push(material.type);
            });
            Work.forEach((work) => {
              workCategories.push(work.vendorCategory);
            });
          });
        }
      });
    });

  return {
    workCategories: [...new Set(workCategories)],
    materialCategories: [...new Set(materialCategories)],
  };
};
