import config from "../config/config";

const dataObject = {
  OrderId: 0,
  ClientId: 1612092733076,
  VendorId: {},
  SiteEngineerId: null,
  TransactionId: "161096557991",
  ProjectId: "2222222222222",
  Lat: "-20.075890",
  Lng: "146.257309",
  Status: "Quotation released",
  Category: "",
  Dates: { AcceptedDate: "" },
  Address: "",
  Payments: { TotalAmount: "0" },
  Data: {},
};

class QuotationService {
  async add(
    data,
    clientName,
    address,
    totalAmount,
    projectId,
    StartDate,
    EndDate,
    typ,
    vendorMobile
  ) {
    console.log("Rachd th API srvic");
    console.log("typ : ", typ);
    if (typ === "work") {
      const { vendorCategory, OrderId } = data[0];
      dataObject["VendorId"] = { [vendorCategory]: null };
      dataObject["Category"] = vendorCategory.toString();
      dataObject["OrderId"] = OrderId;
      console.log("OrderId : ", OrderId);
    } else {
      const { type, OrderId } = data[0];
      dataObject["VendorId"] = { [type]: null };
      dataObject["Category"] = type.toString();
      dataObject["OrderId"] = OrderId;
    }
    dataObject["Data"] = { data, ClientName: clientName, StartDate, EndDate };
    dataObject["Data"] = { ...dataObject["Data"] };
    dataObject["Payments"] = { TotalAmount: totalAmount };
    dataObject["Address"] = address;
    dataObject["ProjectId"] = projectId;
    try {
      const url =
        vendorMobile == "Open Vendor"
          ? `${config.quotationService}quotation`
          : `${config.quotationService}quotation/assignToVendor/${vendorMobile}`;
      let response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ",
        },
        body: JSON.stringify(dataObject),
      });
      response = await response.json();
      console.log("response : ", response);
      return { ...response };
    } catch (e) {
      console.log(`QuotationService catch `, e);
      return {
        status: 400,
        statusMsg: "Error while making quotation!",
        payload: e,
      };
    }
  }

  async delete(Id) {
    try {
      let response = await fetch(
        `${config.quotationService}quotationDelete/${Id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      return { ...response };
    } catch (e) {
      console.log(`QuotationService catch `, e);
      return {
        status: 400,
        statusMsg: "Error while deleting quotation!",
        payload: e,
      };
    }
  }
}

export default QuotationService;
