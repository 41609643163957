import { useState } from "react";
import { TextField } from "@material-ui/core";
import UtilService from "../../api/utilService";
import AuthService from "../../api/authService";
import Button from "../../components/Button/button";
import toast from "react-hot-toast";

function Email_User({ setFormStage, setFormData }) {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onContinue = () => {

    if (!validateEmail(enteredEmail)) {
      setError("Invalid email address");
      return;
    }

    console.log("Email", enteredEmail);
    setIsLoading(true);

    const authService = new AuthService();

    authService
      .getEmailByEmail(enteredEmail)
      .then((userRes) => {
        if (userRes.status != 200) {
        }
        if (userRes.payload && userRes.payload[0].active !== "0") {
          toast.error("Account is Inactive. Please contact Admin");
          setIsLoading(false);

          return;

        }

        console.log("ReS>PAYLOAD USER", userRes.payload);

        const utilService = new UtilService();
        utilService.sendOtp(enteredEmail).then((Otpres) => {
          if (Otpres.status == 200) {
            setIsLoading(false);
            setFormData({
              email: enteredEmail,
              user: userRes?.payload?.[0] || null,
              otpSessionId: Otpres.SessionId,
            });
            setFormStage(1);
          } else {
            alert(Otpres.statusMsg);
          }
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        toast.error("Something went wrong. Please try again");
      });
  };

  const onKeyPressed = (e) => {
    if (e.key === "Enter") {
      console.log("ENTERD KEY DOWN ", enteredEmail);
      e.preventDefault();
      onContinue();
    }
  };

  return (
    <div style={{ marginTop: "3em" }}>
      <TextField
        style={{
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: "6px",
          minWidth: 80,
          fontSize: "17px",
          height: `${error ? "60px" : "50px"}`,
        }}
        error={!!error}
        id="standard-basic email"
        InputProps={{
          placeholder: "Enter Email",
          style: {
            height: `${error ? "65%" : "100%"}`,
            paddingLeft: "4px",
            color: "#505050",
          },
        }}
        type="email"
        variant="standard"
        placeholder="Email"
        name="email"
        onKeyDown={onKeyPressed}
        onChange={(e) => {
          let text = e.currentTarget.value;
          setEnteredEmail(text);
          if (validateEmail(text)) {
            setError("");
          }
        }}
        helperText={error}
        value={enteredEmail}
        autoComplete="username"
      />
      <div style={{ paddingTop: "15px" }}>
        <Button
          content={"Continue"}
          isLoading={isLoading}
          onClick={onContinue}
        />
      </div>
    </div>
  );
}

export default Email_User;
