import { FiSave } from "react-icons/fi"
import RemarkTextBox from "./RemarksTextBox"
import EditIcon from '@mui/icons-material/Edit';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ReceivedAmountCard = ({data,index,setAmountHistory,setEditableItem,setReceived,editableitem,updateAccountDetails,amountHistory,startDate}) =>{
    return (
        <div style={{display:"flex" , padding: "5px 2px"  ,alignItems:"center", background :`${index != editableitem ?  "none" : "#e7e7e7"}` }}>
                      <div style={{boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)", borderRadius: "4px",}}>
                      <p>Amount:</p>
                          <input
                          autoFocus = {index != editableitem ? false : true}
                          value={data.amount}
                          disabled = {index != editableitem ? true : false}
                          style={{
                            width: "100%",
                            fontSize: "16px",
                            borderWidth: 0,
                            border : `${index != editableitem ?  "none" : "1px solid lightgray"}`,
                            outline: "none",
                            background:`${index != editableitem ?  "none" : "#FFF6DB"}`,
                          }}
                          type="number"
                          onChange={(e) => {
                            let tempobj = [...amountHistory]
                            // console.log('before Update',tempobj)
                            tempobj[index] = { ...tempobj[index],date: Date.now() ,amount : Number(e.currentTarget.value.replace(/(?!-)[^0-9.]/g, ""))}
                            // console.log('after the update ',tempobj)
                            setAmountHistory(tempobj)
                          }}
                          />
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disabled = {index != editableitem ? true : false}
                              margin="normal"
                              id="date-picker-dialog"
                              label="Date"
                              format="dd/MM/yyyy"
                              value={data.date}
                              onChange={(date) =>{
                                  // console.log('datee',date,'timestamp : ', new Date(date).getTime())
                                  let tempobj = [...amountHistory]
                                // console.log('before Update',tempobj)
                                tempobj[index] = { ...tempobj[index],date: new Date(date).getTime()}
                                // console.log('after the update ',tempobj)
                                setAmountHistory(tempobj)
                            }}
                              KeyboardButtonProps={{ "aria-label": "change date" }}
                              minDate={new Date(startDate)}
                            />
                          </MuiPickersUtilsProvider>
                          <textarea
                          style={{resize:"none"}}
                          disabled= {index != editableitem ? true : false}
                          placeholder="Remarks"
                          value={
                            data?.remarks
                            ?.replace(/<single-quote>/g, "'")
                             ?.replace(/<double-quote>/g,'"')
                           .replace(/<rupee-symbol>/g,"₹")
                            .replace(/<new-line>/g,"\n")
                          }
                          rows = "2"
                          cols="30"
                          onChange={(e)=>{
                            let value = e?.currentTarget?.value
                            value = value?.replace(/'/g, "<single-quote>");
                            value = value?.replace(/"/g, "<double-quote>");
                            value = value?.replace(/₹/g, "<rupee-symbol>");
                            value = value.replace(/\n/g,"<new-line>");
                            let tempobj = [...amountHistory]
                            tempobj[index] = { ...tempobj[index],remarks:value}
                            // console.log('after the update ',tempobj)
                            setAmountHistory(tempobj)
                          }}
                          >

                          </textarea>
                      </div>
                      
                    {index != editableitem ?
                      <EditIcon
                      size={20}
                      color="#94919F"
                      style={{width : "18%" ,cursor:"pointer"}}
                      onClick={()=>setEditableItem(index)}
                    /> : 
                    <FiSave
                     color="#94919F"
                     size={20}
                     style={{width:"18%" ,cursor:"pointer"}}
                     
                    onClick={()=>{
                        setEditableItem()
                        setReceived(amountHistory.reduce((totalAmount,item)=> totalAmount += +item.amount ,0))
                        updateAccountDetails()
                      }}
                       />}
                    </div>
    )
}
export default ReceivedAmountCard