import { useState } from "react";

const RemarkTextBox = ({cols,rows,onUpdate,value,disabled,placeholder,style}) =>{
    const [enteredvalue ,setEnteredValue] = useState(value)
    const changeText =(remarks) =>{
        if(remarks){
          let value = remarks
          value = value?.replace(/<single-quote>/g, "'")
          value = value?.replace(/<double-quote>/g,'"')
          value = value.replace(/<rupee-symbol>/g,"₹")
          value = value.replace(/<new-line>/g,"\n")
          return `${value}`
        }
    }
    return (
        <textarea 
        placeholder={placeholder}
        disabled={disabled}
        value={changeText(enteredvalue)}
        // style={ {...style} || {resize:"none",width: "94%",height: "45px",padding: "0.1em"}}
        style={style}
        cols={cols}
        rows={rows}
        onChange={(e)=>{
            setEnteredValue(e.currentTarget.value)
        }}
        onBlur={(e)=>{
            onUpdate(e);
        }}
        ></textarea>
    )

};


export default RemarkTextBox;