import { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { UserContext } from "../../Context/UserContext";
import TextField from "@material-ui/core/TextField";
import { IoSend } from "react-icons/io5";
import "./ChatModal.scss";
import firebase from "../../config/firebase";

function SiteEnggChatModal({
  siteEnggChatModalOpen,
  setSiteEnggChatModalOpen,
}) {
  const messageInputRef = useRef(null);
  const userContext = useContext(UserContext);
  const reftoFocus = useRef(null);
  const [chatData, setChatData] = useState({
    12356921: {
      messageId: 1686382356921,
      message: "Hi! This is a test message",
      sentBy: "admin",
      userId: "Staart@uniworksdesigns.com",
      time: "time",
      date: "date",
    },
  });

  const updateFBRDB = async (timestamp, data) => {
    await firebase
      .database()
      .ref(
        "ProgressMessages/" +
          `${siteEnggChatModalOpen.orderId}` +
          "/" +
          timestamp
      )
      .set(data)
      .then(() => {
        console.log("UPDATED IN FIReBASE", data);
      });
  };

  const getMessageFromFirebase = () => {};

  useEffect(() => {
    firebase
      .database()
      .ref("ProgressMessages/" + `${siteEnggChatModalOpen.orderId}` + "/")
      .orderByChild("messageId")
      // .startAfter(1703589873809)
      // .limitToLast(20)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          console.log("database value", snapshot.val());
          setChatData((prev) => {
            return {
              ...prev,
              ...snapshot.val(),
            };
          });
          //   reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
        }
      });

    return () => {
      firebase
        .database()
        .ref("ProgressMessages/" + `${siteEnggChatModalOpen.orderId}` + "/")
        .off();
    };
  }, []);
  const UpdateChatData = () => {
    let lastUpdatedTimestamp = new Date().getTime();

    let newObjectOfChat = {
      [lastUpdatedTimestamp]: {
        messageId: lastUpdatedTimestamp,
        message: messageInputRef.current.value,
        sentBy: userContext.user.email.slice(
          0,
          userContext.user.email.indexOf("@")
        ),
        userId: userContext.user.email,
        OrderId: siteEnggChatModalOpen.orderId,
      },
    };
    setChatData((prev) => {
      return {
        ...prev,
        ...newObjectOfChat,
      };
    });
    messageInputRef.current.value = "";
    // reftoFocus.current.scrollIntoView();
    updateFBRDB(lastUpdatedTimestamp, newObjectOfChat[lastUpdatedTimestamp]);
    // reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);

  return (
    <Modal
      open={siteEnggChatModalOpen.modal}
      onClose={() => {
        setSiteEnggChatModalOpen({ modal: false, orderId: "" });
      }}
      // maxWidth="sm"

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

      // fullWidth={true}
    >
      <Box
        className="chat-box-approval"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          width: "70%",
          height: "90%",
          //   maxWidth: "50%",
        }}
      >
        <div className="chat-modal-container siteEnggChatContainer">
          <div className="chat-modal-header">
            <h2 style={{ textAlign: "center" }}>Remarks & Comments</h2>
          </div>
          <div className="chat-modal-body">
            <div className="body-chat-container">
              {Object.values(chatData).map((sChat, scIndex) => {
                return (
                  <div
                    key={scIndex}
                    className={`chat-bubble ${
                      userContext.user.email === sChat.userId
                        ? "same-user"
                        : "diff-user"
                    }`}
                  >
                    <div
                      style={{
                        color:
                          sChat.userId == userContext.user.email
                            ? "white"
                            : "black",
                        background:
                          sChat.userId == userContext.user.email
                            ? "#394867"
                            : "#E3F4F4",
                        padding: "10px",
                        borderRadius: "10px",
                        fontSize: "0.9em",
                        maxWidth: "70%",
                      }}
                    >
                      <p style={{ fontWeight: "600" }}>
                        {userContext.user.email === sChat.userId ? (
                          "You"
                        ) : (
                          <span style={{ color: "red" }}>{sChat.sentBy}</span>
                        )}
                      </p>
                      {sChat?.imageURL && (
                        <img
                          className="messageImage"
                          src={sChat.imageURL}
                          alt={sChat.messageId}
                        />
                      )}
                      <p>{sChat.message}</p>
                    </div>
                    {/* <p style={{ color: "black", fontSize: "0.7em" }}>
                      {sChat.date} | {sChat.time}
                    </p> */}
                  </div>
                );
              })}
              {/* <div style={{ paddingTop: "1em" }}></div> */}
              <div ref={reftoFocus}></div>
            </div>
            <div className="chat-modal-bottom-container">
              <TextField
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    console.log("shift pressed");

                    e.preventDefault();
                    if (messageInputRef.current.value.length > 0) {
                      UpdateChatData();
                    }
                  }
                }}
                multiline
                style={{ width: "100%" }}
                placeholder="Message"
                maxRows={4}
                inputRef={messageInputRef}
                //   ref={messageInputRef}
                //   width="420px"
                onBlur={() => {
                  console.log(messageInputRef.current.value);
                }}
              />
              <IoSend
                size="27px"
                onClick={() => {
                  console.log(
                    "Text Field value",
                    messageInputRef.current.value
                  );
                  if (messageInputRef.current.value.length > 0) {
                    UpdateChatData();
                    // reftoFocus.current?.scrollIntoView({ behavior: "smooth" });
                    // console.log()
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default SiteEnggChatModal;
