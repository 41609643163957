import { useEffect, useState, useContext } from "react";
import "./releasedOrders.scss";
import { PopOverView, DropDownMUI, Button } from "../../components";
import {
  FiChevronDown,
  FiChevronUp,
  FiMoreVertical,
  FiTruck,
} from "react-icons/fi";
import { FaCheck, FaWhatsapp } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { MdChecklistRtl, MdDelete } from "react-icons/md";
import config from "../../config/config";
import { toast } from "react-toast";
import getWorksMaterials from "../../helpers/getWorksMaterials";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import { UserContext } from "../../Context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "../../config/firebase";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import getVendorPrice from "../../helpers/getVendorPrice";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import { saveWorkButtonClicked } from "./helper";
import { BiEditAlt, BiUpArrowAlt } from "react-icons/bi";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SmsService from "../../api/smsService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Badge, Dialog } from "@material-ui/core";
import ShareModal from "./ShareModal";
import POService from "../../api/poService";
import PaymentOption from "../Finance/paymentOption";
import SiteEnggChatModal from "./SiteEnggChatModal";
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { environment } from "../../config";


const ReleasedOrders = () => {
  const { record } = useAnalyticsContext();

  const location = useLocation();
  // const data = location.state.data;
  const data = location.state.data;

  const history = useHistory();

  // const [data, setData] = useState(location.state.data)

  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;

  const [activeMainTab, setActiveMainTab] = useState(1);
  const [category, setCategory] = useState("");

  const [isSpinner, setSpinner] = useState(true);
  const [displayPurchaseAmount, setDisplayPurchaseAmount] = useState(true);

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + data.ProjectId.toString())
      .set({ ...payload, rooms: JSON.stringify(payload.rooms) });
  const { pathname } = useLocation();
  const [revision, setRevision] = useState(data.rooms);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("All");
  const [searchItems, setSearchItems] = useState("");
  const [workType, setWorkType] = useState("All");
  const [status, setStatus] = useState("All");
  const [showMore, setShowMore] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [shareModalOrder, setShareModalOrder] = useState();
  const [paymentOption, setPaymentOption] = useState({
    isModalOpen: false,
    isRequest: true,
  });
  const [siteEnggChatModalOpen, setSiteEnggChatModalOpen] = useState({
    modal: false,
    orderId: "",
  });

  const [selectedOrderMilestoneData, setSelectedOrderMilestoneData] = useState(
    {}
  );



  useEffect(() => {
    if (data) {
      setRevision(data.rooms);
    }
  }, [data]);

  const [releasedQuotations, setReleasedQuotations] = useState([]);

  useEffect(() => {
    fetchQuotations();
  }, []);

  const fetchQuotations = async () => {
    setSpinner(true);
    await fetch(`${config.quotationService}getQuotation/${data.ProjectId}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from fetchQuotations API: ", json);
        setReleasedQuotations(json.servicesPayload);
      })
      .catch((e) => {
        console.log("fetchQuotations catch: ", e.toString());
      });
  };

  const deleteQuotationFDB = async (quotationId) => {
    setSpinner(true);
    await fetch(`${config.quotationService}quotationDelete/${quotationId}`, {
      method: "DELETE",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from deleteQuotaion API: ", json);
        toast.error("Successfully Deleted Quotation!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
        return json;
      })
      .catch((e) => {
        console.log("deleteQuotation catch: ", e.toString());
        toast.error("Error deleting Quotation!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
    setSpinner(false);
  };

  const [releasedOrders, setReleasedOrders] = useState([]);

  useEffect(() => {
    fetchReleasedOrders();
  }, []);

  const fetchReleasedOrders = async () => {
    setSpinner(true);
    await fetch(`${config.orderService}getServiceOrders/${data.ProjectId}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from getServiceOrders API: ", json);
        setReleasedOrders(json.servicesPayload);
      })
      .catch((e) => {
        console.log("getServiceOrders catch: ", e.toString());
        toast.error("Error fetching orders data!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
    setSpinner(false);
  };


  //   const handleReleasedOrder = (FirmId) => {
  //     const id = sessionStorage.getItem('User');
  //     const firmId = JSON.parse(id).firmId;
  //     console.log(firmId);


  //     const dataToSend = {
  //       poAnalytics: {},
  //       poHistory: {}
  //     }



  //     firebase
  //       .database()
  //       .ref(`poInformation/${firmId}`).set({
  //         request: dataToSend
  //       });
  // }


  // const handleReleasedOrder = (FirmId) => {
  //   const id = sessionStorage.getItem('User');
  //   const firmId = JSON.parse(id).firmId;
  //   console.log(firmId);

  //   const updatedAnalyticsData = {
  //     count: 10,
  //     date: new Date().toISOString(),
  //     poId: 1234567,
  //     id: 'new-id',
  //     name: "Monday",
  //     topHistory: {},
  //     value: 122345
  //   };

  //   const updatedHistoryData = {
  //     category: "new-category",
  //     name: "new-name",
  //     value: 1000
  //   };

  //   const poAnalyticsRef = firebase.database().ref(`poInformation/${firmId}/poAnalytics`);
  //   const poHistoryRef = firebase.database().ref(`poInformation/${firmId}/poHistory`);

  //   const updatePoAnalytics = (ref, updatedData) => {
  //     ref.once('value')
  //       .then(snapshot => {
  //         const existingData = snapshot.val();
  //         if (existingData) {
  //           const updates = {};
  //           Object.keys(existingData).forEach(timestampKey => {
  //             updates[`${timestampKey}`] = {
  //               ...existingData[timestampKey],
  //               ...updatedData
  //             };
  //           });

  //           return ref.update(updates);
  //         }
  //       })
  //       .then(() => {
  //         console.log('poAnalytics data updated successfully.');
  //       })
  //       .catch(error => {
  //         console.error('Error updating poAnalytics data:', error);
  //       });
  //   };

  //   const updatePoHistory = (ref, updatedData) => {
  //     ref.once('value')
  //       .then(snapshot => {
  //         const existingData = snapshot.val();
  //         if (existingData) {
  //           const updates = {};
  //           Object.keys(existingData).forEach(timestampKey => {
  //             const currentArray = existingData[timestampKey] || [];
  //             updates[`${timestampKey}`] = [...currentArray, updatedData];
  //           });

  //           return ref.update(updates);
  //         }
  //       })
  //       .then(() => {
  //         console.log('poHistory data updated successfully.');
  //       })
  //       .catch(error => {
  //         console.error('Error updating poHistory data:', error);
  //       });
  //   };

  //   updatePoAnalytics(poAnalyticsRef, updatedAnalyticsData);
  //   updatePoHistory(poHistoryRef, updatedHistoryData);
  // };


  useEffect(() => {
    var releasedCategories = [];
    releasedQuotations.map((item) => releasedCategories.push(item.Category));
    console.log("category is", releasedCategories)
    releasedOrders.map((item) => releasedCategories.push(item.Category));
    setCategories([...new Set(releasedCategories)]);
  }, [releasedQuotations, releasedOrders]);

  const removeFromAnalytics = async (orderId) => {
    // orderId = String(1717414032310);
    console.log("We can remove it from here...");
    // handling the things from the
    const id = localStorage.getItem("User");
    if (!id) {
      console.error("User ID not found in session storage.");
      return;
    }
    const parsedId = JSON.parse(id);
    if (!parsedId || !parsedId.firmId) {
      console.error("Firm ID not found in parsed user data.");
      return;
    }
    const FirmId = parsedId.firmId;
    const releasedPORef = firebase
      .database()
      .ref(`poInformation/${FirmId}/releasedPO`);
    const poAnalyticsRef = firebase
      .database()
      .ref(`poInformation/${FirmId}/poAnalytics`);
    const poHistoryRef = firebase
      .database()
      .ref(`poInformation/${FirmId}/poHistory`);
    console.log("orderId : ", orderId);
    const releasedDaySnapshot = await releasedPORef
      .child(orderId)
      .once("value");
    if (releasedDaySnapshot.val()) {
      let releasedDayData = releasedDaySnapshot.val();
      console.log("releasedDayData : ", releasedDayData);
      // getting the PO value and removing it from poHistory
      const poHistorySnapshot = await poHistoryRef
        .child(releasedDayData.day + "/" + orderId)
        .once("value");
      console.log("poHistorySnapshot : ", poHistorySnapshot.val());
      const unReleasedPoValue = poHistorySnapshot.val().value;
      console.log("unReleasedPoValue : ", unReleasedPoValue);
      await poHistoryRef.child(releasedDayData.day + "/" + orderId).remove();
      await releasedPORef.child(orderId).remove();
      const poAnalyticsSnapshot = await poAnalyticsRef
        .child(releasedDayData.day)
        .once("value");
      let poAnalyticsValue = poAnalyticsSnapshot.val();
      poAnalyticsValue.count = poAnalyticsValue.count - 1;
      poAnalyticsValue.value = poAnalyticsValue.value - unReleasedPoValue;

      if (poAnalyticsValue.topHistory.hasOwnProperty(orderId)) {
        delete poAnalyticsValue.topHistory[orderId];
      }
      await poAnalyticsRef.child(releasedDayData.day).update(poAnalyticsValue);
      return;
    }
    removeIfFreightIsThere(orderId)
  };


  const removeIfFreightIsThere = async (orderId) => {
    const dbRef = firebase.database().ref(`freightUpdate/${orderId}`);
    const snapshot = await dbRef.once('value');
    if (snapshot.exists()) {
      const data = snapshot.val();
      dbRef.remove();
    }
  }


  const removeFreightFromBOQ = async (orderId) => {
    try {
      const boqRef = firebase.database().ref(`liveBOQ/${data.ProjectId.toString()}/rooms`);
      const boqSnapshot = await boqRef.once('value');
      const roomsJson = boqSnapshot.val();

      let rooms;
      if (typeof roomsJson === 'string') {
        rooms = JSON.parse(roomsJson);
      } else {
        rooms = roomsJson;
      }

      console.log("Rooms data:", rooms);

      if (rooms) {
        const nonTenderRoom = rooms.find(room => room["Room Name"] === "Non tender items");
        if (nonTenderRoom) {
          const freightUnit = nonTenderRoom.Units.find(unit => unit["Unit Name"] === "Freight Charges");
          if (freightUnit && freightUnit.Components) {
            const freightComponent = freightUnit?.Components[0];
            if (freightComponent && freightComponent?.Material) {
              const dbRef = firebase.database().ref(`freightUpdate/${orderId}`);
              const snapshot = await dbRef.once('value');
              const freightIndex = snapshot.val();

              if (freightIndex !== null && freightIndex !== undefined) {
                console.log("Freight Index:", freightIndex);

                const freightItemIndex = freightComponent?.Material.findIndex((item, index) => index === freightIndex.index.freightChargesIndex);
                const freightItemIndexWork = freightComponent?.Work.findIndex((item, index) => index === freightIndex.index.freightChargesIndex);
                console.log("freightItemIndex:", freightItemIndex)
                console.log("freightComponent?.Material[freightIndex]?.index.freightChargesIndex", freightComponent?.Material[freightItemIndex])

                if (freightItemIndex != -1) {
                  if (freightComponent && freightComponent.Material && freightComponent.Material[freightItemIndex]) {
                    freightComponent.Material.splice(freightItemIndex, 1);
                  }
                  await boqRef.set(JSON.stringify(rooms));
                  console.log("Freight item deleted from BOQ.");
                }


                if (freightItemIndexWork != -1) {
                  if (freightComponent && freightComponent.Work && freightComponent.Work[freightItemIndexWork]) {
                    freightComponent.Work.splice(freightItemIndexWork, 1);
                  }
                  await boqRef.set(JSON.stringify(rooms));
                  console.log("Freight item deleted from BOQ.");
                }
                else {
                  console.log("Freight item not found at the specified index in BOQ.");
                }
              } else {
                console.log("Freight index not found in freightUpdate.");
              }
            } else {
              console.log("Freight component or Material not found.");
            }
          } else {
            console.log("Freight unit or Components not found.");
          }
        } else {
          console.log("Non tender room not found.");
        }
      } else {
        console.log("Rooms data not found.");
      }
    } catch (error) {
      console.error("Error while deleting freight item from BOQ:", error);
    }
  }

  const unReleaseOrder = async (orderId, releasedItem) => {
    setSpinner(true);
    let newRevision = [...revision];

    JSON.parse(releasedItem.Data).data.forEach((order) => {
      console.log("order", order, "releasedItem", releasedItem);
      let cWork = order;
      let cRoom = newRevision[cWork.indexces.roomIndex];
      let Units = cRoom.Units;
      let cUnit = Units[cWork.indexces.unitIndex];
      let Components = cUnit.Components;
      let cComponent = Components[cWork.indexces.componentIndex];

      if ("vendorCategory" in order) {
        let Work = [...cComponent.Work];
        Work.splice(cWork.indexces.workIndex, 1, { ...cWork, status: "" });
        cComponent = { ...cComponent, Work };
      } else {
        console.log("material category doing things");
        let Material = [...cComponent.Material];
        Material.splice(cWork.indexces.materialIndex, 1, {
          ...cWork,
          status: "",
        });
        cComponent = { ...cComponent, Material };
      }

      Components.splice(cWork.indexces.componentIndex, 1, cComponent);
      cUnit = { ...cUnit, Components };
      Units.splice(cWork.indexces.unitIndex, 1, cUnit);
      cRoom = { ...cRoom, Units };
      newRevision.splice(cWork.indexces.roomIndex, 1, cRoom);
      setRevision(newRevision);
    });

    saveWorkButtonClicked(
      { ...data, rooms: newRevision },
      undefined,
      updateFBRDB
    );

    console.log("released Item for vendor no: ", releasedItem);
    const smsService = new SmsService();
    await smsService.notifyUnreleasedsms({
      to: releasedItem.VendorMobile,
      firm: userContext.user.Firm,
      category: releasedItem.Category,
    });

    await deleteOrder(orderId);
    await removeFreightFromBOQ(orderId)
    await updateAsUnreleasedPO(orderId);
    await fetchReleasedOrders();
    setActiveCategory("All");
    await removeFromAnalytics(orderId);
  };

  const onDeleteQuotation = async (orderId, deletedItem) => {
    let newRivision = [...revision];
    JSON.parse(deletedItem.Data).data.forEach((order) => {
      let cWork = order;
      let cRoom = newRivision[cWork.indexces.roomIndex];
      let Units = cRoom.Units;
      let cUnit = Units[cWork.indexces.unitIndex];
      let Components = cUnit.Components;
      let cComponent = Components[cWork.indexces.componentIndex];
      if ("vendorCategory" in order) {
        let Work = [...cComponent.Work];
        Work.splice(cWork.indexces.workIndex, 1, {
          ...cWork,
          // status: ""
        });
        cComponent = { ...cComponent, Work };
      } else {
        let Material = [...cComponent.Material];
        Material.splice(cWork.indexces.materialIndex, 1, {
          ...cWork,
          // status: "",
        });
        cComponent = { ...cComponent, Material };
      }
      Components.splice(cWork.indexces.componentIndex, 1, cComponent);
      cUnit = { ...cUnit, Components };
      Units.splice(cWork.indexces.unitIndex, 1, cUnit);
      cRoom = { ...cRoom, Units };
      newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
      setRevision(newRivision);
    });
    // console.log('new Revision :  ',newRivision)
    saveWorkButtonClicked(
      { ...data, rooms: newRivision },
      undefined,
      updateFBRDB
    );
    console.log(
      "qutoation to  be delete : ",
      orderId,
      "order Id ",
      deletedItem
    );
    // const smsService = new SmsService();
    // await smsService.notifyUnreleasedsms({
    //   to: releasedItem.VendorMobile,
    //   firm : userContext.user.Firm,
    //   category: releasedItem.Category,
    //   // contact :

    // })
    console.log("deleteQuotation::: ");
    await deleteQuotationFDB(orderId);

    fetchQuotations();
    // await deleteOrder(orderId);
    // await deletePO(orderId);
    // await fetchReleasedOrders();
    // await fetchQuotations();

    setActiveCategory("All");
    setSpinner(false);
  };

  const deleteOrder = async (orderId) => {
    await fetch(`${config.orderService}ServiceOrders/${orderId}`, {
      method: "DELETE",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from deleteOrder API: ", json);
      })
      .catch((e) => {
        console.log("deleteOrder catch: ", e.toString());
        toast.error("Error deleting order!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
  };

  const updateAsUnreleasedPO = async (orderId) => {
    const poService = new POService();
    await poService
      .unReleasePO(orderId, {
        AmendmentDetails: {
          isUnreleased: true,
          UnreleasedOn: new Date().getTime(),
        },
      })
      .then((res) => console.log("respones from updating the po", res))
      .catch((e) => {
        console.log("UpdatePO catch: ", e.toString());
        toast.error("Error Updating PO!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
  };

  const styles = {
    outerCircle: {
      height: 25,
      width: 25,
      borderRadius: 50,
      border: "1px solid #FFA000",
      backgroundColor: "#FFF8E1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    innerCircle: {
      height: 15,
      width: 15,
      borderRadius: 50,
      backgroundColor: "#FFA000",
    },

    leftRightDashedUnit: {
      height: 4,
      width: 100,
      border: "2px dashed #FFECB3",
    },

    leftRightUnit: {
      height: 4,
      width: 120,
      backgroundColor: "#FFECB3",
    },
    centerUnit: {
      height: 25,
      width: 10,
      backgroundColor: "#FFECB3",
      cursor: "pointer",
    },

    leftRightUnitSelected: {
      height: 7.5,
      width: 30,
      backgroundColor: "#FFECB3",
    },
    selectedMilestone: {
      height: 30,
      width: 30,
      borderRadius: 50,
      border: "1px solid #FFA000",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const [backToTop, setBackTotop] = useState(false);

  useEffect(() => {
    document
      .getElementById("container")
      .addEventListener("scroll", function () {
        if (this.scrollTop === 0) {
          setBackTotop(false);
        } else {
          setBackTotop(true);
        }
      });
  }, []);

  const backToTopHandler = () => {
    document.getElementById("btn").addEventListener("click", function () {
      document
        .getElementById("container")
        .scroll({ top: 0, behavior: "smooth" });
    });
  };

  const unreleaseConfirmation = (itemId, item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px ",
            }}>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "700",
                padding: "15px",
                marginBottom: "10px",
              }}>
              Do you want to unrelease the order?
            </h4>
            <div>
              <button
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginRight: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  unReleaseOrder(itemId, item);
                  onClose();
                }}>
                Yes
              </button>
              <button
                onClick={onClose}
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginLeft: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}>
                No
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const delelteQutotationConfirmation = (itemId, item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px ",
            }}>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "700",
                padding: "15px",
                marginBottom: "10px",
              }}>
              Do you want to Delete the Quotation?
            </h4>
            <div>
              <button
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginRight: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onDeleteQuotation(itemId, item);
                  onClose();
                }}>
                Yes
              </button>
              <button
                onClick={onClose}
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginLeft: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}>
                No
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const [highlightOrder, setHighlightOrder] = useState();

  useEffect(() => {
    if (location.state.state) {
      console.log("location.state", location.state);

      setActiveCategory(location.state.state.selectedIndex["selectedCategory"]);
      console.log("active category is", location.state.state.selectedIndex["selectedCategory"])
      setHighlightOrder(location.state.state.selectedIndex.OrderIdSelected);
    }
  }, [location]);

  useEffect(() => {
    if (highlightOrder != undefined) {
      console.log("it is coming to highlight", highlightOrder)
      let elementToBeFocused = document.getElementById(
        `${highlightOrder} ${location.state.state.selectedIndex.ItemIndex} ${location.state.state.selectedIndex.UnitIndex
        } ${location.state.state.selectedIndex.ComponentIndex} ${location.state.state.MaterialItem
          ? location.state.state.selectedIndex.materialIndex
          : location.state.state.selectedIndex.workIndex
        }`
      );
      elementToBeFocused && elementToBeFocused.focus();
      elementToBeFocused && elementToBeFocused.classList.add("highlightdiv");
    }
  }, [highlightOrder, isSpinner, releasedOrders, releasedQuotations]);

  useEffect(() => {
    let highlightTimer = setTimeout(() => {
      let textarea = (elem, regex) => {
        for (let i = 0; i < elem.length; i++) {
          elem[i].innerHTML = elem[i].innerHTML.replace(regex, "<b>$&</b>");
        }
      };
      textarea(
        document.querySelectorAll(".desc-highlight-brand-released-orders"),
        /@[a-zA-Z]{1,}/g
      );
    }, 0);
    return () => clearTimeout(highlightTimer);
  });

  return (
    <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <div
        className={"mainTabs"}
        style={{ top: 0, width: "100%", margin: "auto", position: "sticky" }}>
        <div>
          <div
            className={"boqTab"}
            onClick={() => {
              setActiveMainTab(0);
              history.push("/ProjectPage/" + data.ProjectId);
              record("@boq_tab_click_success", {
                description: "BOQ tab click success",
              });
            }}>
            <div style={{ fontWeight: "bold", fontSize: 20 }}>BOQ</div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
                <div>Rs {(data && getComponentsPrice(data).toFixed(2)) || 0}</div>
              )}
          </div>
          <div className={activeMainTab === 0 ? "active" : ""}></div>
        </div>

        <div>
          <div
            className={"indentTab"}
            onClick={() => {
              if (accountType == "BOQ" || accountType == "Finance") {
                toast.error("You don't have access to Purchase!", {
                  backgroundColor: "red",
                  color: "black",
                });
              } else {
                if (data?.rooms) {
                  setActiveMainTab(1);
                  saveWorkButtonClicked(data, setSpinner);
                } else {
                  toast.error("Empty BOQ. Please add to proceed!", {
                    backgroundColor: "red",
                    color: "black",
                  });
                }
              }
              record("@purchase_tab_click_success", {
                description: "Purchase tab click success",
              });
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontWeight: "bold" }}>Purchase</div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (displayPurchaseAmount) {
                    setDisplayPurchaseAmount(false);
                  } else {
                    setDisplayPurchaseAmount(true);
                  }
                }}>
                {!displayPurchaseAmount ? (
                  <VisibilityOff style={{ marginLeft: 2 }} />
                ) : (
                  <Visibility style={{ marginLeft: 2 }} />
                )}
              </div>
            </div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
                <div>
                  Rs{" "}
                  {(data &&
                    !displayPurchaseAmount &&
                    getVendorPrice(data).toFixed(2)) ||
                    "********"}
                </div>
              )}
          </div>
          <div className={activeMainTab === 1 ? "active" : ""}></div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "25px",
          top: 75,
          alignItems: "center",
          position: "sticky",
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            cursor: "pointer",
          }}>
          {data && (
            <p
              style={{
                fontWeight: "bold",
                marginRight: "20px",
                cursor: "default",
              }}>
              {data.clientName ? data.clientName : data.ClientName}
            </p>
          )}
          <div
            onClick={() => {
              history.push("/UnreleasedItems/" + data.ProjectId, {
                data: data,
              });
              record("@unreleaseditems_tab_click_success", {
                description: "unreleaseditems tab click",
              });
            }}>
            <p>Unreleased Items</p>
          </div>
          <div className={"activeText"}>
            <p>Released Orders</p>
            <div className={"activeTab"}></div>
          </div>
          <div
            onClick={() => {
              history.push("/Timeline/" + data.ProjectId, { data: data });
              record("@timeline_tab_click_success", {
                description: "timeline tab click",
              });
            }}>
            <p>Timeline</p>
          </div>
        </div>
        <div style={{ width: "20%" }}>
          <Button
            content="Generate PO/ WO/ WQ/ MQ"
            onClick={() => {
              history.push("/OrdersQuotations/" + data.ProjectId, {
                data: {
                  ...data,
                  rooms: data.rooms,
                  ProjectId: data.ProjectId,
                  ClientName: data.ClientName,
                  Address: data.Address,
                  AmountRecieved: data.AmountReceived,
                  SiteEngineerId: data.SiteEngineersId,
                },
              });
              record("@generate_button_click_success", {
                description: "generate button click success",
              });
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <div
          style={{
            border: "1px solid #E1E1E1",
            width: "20%",
            height: "78% ",
            position: "absolute",
          }}>
          <p
            style={{
              color: "#231700",
              margin: "10px",
              position: "sticky",
              top: 10,
            }}>
            {activeCategory == "All"
              ? // ? releasedOrders.filter((item) => item.ApprovalStatus != "inApproval").length + releasedQuotations.length
              // : releasedOrders.filter((item) => item.ApprovalStatus != "inApproval" && item.Category == activeCategory)
              releasedOrders.length + releasedQuotations.length
              : releasedOrders.filter((item) => item.Category == activeCategory)
                .length +
              releasedQuotations.filter(
                (item) => item.Category == activeCategory
              ).length}
            {" released orders/ quotations"}
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
              position: "sticky",
              top: 40,
            }}>
            <p style={{ fontWeight: "bold" }}>Category</p>
            <p style={{ fontWeight: "bold" }}>Amount</p>
          </div>
          {isSpinner ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <CircularProgress
                style={{ width: "30px", height: "30px", color: "#fdd34d" }}
              />
            </div>
          ) : (
            <div style={{ height: "85%", width: "100%", overflowY: "scroll" }}>
              <div
                style={
                  "All" == activeCategory
                    ? {
                      backgroundColor: "#FFF8E1",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
                    : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
                }
                onClick={() => {
                  setActiveCategory("All");
                }}>
                <p>All</p>
                <p>
                  {"\u20B9 "}
                  {(
                    [...releasedQuotations]
                      .map(
                        (item, index) =>
                          // item?.ApprovalStatus != "inApproval" &&
                          JSON.parse(item.Data)
                            .data.map((dItem, dIndex) =>
                              (
                                dItem.quantity * dItem.rate +
                                dItem.quantity *
                                dItem.rate *
                                (dItem.gst / 100) || 0
                              ).toFixed(2)
                            )
                            .reduce((total, item) => (total += Number(item)), 0)
                        // .toFixed(2)
                      )
                      .reduce((total, item) => (total += Number(item)), 0) +
                    [...releasedOrders]
                      .filter((item) => item.ApprovalStatus != "inApproval")
                      .reduce((totalCategoryAmount, item) => {
                        return (totalCategoryAmount += Number(
                          item.OrderTotalAmount
                        ));
                      }, 0)
                  ).toFixed(2)}
                </p>
              </div>

              {categories
                .sort((a, b) => a.localeCompare(b))
                .map((category, index) => (
                  <div
                    key={index}
                    style={
                      category == activeCategory
                        ? {
                          backgroundColor: "#FFF8E1",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 10,
                          cursor: "pointer",
                        }
                        : {
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 10,
                          cursor: "pointer",
                        }
                    }
                    onClick={() => {
                      setActiveCategory(category);
                    }}>
                    <p>{category}</p>
                    <p>
                      {"\u20B9 "}
                      {(
                        [...releasedQuotations]
                          .map(
                            (item, index) =>
                              item.Category == category &&
                              JSON.parse(item.Data)
                                .data.map((dItem, dIndex) =>
                                  (
                                    dItem.quantity * dItem.rate +
                                    dItem.quantity *
                                    dItem.rate *
                                    (dItem.gst / 100) || 0
                                  ).toFixed(2)
                                )
                                .reduce(
                                  (total, item) => (total += Number(item)),
                                  0
                                )
                            // .toFixed(2)
                          )
                          .reduce((total, item) => (total += Number(item)), 0) +
                        [...releasedOrders]
                          .filter(
                            (item) =>
                              item.ApprovalStatus != "inApproval" &&
                              item.Category === category
                          )
                          .reduce((totalCategoryAmount, item) => {
                            return (totalCategoryAmount += Number(
                              item.OrderTotalAmount
                            ));
                          }, 0)
                      ).toFixed(2)}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>

        <div
          style={{
            width: "80%",
            padding: "0px 10px 10px 10px",
            marginLeft: "21%",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "space-between",
            }}>
            <div style={{ marginBottom: 10 }}>
              <DropDownMUI
                placeholder={"Work Type"}
                data={["All", "Only Work", "Work + Material", "Only Material"]}
                value={workType}
                onChange={(value) => {
                  setWorkType(value);
                }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <DropDownMUI
                placeholder={"Status"}
                data={[
                  "All",
                  "Order placed",
                  "Order accepted",
                  "Order completed",
                  "Quotation released",
                ]}
                value={status}
                onChange={(value) => {
                  setStatus(value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                border: "1px solid #3535354d",
                width: "100%",
                marginBottom: 10,
                borderRadius: 5,
              }}>
              <AiOutlineSearch
                color="grey"
                size="20"
                style={{ margin: "0px 10px" }}
              />
              <input
                placeholder="Search by description or vendor name"
                style={{
                  width: "100%",
                  fontSize: "16px",
                  borderWidth: 0,
                  border: "none",
                  outline: "none",
                }}
                value={searchItems}
                onChange={(e) => {
                  setSearchItems(e.currentTarget.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              height: "68%",
              width: "75%",
              overflowY: "scroll",
            }}
            id="container">
            {releasedQuotations &&
              releasedQuotations.map((item, index) => {
                console.log("description is", item?.description);
                console.log("vendor is", item?.VendorName);
                return (
                  (activeCategory == item?.Category ||
                    activeCategory == "All") &&
                  (searchItems.length > 0
                    ? item?.VendorName?.toLowerCase().includes(
                      searchItems.toLowerCase()
                    ) ||
                    JSON.parse(item?.Data)
                      ?.data[0]?.description?.toLowerCase()
                      .includes(searchItems.toLowerCase())
                    : item?.VendorName || item?.description == null
                      ? true
                      : item?.VendorName?.toLowerCase().includes(
                        searchItems.toLowerCase()
                      ) ||
                      JSON.parse(item?.Data)
                        ?.data[0]?.description?.toLowerCase()
                        .includes(searchItems.toLowerCase())) &&
                  ((JSON.parse(item.Data)?.data[0]?.workType == undefined
                    ? workType == "Only Material"
                    : workType == JSON.parse(item.Data)?.data[0]?.workType) ||
                    workType == "All") &&
                  (item?.Status.toLowerCase().includes(status.toLowerCase()) ||
                    status == "All") && (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#FDD34D",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}>
                      <div
                        style={{
                          backgroundColor: "#FFF8E1",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                          padding: "10px",
                          borderRadius: "4px",
                        }}>
                        <div
                          style={{
                            width: "48%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 20,
                              alignItems: "center",
                            }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 30,
                                width: 30,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderRadius: "50%",
                                borderColor: "#FDD34D",
                              }}>
                              {JSON.parse(item.Data)?.data[0]?.workType ==
                                "Only Work" ? (
                                <GrUserWorker size={15} />
                              ) : JSON.parse(item.Data)?.data[0]?.workType ==
                                "Work + Material" ? (
                                <>
                                  <GrUserWorker size={10} />
                                  <FiTruck size={10} />
                                </>
                              ) : (
                                <FiTruck size={15} />
                              )}
                            </div>
                            <p style={{ fontWeight: "500", width: "auto" }}>
                              {item?.Status}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 25,
                              fontWeight: "400",
                              fontSize: "14px",
                            }}>
                            <p>
                              Start Date:{" "}
                              {JSON.parse(item.Data)
                                ?.StartDate.substring(0, 10)
                                .split("-")
                                .reverse()
                                .join(" / ")}
                            </p>
                            <p>
                              End Date:{" "}
                              {JSON.parse(item.Data)
                                ?.EndDate.substring(0, 10)
                                .split("-")
                                .reverse()
                                .join(" / ")}
                            </p>
                          </div>
                        </div>

                        <p style={{ width: "10%", paddingTop: 5 }}>Quantity</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>Unit</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>Rate</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>GST</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>Amount</p>
                        <div
                          style={{
                            width: "2%",
                            paddingTop: 5,
                            cursor: "pointer",
                          }}>
                          <PopOverView
                            position="left"
                            component={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                  fontSize: 12,
                                  color: "grey",
                                  alignItems: "flex-start",
                                  width: 140,
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    delelteQutotationConfirmation(
                                      item?.Id,
                                      item
                                    );
                                    record(
                                      "@delete_quotation_icon_click_success",
                                      {
                                        description:
                                          "delete order icon click success",
                                      }
                                    );
                                  }}
                                  className="menuItem">
                                  <span>
                                    <MdDelete size={17} />
                                  </span>
                                  <span style={{ padding: "0px 0px 3px 8px" }}>
                                    Delete Quotation
                                  </span>
                                </div>
                              </div>
                            }>
                            <FiMoreVertical />
                          </PopOverView>
                        </div>
                      </div>

                      {JSON.parse(item?.Data) &&
                        JSON.parse(item?.Data)?.data.map(
                          (dataItem, dataIndex) => (
                            <div key={dataIndex}>
                              <div
                                key={dataIndex}
                                style={{
                                  padding: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "8px",
                                  margin: "10px",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                  }}>
                                  <p style={{ width: "48%" }}>
                                    <p style={{ fontWeight: "600" }}>
                                      {dataItem["Unit Name"]}
                                    </p>
                                    <br />
                                    {"type" in dataItem
                                      ? dataItem?.type
                                      : dataItem?.vendorCategory}
                                    {" | "}

                                    {dataItem.description &&
                                      dataItem.description.length > 100 ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}
                                      // className="desc-highlight-brand"
                                      >
                                        <span style={{ width: "80%" }}>
                                          {showMore.includes(
                                            dataItem.description
                                          ) ? (
                                            <span>
                                              {dataItem.description
                                                .replace(/<new-line>/g, "\n")
                                                .replace(/<single-quote>/g, "'")
                                                .replace(
                                                  /<double-quote>/g,
                                                  '"'
                                                )}
                                            </span>
                                          ) : (
                                            <span>
                                              {dataItem.description
                                                .replace(/<new-line>/g, "\n")
                                                .replace(/<single-quote>/g, "'")
                                                .replace(/<double-quote>/g, '"')
                                                .substring(0, 100)}
                                              ...
                                            </span>
                                          )}
                                        </span>
                                        <AiOutlineInfoCircle
                                          title={
                                            showMore.length > 0
                                              ? "ShowLess"
                                              : "ShowMore"
                                          }
                                          size={20}
                                          style={{
                                            color: "gray",
                                            cursor: "pointer",
                                            marginRight: "90px",
                                          }}
                                          onClick={() => {
                                            if (
                                              showMore.includes(
                                                dataItem.description
                                              )
                                            ) {
                                              setShowMore(
                                                showMore.filter(
                                                  (item) =>
                                                    item !==
                                                    dataItem.description
                                                )
                                              );
                                            } else {
                                              setShowMore([
                                                ...showMore,
                                                dataItem.description,
                                              ]);
                                            }
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span>
                                        {dataItem.description &&
                                          dataItem.description
                                            .replace(/<new-line>/g, "\n")
                                            .replace(/<single-quote>/g, "'")
                                            .replace(/<double-quote>/g, '"')}
                                      </span>
                                    )}
                                    {dataItem.specification &&
                                      dataItem.specification.length > 100 ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}>
                                        <span style={{ width: "80%" }}>
                                          {showMore.includes(
                                            dataItem.specification
                                          ) ? (
                                            <span>
                                              {dataItem.specification
                                                .replace(/<new-line>/g, "\n")
                                                .replace(/<single-quote>/g, "'")
                                                .replace(
                                                  /<double-quote>/g,
                                                  '"'
                                                )}
                                            </span>
                                          ) : (
                                            <span>
                                              {dataItem.specification
                                                .replace(/<new-line>/g, "\n")
                                                .replace(/<single-quote>/g, "'")
                                                .replace(/<double-quote>/g, '"')
                                                .substring(0, 100)}
                                              ...
                                            </span>
                                          )}
                                        </span>
                                        <AiOutlineInfoCircle
                                          title={
                                            showMore.length > 0
                                              ? "ShowLess"
                                              : "ShowMore"
                                          }
                                          size={20}
                                          style={{
                                            color: "gray",
                                            cursor: "pointer",
                                            marginRight: "90px",
                                          }}
                                          onClick={() => {
                                            if (
                                              showMore.includes(
                                                dataItem.specification
                                              )
                                            ) {
                                              setShowMore(
                                                showMore.filter(
                                                  (item) =>
                                                    item !==
                                                    dataItem.specification
                                                )
                                              );
                                            } else {
                                              setShowMore([
                                                ...showMore,
                                                dataItem.specification,
                                              ]);
                                            }
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span style={{ width: "80%" }}>
                                        {dataItem.specification &&
                                          dataItem.specification
                                            .replace(/<new-line>/g, "\n")
                                            .replace(/<single-quote>/g, "'")
                                            .replace(/<double-quote>/g, '"')}
                                      </span>
                                    )}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {dataItem?.quantity.length == 0
                                      ? 0
                                      : dataItem?.quantity}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {dataItem?.unit}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {"\u20B9 "}
                                    {dataItem?.rate}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {dataItem?.gst} %
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {"\u20B9 "}
                                    {(
                                      dataItem?.quantity * dataItem?.rate +
                                      dataItem?.quantity *
                                      dataItem?.rate *
                                      (dataItem?.gst / 100) || 0
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  )
                );
              })}

            {isSpinner ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <CircularProgress style={{ color: "#fdd34d" }} />
              </div>
            ) : (
              releasedOrders &&
              releasedOrders.map((item, index) => {
                return (
                  (activeCategory == item?.Category ||
                    activeCategory == "All") &&
                  (searchItems.length > 0
                    ? item?.VendorName?.toLowerCase().includes(
                      searchItems.toLowerCase()
                    ) ||
                    JSON.parse(item?.Data)
                      ?.data[0]?.description?.toLowerCase()
                      .includes(searchItems.toLowerCase())
                    : item?.VendorName || item?.description == null
                      ? true
                      : item?.VendorName.toLowerCase().includes(
                        searchItems.toLowerCase()
                      ) ||
                      JSON.parse(item?.Data)
                        ?.data[0]?.description.toLowerCase()
                        .includes(searchItems.toLowerCase())) &&
                  ((JSON.parse(item.Data)?.data[0]?.workType == undefined
                    ? workType == "Only Material"
                    : workType == JSON.parse(item.Data)?.data[0]?.workType) ||
                    workType == "All") &&
                  (item?.Status.toLowerCase().includes(status.toLowerCase()) ||
                    status == "All") && (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "#FDD34D",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}>
                      <div
                        style={{
                          backgroundColor: "#FFF8E1",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontWeight: "bold",
                          padding: "10px",
                          borderRadius: "4px",
                        }}>
                        <div
                          style={{
                            width: "48%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 20,
                              alignItems: "center",
                            }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 30,
                                width: 30,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderRadius: "50%",
                                borderColor: "#FDD34D",
                              }}>
                              {JSON.parse(item.Data)?.data[0]?.workType ==
                                "Only Work" ? (
                                <GrUserWorker size={15} />
                              ) : JSON.parse(item.Data)?.data[0]?.workType ==
                                "Work + Material" ? (
                                <>
                                  <GrUserWorker size={10} />
                                  <FiTruck size={10} />
                                </>
                              ) : (
                                <FiTruck size={15} />
                              )}
                            </div>
                            <p style={{ fontWeight: "500", width: "auto" }}>
                              {item?.Status}
                              {" | "}
                              {item?.VendorName == null ||
                                (item.VendorName == "null" &&
                                  item?.VendorMobileFromId == null &&
                                  item?.VendorMobile == null) ||
                                (item.VendorMobile == "null" &&
                                  item?.VendorNameFromId == null)
                                ? "Not accepted by any vendor yet!"
                                : `${(item?.VendorName !== "null" &&
                                  item?.VendorName) ||
                                item?.VendorNameFromId
                                } (${item?.VendorMobile ||
                                item?.VendorMobileFromId
                                })`}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 25,
                              fontWeight: "400",
                              fontSize: "14px",
                            }}>
                            <p>
                              Start Date:{" "}
                              {JSON.parse(item.Data)
                                ?.StartDate.substring(0, 10)
                                .split("-")
                                .reverse()
                                .join(" / ")}
                            </p>
                            <p>
                              End Date:{" "}
                              {JSON.parse(item.Data)
                                ?.EndDate.substring(0, 10)
                                .split("-")
                                .reverse()
                                .join(" / ")}
                            </p>
                          </div>
                        </div>
                        <p style={{ width: "10%", paddingTop: 5 }}>Quantity</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>Unit</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>Rate</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>GST</p>
                        <p style={{ width: "10%", paddingTop: 5 }}>Amount</p>
                        <div
                          style={{
                            width: "2%",
                            paddingTop: 5,
                            cursor: "pointer",
                          }}>
                          <PopOverView
                            position="left"
                            component={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                  fontSize: 12,
                                  color: "grey",
                                  alignItems: "flex-start",
                                  width: 140,
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "orangered",
                                  }}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    if (
                                      new Date(item.OrderId) >
                                      new Date(1680328732000) ||
                                      data.ProjectId === "1670912315229"
                                    ) {
                                      console.log(
                                        "edit go to order quotations page"
                                      );
                                      history.push(
                                        "/OrdersQuotations/" + data.ProjectId,
                                        {
                                          data: {
                                            ...data,
                                            rooms: data.rooms,
                                            ProjectId: data.ProjectId,
                                            ClientName: data.ClientName,
                                            Address: data.Address,
                                            AmountRecieved: data.AmountReceived,
                                            SiteEngineerId:
                                              data.SiteEngineersId,
                                          },
                                          releasedOrderDataFromReleased: item,
                                          releasedItem: true,
                                        }
                                      );
                                    } else {
                                      toast.error("You can't edit this Order");
                                    }
                                    console.log(
                                      "edit go to order quotations page qwqq",
                                      data.rooms
                                    );
                                  }}
                                  className="menuItem">
                                  <span>
                                    <BiEditAlt size={18} />
                                  </span>
                                  <span style={{ padding: "0px 0px 3px 10px" }}>
                                    Edit
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "royalblue",
                                  }}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();

                                    const url =
                                      environment === "staging"
                                        ? "https://staartstaging.netlify.app/?po_id=" +
                                        item?.Id
                                        : "https://staartbuild.netlify.app/?po_id=" +
                                        item?.Id;

                                    const win = window.open(url, "_blank");

                                    win.focus();
                                    record("@viewOrder_icon_click_success", {
                                      description:
                                        "view order icon click success",
                                    });
                                  }}
                                  className="menuItem">
                                  <span>
                                    <AiOutlineEye size={18} />
                                  </span>
                                  <span style={{ padding: "0px 0px 3px 10px" }}>
                                    View Order
                                  </span>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "forestgreen",
                                  }}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    setShareModal(true);
                                    setShareModalOrder(item);
                                  }}
                                  className="menuItem">
                                  <span>
                                    <FaWhatsapp size={18} />
                                  </span>
                                  <span style={{ padding: "0px 0px 3px 10px" }}>
                                    Share
                                  </span>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "firebrick",
                                  }}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    unreleaseConfirmation(item?.Id, item);
                                    record(
                                      "@unrelease_order_icon_click_success",
                                      {
                                        description:
                                          "unrelease order icon click success",
                                      }
                                    );
                                  }}
                                  className="menuItem">
                                  <span>
                                    <IoReturnUpBackSharp size={20} />
                                  </span>
                                  <span style={{ padding: "0px 0px 3px 8px" }}>
                                    Unrelease Order
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color: "blue",
                                  }}
                                  onMouseDown={(e) => {
                                    e.stopPropagation();
                                    setSiteEnggChatModalOpen({
                                      modal: true,
                                      orderId: item.OrderId,
                                    });
                                  }}
                                  className="menuItem">
                                  <span>
                                    <MdChecklistRtl size={20} />
                                  </span>
                                  <span style={{ padding: "0px 0px 3px 8px" }}>
                                    Remarks and Comments
                                  </span>
                                </div>
                              </div>
                            }>
                            <FiMoreVertical />
                          </PopOverView>
                        </div>
                      </div>

                      {JSON.parse(item?.Data) &&
                        JSON.parse(item?.Data)?.data.map(
                          (dataItem, dataIndex) => (
                            <div key={dataIndex}>
                              <div
                                tabIndex="1"
                                id={`${dataItem?.OrderId} ${dataItem?.indexces?.roomIndex
                                  } ${dataItem?.indexces?.unitIndex} ${dataItem?.indexces?.componentIndex
                                  } ${dataItem?.indexces?.workIndex
                                    ? dataItem?.indexces?.workIndex
                                    : dataItem?.indexces?.materialIndex
                                  }`}
                                key={dataIndex}
                                style={{
                                  padding: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "8px",
                                  margin: "10px",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                  }}>
                                  <p style={{ width: "48%" }}>
                                    <span style={{ fontWeight: 600 }}>
                                      {dataItem["UnitName"] ||
                                        dataItem["Unit Name"]}
                                    </span>

                                    <br />
                                    {"type" in dataItem
                                      ? dataItem?.type
                                      : dataItem?.vendorCategory}
                                    {" | "}
                                    {dataItem.description &&
                                      dataItem.description.length > 100 ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}>
                                        <span
                                          style={{
                                            width: "80%",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            history.push(
                                              "/ProjectPage/" + data.ProjectId,
                                              {
                                                fromReleased: true,
                                                materialItem: dataItem?.workType
                                                  ? false
                                                  : true,
                                                indexArr: dataItem["indexces"],
                                              }
                                            );
                                          }}>
                                          {showMore.includes(
                                            dataItem.description
                                          ) ? (
                                            <div key={dataItem.OrderId}>
                                              <span className="desc-highlight-brand-released-orders">
                                                {dataItem.description
                                                  .replace(/<new-line>/g, "\n")
                                                  .replace(
                                                    /<single-quote>/g,
                                                    "'"
                                                  )
                                                  .replace(
                                                    /<double-quote>/g,
                                                    '"'
                                                  )}
                                              </span>
                                            </div>
                                          ) : (
                                            <>
                                              <span className="desc-highlight-brand-released-orders">
                                                {dataItem.description
                                                  .replace(/<new-line>/g, "\n")
                                                  .replace(
                                                    /<single-quote>/g,
                                                    "'"
                                                  )
                                                  .replace(
                                                    /<double-quote>/g,
                                                    '"'
                                                  )
                                                  .substring(0, 100)}
                                                ...
                                              </span>
                                            </>
                                          )}
                                        </span>
                                        <AiOutlineInfoCircle
                                          title={
                                            showMore.length > 0
                                              ? "ShowLess"
                                              : "ShowMore"
                                          }
                                          size={20}
                                          style={{
                                            color: "gray",
                                            cursor: "pointer",
                                            marginRight: "90px",
                                          }}
                                          onClick={() => {
                                            // console.log('clicked')
                                            if (
                                              showMore.includes(
                                                dataItem.description
                                              )
                                            ) {
                                              // console.log('closing')
                                              setShowMore(
                                                showMore.filter(
                                                  (item) =>
                                                    item !==
                                                    dataItem.description
                                                )
                                              );
                                            } else {
                                              // console.log('opening')
                                              setShowMore([
                                                ...showMore,
                                                dataItem.description,
                                              ]);
                                            }
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        className="desc-highlight-brand-released-orders"
                                        style={{
                                          width: "80%",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          history.push(
                                            "/ProjectPage/" + data.ProjectId,
                                            {
                                              fromReleased: true,
                                              materialItem: dataItem?.workType
                                                ? false
                                                : true,
                                              indexArr: dataItem["indexces"],
                                            }
                                          );
                                        }}>
                                        {dataItem.description &&
                                          dataItem.description
                                            .replace(/<new-line>/g, "\n")
                                            .replace(/<single-quote>/g, "'")
                                            .replace(/<double-quote>/g, '"')}
                                      </span>
                                    )}
                                    {dataItem.specification &&
                                      dataItem.specification.length > 100 ? (
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                        }}>
                                        <span
                                          style={{
                                            width: "80%",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            history.push(
                                              "/ProjectPage/" + data.ProjectId,
                                              {
                                                fromReleased: true,
                                                materialItem: dataItem?.workType
                                                  ? false
                                                  : true,
                                                indexArr: dataItem["indexces"],
                                              }
                                            );
                                          }}>
                                          {showMore.includes(
                                            dataItem.specification
                                          ) ? (
                                            <div key={dataItem.OrderId}>
                                              <span className="desc-highlight-brand-released-orders">
                                                {dataItem.specification
                                                  .replace(/<new-line>/g, "\n")
                                                  .replace(
                                                    /<single-quote>/g,
                                                    "'"
                                                  )
                                                  .replace(
                                                    /<double-quote>/g,
                                                    '"'
                                                  )}
                                              </span>
                                            </div>
                                          ) : (
                                            <span className="desc-highlight-brand-released-orders">
                                              {dataItem.specification
                                                .replace(/<new-line>/g, "\n")
                                                .replace(/<single-quote>/g, "'")
                                                .replace(/<double-quote>/g, '"')
                                                .substring(0, 100)}
                                              ...
                                            </span>
                                          )}
                                        </span>
                                        <AiOutlineInfoCircle
                                          title={
                                            showMore.length > 0
                                              ? "ShowLess"
                                              : "ShowMore"
                                          }
                                          size={20}
                                          style={{
                                            color: "gray",
                                            cursor: "pointer",
                                            marginRight: "90px",
                                          }}
                                          onClick={() => {
                                            if (
                                              showMore.includes(
                                                dataItem.specification
                                              )
                                            ) {
                                              setShowMore(
                                                showMore.filter(
                                                  (item) =>
                                                    item !==
                                                    dataItem.specification
                                                )
                                              );
                                            } else {
                                              setShowMore([
                                                ...showMore,
                                                dataItem.specification,
                                              ]);
                                            }
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          width: "80%",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          history.push(
                                            "/ProjectPage/" + data.ProjectId,
                                            {
                                              fromReleased: true,
                                              materialItem: dataItem?.workType
                                                ? false
                                                : true,
                                              indexArr: dataItem["indexces"],
                                            }
                                          );
                                        }}
                                        className="desc-highlight-brand-released-orders">
                                        {dataItem.specification &&
                                          dataItem.specification
                                            .replace(/<new-line>/g, "\n")
                                            .replace(/<single-quote>/g, "'")
                                            .replace(/<double-quote>/g, '"')}
                                      </span>
                                    )}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {dataItem?.quantity.length == 0
                                      ? 0
                                      : dataItem?.quantity}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {dataItem?.unit}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {"\u20B9 "}
                                    {dataItem?.rate}
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {dataItem?.gst} %
                                  </p>
                                  <p style={{ width: "10%" }}>
                                    {"\u20B9 "}
                                    {(
                                      dataItem?.quantity * dataItem?.rate +
                                      dataItem?.quantity *
                                      dataItem?.rate *
                                      (dataItem?.gst / 100) || 0
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      <div
                        className="order-item-milestones"
                        style={{ margin: "20px" }}>
                        <h5>Payment Milestones</h5>
                        {JSON.parse(item.CommonMilestones) == null ? (
                          <div className="order-no-milestones">
                            <p>This is an Old Order! </p>
                          </div>
                        ) : (
                          <div>
                            <div
                              style={{ margin: "20px 0px", display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}>
                                    <div style={styles.outerCircle}>
                                      <div style={styles.innerCircle} />
                                    </div>
                                    <div style={styles.leftRightDashedUnit} />
                                  </div>
                                  <small>Placed</small>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}>
                                    <div style={styles.selectedMilestone}>
                                      {item.Status == "Order accepted" && (
                                        <FaCheck color={"#FDD34D"} />
                                      )}
                                    </div>
                                    <div style={styles.leftRightUnit} />
                                  </div>
                                  <small>Accepted</small>
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                {JSON.parse(item?.CommonMilestones)?.map(
                                  (milestone, milestoneIndex) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 10,
                                          minWidth: "150px",
                                          maxWidth: "160px",
                                        }}
                                        key={milestoneIndex}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                          }}>
                                          <div style={styles.selectedMilestone}>
                                            {"status" in milestone &&
                                              milestone?.status ==
                                              "confirmed" && (
                                                <FaCheck color={"#FDD34D"} />
                                              )}
                                            {milestone.totalAmount -
                                              ("payments" in milestone
                                                ? milestone.payments.reduce(
                                                  (total, item) => {
                                                    // console.log('inside the milestones if payment is there',item )
                                                    return (total =
                                                      total +
                                                      Number(item.amount));
                                                  },
                                                  0
                                                )
                                                : 0.0
                                              ).toFixed(2) <=
                                              0.0 ||
                                              milestone.name === "Paid" ? (
                                              <FaCheck color={"#FDD34D"} />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          {milestoneIndex <
                                            JSON.parse(item.CommonMilestones)
                                              .length -
                                            1 && (
                                              <div style={styles.leftRightUnit} />
                                            )}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "120px",
                                          }}>
                                          <small>{milestone?.name}</small>
                                          <small>
                                            ({milestone.percentage}%)
                                          </small>
                                        </div>
                                        <div>
                                          <button
                                            disabled={
                                              milestone.totalAmount -
                                              ("payments" in milestone
                                                ? milestone.payments.reduce(
                                                  (total, item) => {
                                                    return (total =
                                                      total +
                                                      Number(item.amount));
                                                  },
                                                  0
                                                )
                                                : 0.0
                                              ).toFixed(2) <=
                                              0.0 || milestone.name === "Paid"
                                            }
                                            onClick={() => {
                                              console.log(
                                                "item from releasedOrders : ",
                                                item
                                              );
                                              setCategory(item.Category);
                                              setShareModalOrder(item);
                                              setSelectedOrderMilestoneData({
                                                CommonMilestones: JSON.parse(
                                                  item?.CommonMilestones
                                                ),
                                                milestoneIndex,
                                              });
                                              JSON.parse(
                                                item?.CommonMilestones
                                              );
                                              setPaymentOption((prev) => {
                                                return {
                                                  isRequest: true,
                                                  isModalOpen: true,
                                                };
                                              });
                                            }}
                                            className="request-button">
                                            Request
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })
            )}
            {backToTop && (
              <button
                id="btn"
                title="back to top"
                onClick={backToTopHandler}
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#2f4858",
                  marginTop: "5px",
                  position: "fixed",
                  bottom: "4%",
                  left: "96%",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "white",
                }}>
                <BiUpArrowAlt size={30} style={{ marginTop: "2px" }} />
              </button>
            )}
          </div>
        </div>
      </div>
      {paymentOption.isModalOpen && (
        <PaymentOption
          // cashPay={cashPay}
          // setCashPay={setCashPay}
          // vendorMob={vendorData}
          category={category}
          paymentOption={paymentOption}
          setPaymentOption={setPaymentOption}
          // totalOrderAmount={
          //   selectedOrderMilestoneData.CommonMilestones &&
          //   selectedOrderMilestoneData.CommonMilestones[
          //     selectedOrderMilestoneData.milestoneIndex
          //   ]?.totalAmount
          // }
          totalOrderAmount={
            selectedOrderMilestoneData.CommonMilestones &&
            selectedOrderMilestoneData.CommonMilestones.reduce((accumulator, currentObject) => {
              return accumulator + Number(currentObject.totalAmount)
            }, 0)
          }
          // totalOrderAmount={
          //   selectedOrderMilestoneData.CommonMilestones &&
          //   selectedOrderMilestoneData.CommonMilestones[
          //     selectedOrderMilestoneData.milestoneIndex
          //   ]?.totalAmount
          // }
          totalPaidAmount={
            // " 3023"
            selectedOrderMilestoneData.CommonMilestones.length > 0 &&
            (selectedOrderMilestoneData.milestoneIndex != undefined &&
              "payments" in
              selectedOrderMilestoneData.CommonMilestones[
              selectedOrderMilestoneData.milestoneIndex
              ]
              ? selectedOrderMilestoneData.CommonMilestones[
                selectedOrderMilestoneData.milestoneIndex
              ]?.payments.reduce(
                (total, item) => (total = total + Number(item.amount)),
                0
              )
              : 0 || 0
            ).toFixed(2)
          }
          totalRemainingAmount={
            //  "32323"
            selectedOrderMilestoneData.CommonMilestones.length > 0 &&
            (
              selectedOrderMilestoneData.CommonMilestones[
                selectedOrderMilestoneData.milestoneIndex
              ]?.totalAmount -
              (selectedOrderMilestoneData.CommonMilestones &&
                "payments" in
                selectedOrderMilestoneData.CommonMilestones[
                selectedOrderMilestoneData.milestoneIndex
                ]
                ? selectedOrderMilestoneData.CommonMilestones[
                  selectedOrderMilestoneData.milestoneIndex
                ]?.payments.reduce(
                  (total, item) => (total = total + Number(item?.amount)),
                  0
                )
                : 0 || 0
              ).toFixed(0)
            ).toFixed(2)
          }
          selectedOrderMilestoneData={
            selectedOrderMilestoneData.CommonMilestones
          }
          selectedOrderId={shareModalOrder.OrderId}
          selectedMilestoneIndex={selectedOrderMilestoneData.milestoneIndex}
          startDate={JSON.parse(shareModalOrder.Data)?.StartDate}
          ProjectId={location.pathname.split("/")[2]}
          ClientName={data.clientName ? data.clientName : data.ClientName}
          vendorCompanyName={shareModalOrder?.VendorId}
        />
      )}

      <Dialog
        open={shareModal}
        onClose={() => {
          setShareModal(false);
        }}
        maxWidth="sm"
        fullWidth={true}>
        <ShareModal
          setShareModal={setShareModal}
          order={shareModalOrder}
          projectId={location.pathname.split("/")[2]}
          ClientName={data.clientName ? data.clientName : data.ClientName}
        />
      </Dialog>

      {siteEnggChatModalOpen.modal && (
        <SiteEnggChatModal
          siteEnggChatModalOpen={siteEnggChatModalOpen}
          setSiteEnggChatModalOpen={setSiteEnggChatModalOpen}
        />
      )}
    </div>
  );
};

export default drawerTemplate1(ReleasedOrders);
