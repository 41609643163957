import { React, useContext, useState } from "react";
import "./drawerTemplate.scss";
import {
  FiHome,
  FiInbox,
  FiSettings,
  FiServer,
  FiArchive,
  FiUsers,
} from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../containers/DashboardPage/Logo/index";
import {
  Input,
  Divider,
  TextArea,
  Button,
  FinanceCard,
  SearchBar,
  OrderCard,
  NewOrderCard,
  Label,
  FileExplorer,
} from "../components";
import { UserContext } from "../Context/UserContext";
import { useAnalyticsContext, TAGS } from "../Context/Analytics";
import { BiRupee } from "react-icons/bi";
import { GoGraph } from "react-icons/go";

export default (OldComponent, setSortedProjects, projects) => {
  const NewComponent = (props) => {
    const history = useHistory();
    const location = useLocation();
    const userContext = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [onAuthChanged, SetOnAuthChanged] = useState(null);
    const { record } = useAnalyticsContext();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div id="drawerTemplate">
        <div id="dpDrawer">
          <div id="dpdpic">
            <abbr title="Click to upload Image">
              <Logo />
            </abbr>
          </div>
          <div id="dpdCompanyName">
            <Label
              content={userContext.user.Firm}
              size="30px"
              textAlign="center"
              fontWeight="bold"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label content={userContext.user.email} size="small" />
          </div>
          <div id="dpdEmail">
            <Label content={userContext.user.userName} size="small" />
          </div>

          <div
            className={`dpdMenuItem ${
              location.pathname == "/" && "dpdMenuItemActive"
            }`}
            onClick={() => {
              history.push("/");
              record("@home_icon_click_success", {
                description: "home icon click success",
              });
            }}
          >
            <FiHome />
            <div>Home</div>
          </div>
          {/* <div
            className={`dpdMenuItem ${
              location.pathname == "/Archived" && "dpdMenuItemActive"
            }`}
            onClick={() => {
              history.push("/Archived");
              // record("@home_icon_click_success", { description: "home icon click success" })
            }}
          >
            <FiArchive />
            <div>Archived</div>
          </div> */}
          {userContext.user.accountType === "Admin" ||
          userContext.user.accountType === "Owner" ||
          userContext.user.accountType === "Finance" ||
          userContext.user.accountType === "Project" ? (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/Analytics" && "dpdMenuItemActive"
              }`}
              onClick={() => {
                history.push("/Analytics");
                record("@analytics_icon_click_success", {
                  description: "analytics icon click success",
                });
              }}
            >
              <FiUsers />
              <div>Users</div>
            </div>
          ) : null}
          {/* {userContext.user.accountType === "Admin" ||
          userContext.user.accountType === "Owner" ? (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/Ticket" && "dpdMenuItemActive"
              }`}
              onClick={() => {
                history.push("/Ticket");
                record("@issue_icon_click_success", {
                  description: "issue icon click success",
                });
              }}
            >
              <FiInbox />
              <div>Issues</div>
            </div>
          ) : null} */}

          {/* <div
            className={`dpdMenuItem ${
              location.pathname == "/ChangePassword" && "dpdMenuItemActive"
            }`}
            onClick={() => {
              history.push("/ChangePassword");
              record("@setting_icon_click_success", {
                description: "setting icon click success",
              });
            }}
          >
            <FiSettings />
            <div>Settings</div>
          </div> */}
          {(userContext.user.accountType === "Admin" ||
            userContext.user.accountType === "Owner") && (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/SuggestiveItems" && "dpdMenuItemActive"
              }`}
              onClick={() => {
                history.push("/SuggestiveItems");
                record("@suggestive_items_icon_click_success", {
                  description: "suggestive items icon click success",
                });
              }}
            >
              <FiServer />
              <div>Suggestive Items</div>
            </div>
          )}
          {userContext.user.accountType === "Admin" ||
          userContext.user.accountType === "Owner" ||
          userContext.user.accountType === "Finance" ||
          userContext.user.accountType === "Project" ? (
            <div
              className={`dpdMenuItem ${
                location.pathname == "/FinanceRecords" && "dpdMenuItemActive"
              }`}
              onClick={() => {
                history.push("/FinanceRecords");
                record("@financeApprovals request_icon_click_success", {
                  description: "financeApprovals icon click success",
                });
              }}
            >
              <BiRupee size={20} />
              <div>Finance Approvals</div>
            </div>
          ) : null}
          <div
              className={`dpdMenuItem ${
                location.pathname == "/POAnalytics" && "dpdMenuItemActive"
              }`}
              onClick={() => {
                history.push("/POAnalytics");
                console.log('Go to analytics button pressed...');
              }}
            >
              <GoGraph size={20} />
              <div>Analytics</div>
            </div>
          <div
            className="dpdMenuItem"
            onClick={() => {
              // record(TAGS["@exit_icon_click_success", { description: "Exit icon click success" }]);
              record("@exit_icon_click_success", {
                description: "Exit icon click success",
              });
              history.push("/");
              props.onAuthChanged(null);
              localStorage.clear();
              sessionStorage.clear();
            }}
          >
            <FiLogOut size={20} />
            <div>Logout</div>
          </div>
        </div>
        <div id="drawerContent">
          <OldComponent {...props} />
        </div>
      </div>
    );

    // {
    //     userContext.user.accountType === "Admin" ? ["/", "/Analytics", "/Ticket", "/ChangePassword", "/SuggestiveItems"] : ["/", "/Analytics", "/Ticket", "/ChangePassword"]
    // }
  };

  return NewComponent;
};
