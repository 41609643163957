import React from "react";
import "./button.scss";

export default ({ invert, content, onClick, style }) => {
  return (
    <div
      className={`${invert ? "invert1" : "button1"} commonBtn1 `}
      style={style}
      onClick={onClick}
    >
      {content}
    </div>
  );
};
