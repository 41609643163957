import React from "react";
import Styled from "styled-components";

const Container = Styled.div`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0px;
    left: 0px;

    background-color: #FFF8E144;

    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
`;

const ActualModal = Styled.div`
    width: 80% !important;
    min-width: 600px !important;
    max-width: 1280px !important;

    height: 90% !important;
    min-height: 400px !important;
    max-height: 800px !important;

    border-radius: 0px !important;
    border: none !important;
    background-color: #fff !important;

    display: flex;
    flex-direction: column;
    overflow: auto !important;

    position: relative;
    z-index: 10000;

    -webkit-box-shadow: 0px 0px 30px 4px rgba(171,171,171,0.4) !important;
    -moz-box-shadow: 0px 0px 30px 4px rgba(171,171,171,0.4) !important;
    box-shadow: 0px 0px 30px 4px rgba(171,171,171,0.4) !important;

    &:hover
    {
        border: none !important;
        border-radius: 0px !important;
        padding: 0px !important;
        height: auto!;
        cursor: auto !important;
    }
`;

const TitleBar = Styled.div`
    width: 100% !important;
    height: 80px !important;
    flex-shrink: 0;
    background-color: rgb(255, 211, 77) !important;
    position: sticky;
    top: 0;
    left: 0;
    padding: 0px 24px;

    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
`;

const TitleText = Styled.p`
    font-size: 22px;
    font-weight: 600;
`;

const CloseText = Styled(TitleText)`
    cursor: pointer;
`;

const POModal = (props) => {
  const handleOnClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <Container>
      <ActualModal id="overflow-print">
        <TitleBar id="po-print-titlebar">
          <TitleText>Purchase Order</TitleText>
          <CloseText onClick={() => handleOnClose()}>Close</CloseText>
        </TitleBar>
        {props.children}
      </ActualModal>
    </Container>
  );
};

export default POModal;
