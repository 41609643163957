import { useState } from "react";
import DrawerTemplate from "../../hoc/drawerTemplate";
// import { Tab, Tabs as NewTab, TabList, TabPanel } from "react-tabs";
import { Tabs } from "../../components";
import { Requests_Fin } from "./Requests_Fin";
import Records_Fin from "./Records_Fin";
import FinanceReqRecAnalytics from "./FinanceReqRecAnalytics";

const FinanceRec_Req = () => {
  
  const [activeTab, setActiveTab] = useState("Requests");

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      <h3 style={{ margin: "1em 2em " }}>Finance Requests & Records</h3>
      <Tabs
        tabs={["Requests", "Records", "Analytics"]}
        activeTab={activeTab}
        onChange={(tab) => {
          setActiveTab(tab);
        }}
      >
        {activeTab === "Requests" ? (
          <Requests_Fin />
        ) : activeTab === "Records" ? (
          <Records_Fin />
        ) : (
          <FinanceReqRecAnalytics />
        )}
      </Tabs>
    </div>
  );
};
export default DrawerTemplate(FinanceRec_Req);
