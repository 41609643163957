import { useState, useContext, useEffect } from "react";
import { PopOverView } from "../../components";
import { FiMoreVertical, FiTruck } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import { AiOutlineEye } from "react-icons/ai";
import config from "../../config/config";
import { toast } from "react-toast";
import { UserContext } from "../../Context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import firebase from "../../config/firebase";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import { saveWorkButtonClicked } from "./helper";
import { BiEditAlt } from "react-icons/bi";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineInfoCircle } from "react-icons/ai";
import SmsService from "../../api/smsService";
import "./approvals.scss";
import OpportunityService from "../../api/opportunityService";
import environment from "../../config/config";
import CircularProgress from "@material-ui/core/CircularProgress";

import RemarkTextBox from "../Finance/RemarksTextBox";
import POService from "../../api/poService";
import { FaHistory } from "react-icons/fa";

import { IoReturnUpBackSharp } from "react-icons/io5";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import RequestHistory from "./RequestHistory";
import ChatModal from "./ChatModal";

const Approvals = ({
  approvalOrders,
  fetchApprovalOrders,
  setApprovalOrders,
  setActiveCategory,
}) => {
  const userContext = useContext(UserContext);

  const commentHandler = async (
    IdtoUpdate,
    approvedItem,
    commenttoUpdate,
    status
  ) => {
    console.log("afd", commenttoUpdate, approvedItem);

    let lastUpdatedTimestamp = new Date().getTime();
    let lastUpdatedDate =
      new Date(lastUpdatedTimestamp).getDate() +
      "-" +
      (new Date(lastUpdatedTimestamp).getMonth() + 1) +
      "-" +
      new Date(lastUpdatedTimestamp).getFullYear();
    let lastUpdatedTime =
      new Date(lastUpdatedTimestamp).getHours() +
      ":" +
      new Date(lastUpdatedTimestamp).getMinutes();

    let previousHistory = { ...JSON.parse(approvedItem.requestHistory) };
    let objecttoupdate = {
      ...previousHistory,
      [new Date().getTime()]: {
        sendTo: "", //same all the time
        raised: Object.values(previousHistory)[0].raised || "", //same all the time
        comments: commenttoUpdate,
        status:
          status === "Decline"
            ? "Declined"
            : status === "Hold"
              ? "On hold"
              : "Approved",
        date: lastUpdatedDate,
        time: lastUpdatedTime,
        userId: userContext.user.email, //  email of current  updating user
        By: userContext.user.email.slice(
          0,
          userContext.user.email.indexOf("@")
        ), // name/email of current  updating user
      },
    };
    console.log("oject to update ", objecttoupdate);

    const opportunityService = new OpportunityService();
    const poService = new POService();
    await opportunityService.updateOpportunityById(IdtoUpdate, {
      requestHistory: objecttoupdate,
    });
    // await poService
    //   .unReleasePO(IdtoUpdate, { approvalDetails: objecttoupdate })
    //   .then((res) => console.log("res", res)); // the name is just "unrelasePO" just to update the po with orderId not POID
    return objecttoupdate;
  };

  const { record } = useAnalyticsContext();

  const location = useLocation();
  const data = location.state.data;

  const history = useHistory();

  const [isSpinner, setSpinner] = useState(false);
  const [requestHistoryModal, setRequestHistoryModal] = useState(false);
  const [chatModal, setChatModal] = useState(false);

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + data.ProjectId.toString())
      .set({ ...payload, rooms: JSON.stringify(payload.rooms) });

  const [revision, setRevision] = useState(data.rooms);

  const [showMore, setShowMore] = useState([]);

  useEffect(() => {
    // desc-highlight-brand
    let highlightTimer = setTimeout(() => {
      let textarea = (elem, regex) => {
        for (let i = 0; i < elem.length; i++) {
          elem[i].innerHTML = elem[i].innerHTML.replace(regex, "<b>$&</b>");
        }
        // Takes an element, and a regex. The regex should have the "g" flag set
      };
      // let textarea = document.querySelector(".desc-highlight-brand")
      // console.log('text area divs',textarea)
      textarea(
        document.querySelectorAll(".desc-highlight-brand-released-orders"),
        /@[a-zA-Z]{1,}/g
      );
    }, 0);

    // let testing = document.querySelectorAll(".desc-highlight-brand")
    // console.log('testing ',testing);

    return () => clearTimeout(highlightTimer);
  });

  //this use Effect for navigaition

  // useEffect(() => {
  //   if (data) {
  //     setRevision(data.rooms);
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (activeCategory) {
  //     const { Work, Material } = getWorksMaterials({ rooms: revision });
  //     const works = Work;
  //     const materials = Material;
  //     setWorks(works);
  //     setMaterials(materials);
  //   }
  // }, [revision]);

  // useEffect(() => {
  //   // setSpinner(true)
  //   let projectId = location.pathname.split("/")[2];
  //   firebase
  //     .database()
  //     .ref("liveBOQ/" + projectId.toString())
  //     .on("value", (snapshot) => {
  //       // console.log('Firebase SS', snapshot.val())
  //       // setSpinner(false)
  //       if (snapshot.exists()) {
  //         const response = {
  //           ...snapshot.val(),
  //           rooms: JSON.parse(snapshot.val().rooms),
  //           Rooms: JSON.parse(snapshot.val().rooms),
  //         }; // has to replace with rooms or Rooms
  //         console.log("response :", response);
  //         setProjectDetails(response);
  //         // setSelectedProject(response);
  //       }
  //     });
  // }, []);

  // const [releasedOrders, setReleasedOrders] = useState(approvalOrders);
  const [declineModal, setdeclineModal] = useState({
    modalOpen: false,
    type: "",
  });
  const [commenttoUpdate, setComment] = useState("");
  const [selectedOrderToChange, setSelectedOrderToChange] = useState({
    item: {},
    indexToUpdate: 0,
  });
  // useEffect(() => {
  //   fetchReleasedOrders();
  // }, []);

  // useEffect(() => {
  //   var releasedCategories = [];
  //   // releasedQuotations.map((item) => releasedCategories.push(item.Category));
  //   releasedOrders.map((item) =>item.ApprovalStatus === "inApproval" && releasedCategories.push(item.Category));
  //   setCategories([...new Set(releasedCategories)]);
  // }, [releasedOrders]);

  // const styles = {
  //   outerCircle: {
  //     height: 25,
  //     width: 25,
  //     borderRadius: 50,
  //     border: "1px solid #FFA000",
  //     backgroundColor: "#FFF8E1",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  //   innerCircle: {
  //     height: 15,
  //     width: 15,
  //     borderRadius: 50,
  //     backgroundColor: "#FFA000",
  //   },

  //   leftRightDashedUnit: {
  //     height: 4,
  //     width: 100,
  //     border: "2px dashed #FFECB3",
  //   },

  //   leftRightUnit: {
  //     height: 4,
  //     width: 100,
  //     backgroundColor: "#FFECB3",
  //   },
  //   centerUnit: {
  //     height: 25,
  //     width: 10,
  //     backgroundColor: "#FFECB3",
  //     cursor: "pointer",
  //   },

  //   leftRightUnitSelected: {
  //     height: 7.5,
  //     width: 30,
  //     backgroundColor: "#FFECB3",
  //   },
  //   selectedMilestone: {
  //     height: 30,
  //     width: 30,
  //     borderRadius: 50,
  //     border: "1px solid #FFA000",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  // };

  // const [backToTop, setBackTotop] = useState(false);

  // useEffect(() => {
  //   document
  //     .getElementById("container")
  //     .addEventListener("scroll", function () {
  //       if (this.scrollTop === 0) {
  //         setBackTotop(false);
  //       } else {
  //         setBackTotop(true);
  //       }
  //     });
  // }, []);

  // const backToTopHandler = () => {
  //   document.getElementById("btn").addEventListener("click", function () {
  //     document
  //       .getElementById("container")
  //       .scroll({ top: 0, behavior: "smooth" });
  //   });
  // };

  useEffect(() => {
    const status_value = document.querySelectorAll(".status-req-order");
    for (let i = 0; i < status_value.length; i++) {
      status_value[i].style.color =
        status_value[i].innerHTML === "Approved"
          ? "green"
          : status_value[i].innerHTML === "Declined"
            ? "red"
            : "tranparent";
    }
    // console.log(status_value, "statusaf");
    // === 'Approved' ?  :
  }, [approvalOrders, isSpinner]);

  const deleteOrder = async (orderId) => {
    await fetch(`${config.orderService}ServiceOrders/${orderId}`, {
      method: "DELETE",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from deleteOrder API: ", json);
      })
      .catch((e) => {
        console.log("deleteOrder catch: ", e.toString());
        toast.error("Error deleting order!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
  };

  const deletePO = async (orderId) => {
    await fetch(`${config.poService}poDelete/${orderId}`, {
      method: "DELETE",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from deletePO API: ", json);
      })
      .catch((e) => {
        console.log("deletePO catch: ", e.toString());
        toast.error("Error deleting PO!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });
  };

  const unreleaseConfirmation = (itemId, item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px ",
            }}
          >
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "700",
                padding: "15px",
                marginBottom: "10px",
              }}
            >
              Do you want to unrelease the order?
            </h4>
            <div>
              <button
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginRight: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  unReleaseOrder(itemId, item);
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                onClick={onClose}
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginLeft: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                No
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const approveConfirmation = async (itemId, item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px ",
            }}
          >
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "700",
                padding: "15px",
                marginBottom: "10px",
              }}
            >
              Do you want to Approve the Order? Order will be released!
            </h4>
            <div>
              <button
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginRight: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  approveHandler(itemId, item);
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                onClick={onClose}
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginLeft: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                No
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const editingTheBOQPage = async (releasedItem, isApprovalChangetoOrdered) => {
    let newRivision = [...revision];
    JSON.parse(releasedItem.Data).data.forEach((order) => {
      let cWork = order;
      let cRoom = newRivision[cWork.indexces.roomIndex];
      let Units = cRoom.Units;
      let cUnit = Units[cWork.indexces.unitIndex];
      let Components = cUnit.Components;
      let cComponent = Components[cWork.indexces.componentIndex];
      if ("vendorCategory" in order) {
        let Work = [...cComponent.Work];
        Work.splice(cWork.indexces.workIndex, 1, {
          ...cWork,
          status: isApprovalChangetoOrdered ? "ordered" : "",
        });
        cComponent = { ...cComponent, Work };
      } else {
        let Material = [...cComponent.Material];
        Material.splice(cWork.indexces.materialIndex, 1, {
          ...cWork,
          status: isApprovalChangetoOrdered ? "ordered" : "",
        });
        cComponent = { ...cComponent, Material };
      }
      Components.splice(cWork.indexces.componentIndex, 1, cComponent);
      cUnit = { ...cUnit, Components };
      Units.splice(cWork.indexces.unitIndex, 1, cUnit);
      cRoom = { ...cRoom, Units };
      newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
      setRevision(newRivision);
    });
    saveWorkButtonClicked(
      { ...data, rooms: newRivision },
      undefined,
      updateFBRDB
    );
  };

  const unReleaseOrder = async (orderId, releasedItem) => {
    setSpinner(true);
    // JSON.parse(releasedItem.Data).data.forEach((order) => {
    //   let cWork = order;
    //   let cRoom = newRivision[cWork.indexces.roomIndex];
    //   let Units = cRoom.Units;
    //   let cUnit = Units[cWork.indexces.unitIndex];
    //   let Components = cUnit.Components;
    //   let cComponent = Components[cWork.indexces.componentIndex];
    //   if ("vendorCategory" in order) {
    //     let Work = [...cComponent.Work];
    //     Work.splice(cWork.indexces.workIndex, 1, { ...cWork, status: "" });
    //     cComponent = { ...cComponent, Work };
    //   } else {
    //     let Material = [...cComponent.Material];
    //     Material.splice(cWork.indexces.materialIndex, 1, {
    //       ...cWork,
    //       status: "",
    //     });
    //     cComponent = { ...cComponent, Material };
    //   }
    //   Components.splice(cWork.indexces.componentIndex, 1, cComponent);
    //   cUnit = { ...cUnit, Components };
    //   Units.splice(cWork.indexces.unitIndex, 1, cUnit);
    //   cRoom = { ...cRoom, Units };
    //   newRivision.splice(cWork.indexces.roomIndex, 1, cRoom);
    //   setRevision(newRivision);
    // });
    // saveWorkButtonClicked(
    //   { ...data, rooms: newRivision },
    //   undefined,
    //   updateFBRDB
    // );
    await editingTheBOQPage(releasedItem, false);
    await deleteOrder(orderId);

    await deletePO(orderId);
    // await fetchReleasedOrders();
    await fetchApprovalOrders();
    // setActiveCategory("All");
    setSpinner(false);
  };

  const approveHandler = async (IdtoUpdate, approvedItem) => {
    // console.log('appCategory ', approvedItem.Category)
    setSpinner(true);

    let lastUpdatedTimestamp = new Date().getTime();
    let lastUpdatedDate =
      new Date(lastUpdatedTimestamp).getDate() +
      "-" +
      (new Date(lastUpdatedTimestamp).getMonth() + 1) +
      "-" +
      new Date(lastUpdatedTimestamp).getFullYear();
    let lastUpdatedTime =
      new Date(lastUpdatedTimestamp).getHours() +
      ":" +
      new Date(lastUpdatedTimestamp).getMinutes();

    let previousHistory = { ...JSON.parse(approvedItem.requestHistory) };
    let objecttoupdate = {
      ...previousHistory,
      [new Date().getTime()]: {
        sendTo: "", //same all the time
        raised: Object.values(previousHistory)[0].raised || "", //same all the time
        comments: "Approved",
        status: "Approved",
        date: lastUpdatedDate,
        time: lastUpdatedTime,
        userId: userContext.user.email, //  email of current  updating user
        By: userContext.user.email.slice(
          0,
          userContext.user.email.indexOf("@")
        ), // name/email of current  updating user
      },
    };

    // let objecttoupdate = {
    //   ...JSON.parse(approvedItem.approvalDetails),
    //   ApprovedOn: new Date().getTime(),
    //   status: "Ordered",
    // };
    const opportunityService = new OpportunityService();
    await opportunityService.updateOpportunityById(IdtoUpdate, {
      ApprovalStatus: "Ordered",
      requestHistory: objecttoupdate,
    });
    // const poService = new POService();
    // await poService.unReleasePO(IdtoUpdate, {
    //   approvalDetails: objecttoupdate,
    //   OlderVersions: "null",
    // });

    await editingTheBOQPage(approvedItem, true);
    // const opportunityService = new OpportunityService();
    // await opportunityService.updateOpportunityById(IdtoUpdate, {"ApprovalStatus" : "Approved" } )
    const smsService = new SmsService();
    let orderType =
      JSON.parse(approvedItem.Data)?.data[0]?.workType === "Work + Material" ||
        JSON.parse(approvedItem.Data)?.data[0]?.workType === "Only Work"
        ? "Work Order"
        : "Purchase Order";
    await smsService.sendWhatsapp({
      to: `91${approvedItem.VendorMobile}`,
      firm: userContext.user.Firm,
      orderType: orderType,
      category: [approvedItem.Category],
      link:
        environment.environment == "staging"
          ? `https://staartstaging.netlify.app/?po_id=${IdtoUpdate}`
          : `https://staartbuild.netlify.app/?po_id=${IdtoUpdate}`,
    });
    toast.success("Order Approved and Released!", {
      backgroundColor: "rgb(255, 211, 77)",
      color: "black",
    });
    await fetchApprovalOrders();
    setActiveCategory("ApprovalOrders");
    setSpinner(false);
  };

  const releaseHandler = async (IdtoUpdate, approvedItem) => {
    setSpinner(true);
    const opportunityService = new OpportunityService();
    await opportunityService.updateOpportunityById(IdtoUpdate, {
      ApprovalStatus: "Ordered",
    });
    await editingTheBOQPage(approvedItem, true);
    const smsService = new SmsService();
    let orderType =
      JSON.parse(approvedItem.Data)?.data[0]?.workType === "Work + Material" ||
        JSON.parse(approvedItem.Data)?.data[0]?.workType === "Only Work"
        ? "Work Order"
        : "Purchase Order";
    await smsService.sendWhatsapp({
      to: `91${approvedItem.VendorMobile}`,
      firm: userContext.user.Firm,
      orderType: orderType,
      category: [approvedItem.Category],
      link:
        environment.environment == "staging"
          ? `https://staartstaging.netlify.app/?po_id=${IdtoUpdate}`
          : `https://staartbuild.netlify.app/?po_id=${IdtoUpdate}`,
    });
    toast.success("Order Approved and Released!", {
      backgroundColor: "rgb(255, 211, 77)",
      color: "black",
    });
    await fetchApprovalOrders();
    setActiveCategory("ApprovalOrders");
    setSpinner(false);
  };

  // filter((item)=>JSON.parse(item.ApprovalStatus) !== null  || JSON.parse(item.ApprovalStatus) !== 'null')
  return (
    <>
      {isSpinner ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#fdd34d" }} />
        </div>
      ) : (
        approvalOrders.map((item, index) => {
          return (
            <div
              key={item.OrderId}
              style={{
                width: "100%",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#FDD34D",
                borderRadius: "4px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFF8E1",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    width: "48%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 30,
                        width: 30,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: "50%",
                        borderColor: "#FDD34D",
                      }}
                    >
                      {JSON.parse(item.Data)?.data[0]?.workType ==
                        "Only Work" ? (
                        <GrUserWorker size={15} />
                      ) : JSON.parse(item.Data)?.data[0]?.workType ==
                        "Work + Material" ? (
                        <>
                          <GrUserWorker size={10} />
                          <FiTruck size={10} />
                        </>
                      ) : (
                        <FiTruck size={15} />
                      )}
                    </div>
                    <p style={{ fontWeight: "500", width: "auto" }}>
                      {item?.Status}
                      {" | "}
                      {item?.VendorName == null && item?.VendorMobile == null
                        ? "Not accepted by any vendor yet!"
                        : `${item?.VendorName} (${item?.VendorMobile})`}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 25,
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    <p>
                      Start Date:{" "}
                      {JSON.parse(item.Data)
                        ?.StartDate.substring(0, 10)
                        .split("-")
                        .reverse()
                        .join(" / ")}
                    </p>
                    <p>
                      End Date:{" "}
                      {JSON.parse(item.Data)
                        ?.EndDate.substring(0, 10)
                        .split("-")
                        .reverse()
                        .join(" / ")}
                    </p>
                  </div>
                </div>
                <p style={{ width: "10%", paddingTop: 5 }}>Quantity</p>
                <p style={{ width: "10%", paddingTop: 5 }}>Unit</p>
                <p style={{ width: "10%", paddingTop: 5 }}>Rate</p>
                <p style={{ width: "10%", paddingTop: 5 }}>GST</p>
                <p style={{ width: "10%", paddingTop: 5 }}>Amount</p>
                <div
                  style={{
                    width: "2%",
                    paddingTop: 5,
                    cursor: "pointer",
                  }}
                >
                  <PopOverView
                    position="left"
                    component={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                          fontSize: 12,
                          color: "grey",
                          alignItems: "flex-start",
                          width: 140,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "orangered",
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();

                            console.log("edit go to order quotations page");
                            history.push(
                              "/OrdersQuotations/" + data.ProjectId,
                              {
                                data: {
                                  ...data,
                                  rooms: data.rooms,
                                  ProjectId: data.ProjectId,
                                  ClientName: data.ClientName,
                                  Address: data.Address,
                                  AmountRecieved: data.AmountReceived,
                                  SiteEngineerId: data.SiteEngineersId,
                                },
                                releasedOrderDataFromReleased: item,
                                releasedItem: true,
                                ApprovalItem: true,
                              }
                            );
                          }}
                          className="menuItem"
                        >
                          <span>
                            <BiEditAlt size={18} />
                          </span>
                          <span style={{ padding: "0px 0px 3px 10px" }}>
                            Edit
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "royalblue",
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();

                            console.log("Environment:", environment);

                            const url =
                              environment === "staging"
                                ? "https://staartstaging.netlify.app/?po_id=" +
                                item?.Id
                                : "https://staartbuild.netlify.app/?po_id=" +
                                item?.Id;
                            console.log("url is reading", url);
                            const win = window.open(url, "_blank");
                            win.focus();
                            record("@viewOrder_icon_click_success", {
                              description: "view order icon click success",
                            });
                          }}
                          className="menuItem"
                        >
                          <span>
                            <AiOutlineEye size={18} />
                          </span>
                          <span style={{ padding: "0px 0px 3px 10px" }}>
                            View Order
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "forestgreen",
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            setRequestHistoryModal(true);
                            // setShareModalOrder(item);
                            setSelectedOrderToChange({
                              item: item,
                              indexToUpdate: index,
                            });
                          }}
                          className="menuItem"
                        >
                          <span>
                            <FaHistory size={18} />
                          </span>
                          <span style={{ padding: "0px 0px 3px 10px" }}>
                            Request History
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "firebrick",
                          }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            unreleaseConfirmation(item.OrderId, item);
                            record("@unrelease_order_icon_click_success", {
                              description: "unrelease order icon click success",
                            });
                          }}
                          className="menuItem"
                        >
                          <span>
                            <IoReturnUpBackSharp size={20} />
                          </span>
                          <span style={{ padding: "0px 0px 3px 8px" }}>
                            Unrelease Order
                          </span>
                        </div>
                      </div>
                    }
                  >
                    <FiMoreVertical />
                  </PopOverView>
                </div>
              </div>

              {JSON.parse(item?.Data) &&
                JSON.parse(item?.Data)?.data.map((dataItem, dataIndex) => (
                  <div key={dataIndex}>
                    <div
                      key={dataIndex}
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <p style={{ width: "48%" }}>
                          <p style={{ fontWeight: "600" }}>
                            {dataItem["Unit Name"]}
                          </p>
                          <br />
                          {"type" in dataItem
                            ? dataItem?.type
                            : dataItem?.vendorCategory}
                          {" | "}
                          {/* {
                                                                        dataItem?.description?.replace(/<new-line>/g, "\n").replace(/<single-quote>/g, "'").replace(/<double-quote>/g, "\"")
                                                                        ||
                                                                        dataItem?.specification?.replace(/<new-line>/g, "\n").replace(/<single-quote>/g, "'").replace(/<double-quote>/g, "\"")
                                                                    } */}
                          {dataItem.description &&
                            dataItem.description.length > 100 ? (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <span style={{ width: "80%" }}>
                                {showMore.includes(dataItem.description) ? (
                                  <div key={dataItem.OrderId}>
                                    <span className="desc-highlight-brand-released-orders">
                                      {dataItem.description
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')}
                                    </span>
                                  </div>
                                ) : (
                                  <span className="desc-highlight-brand-released-orders">
                                    {dataItem.description
                                      .replace(/<new-line>/g, "\n")
                                      .replace(/<single-quote>/g, "'")
                                      .replace(/<double-quote>/g, '"')
                                      .substring(0, 100)}
                                    ...
                                  </span>
                                )}
                              </span>
                              <AiOutlineInfoCircle
                                title={
                                  showMore.length > 0 ? "ShowLess" : "ShowMore"
                                }
                                size={20}
                                style={{
                                  color: "gray",
                                  cursor: "pointer",
                                  marginRight: "90px",
                                }}
                                onClick={() => {
                                  if (showMore.includes(dataItem.description)) {
                                    setShowMore(
                                      showMore.filter(
                                        (item) => item !== dataItem.description
                                      )
                                    );
                                  } else {
                                    setShowMore([
                                      ...showMore,
                                      dataItem.description,
                                    ]);
                                  }
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              className="desc-highlight-brand-released-orders"
                              style={{ width: "80%" }}
                            >
                              {dataItem.description &&
                                dataItem.description
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                            </span>
                          )}
                          {dataItem.specification &&
                            dataItem.specification.length > 100 ? (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <span style={{ width: "80%" }}>
                                {showMore.includes(dataItem.specification) ? (
                                  <div key={dataItem.OrderId}>
                                    <span className="desc-highlight-brand-released-orders">
                                      {dataItem.specification
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')}
                                    </span>
                                  </div>
                                ) : (
                                  <span className="desc-highlight-brand-released-orders">
                                    {dataItem.specification
                                      .replace(/<new-line>/g, "\n")
                                      .replace(/<single-quote>/g, "'")
                                      .replace(/<double-quote>/g, '"')
                                      .substring(0, 100)}
                                    ...
                                  </span>
                                )}
                              </span>
                              <AiOutlineInfoCircle
                                title={
                                  showMore.length > 0 ? "ShowLess" : "ShowMore"
                                }
                                size={20}
                                style={{
                                  color: "gray",
                                  cursor: "pointer",
                                  marginRight: "90px",
                                }}
                                onClick={() => {
                                  if (
                                    showMore.includes(dataItem.specification)
                                  ) {
                                    setShowMore(
                                      showMore.filter(
                                        (item) =>
                                          item !== dataItem.specification
                                      )
                                    );
                                  } else {
                                    setShowMore([
                                      ...showMore,
                                      dataItem.specification,
                                    ]);
                                  }
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              className="desc-highlight-brand-released-orders"
                              style={{ width: "80%" }}
                            >
                              {dataItem.specification &&
                                dataItem.specification
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                            </span>
                          )}
                        </p>
                        <p style={{ width: "10%" }}>
                          {dataItem?.quantity.length == 0
                            ? 0
                            : dataItem?.quantity}
                        </p>
                        <p style={{ width: "10%" }}>{dataItem?.unit}</p>
                        <p style={{ width: "10%" }}>
                          {"\u20B9 "}
                          {dataItem?.rate}
                        </p>
                        <p style={{ width: "10%" }}>{dataItem?.gst} %</p>
                        <p style={{ width: "10%" }}>
                          {"\u20B9 "}
                          {(
                            dataItem?.quantity * dataItem?.rate +
                            dataItem?.quantity *
                            dataItem?.rate *
                            (dataItem?.gst / 100) || 0
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="approval-bottom-container">
                <div>
                  <div>
                    <h4>Comment:</h4>
                    <p>
                      {JSON.parse(item.requestHistory) &&
                        Object.values(JSON.parse(item.requestHistory))
                        [
                          Object.values(JSON.parse(item.requestHistory))
                            .length - 1
                        ]?.comments.replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"')
                          .replace(/<rupee-symbol>/g, "₹")
                          .replace(/<new-line>/g, "\n")}
                    </p>
                  </div>
                  <div>
                    <p>
                      Status:
                      <span
                        // using js to set color to just to avoid using  JSON.parse multiple times
                        className="status-req-order"
                      >
                        {JSON.parse(item.requestHistory) &&
                          Object.values(JSON.parse(item.requestHistory))[
                            Object.values(JSON.parse(item.requestHistory))
                              .length - 1
                          ].status}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="approval-bottom-buttons">
                  <button
                    className="approval-chat-btn"
                    onClick={() => {
                      setChatModal(true);
                      setSelectedOrderToChange({
                        item: item,
                        indexToUpdate: index,
                      });
                    }}
                  >
                    Chat
                  </button>
                  {item.ApprovalStatus === "Approved" ? (
                    <div>
                      <button
                        onClick={async () => {
                          await releaseHandler(item.OrderId, item);
                        }}
                        className="approval-approve-btn"
                      >
                        Release
                      </button>
                    </div>
                  ) : (
                    userContext.user.accountType === "Admin" ||
                    (userContext.user.accountType === "Owner" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <button
                          onClick={() => {
                            setdeclineModal({ modalOpen: true, type: "Hold" });
                            setSelectedOrderToChange({
                              item: item,
                              indexToUpdate: index,
                            });
                          }}
                          className="approval-onhold-btn"
                        >
                          Mark as Hold
                        </button>
                        {/* <button onClick={() => {declineHandler(item.OrderId,item)}} className="approval-decline-btn">Decline</button> */}
                        <button
                          onClick={() => {
                            setdeclineModal({
                              modalOpen: true,
                              type: "Decline",
                            });
                            setSelectedOrderToChange({
                              item: item,
                              indexToUpdate: index,
                            });
                          }}
                          className="approval-decline-btn"
                        >
                          Decline
                        </button>
                        <button
                          onClick={async () => {
                            // setSpinner(true)
                            await approveConfirmation(item.OrderId, item);
                            // await  fetchApprovalOrders();
                            // setSpinner(false);
                            // approveHandler(item.OrderId,item)
                          }}
                          className="approval-approve-btn"
                        >
                          Approve
                        </button>
                      </div>
                    ))
                  )}

                  {/* <button onClick={() => {declineHandler(item.OrderId,item)}} className="approval-decline-btn">Decline</button> */}
                  {/* <button onClick={() => {setdeclineModal(true)}} className="approval-decline-btn">Decline</button>
                                        <button onClick={async() => {
                                          // setSpinner(true)
                                          await approveConfirmation(item.OrderId,item);
                                          // await  fetchApprovalOrders();
                                          // setSpinner(false);
                                          // approveHandler(item.OrderId,item)
                                          }} className="approval-approve-btn">Approve</button> */}
                </div>
              </div>
            </div>
          );
        })
      )}
      {declineModal.modalOpen && (
        <div>
          <Modal
            open={declineModal.modalOpen}
            onClose={() => {
              setdeclineModal((prev) => {
                return {
                  ...prev,
                  modalOpen: false,
                };
              });
            }}
            // maxWidth="sm"

            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

          // fullWidth={true}
          >
            <Box
              className="chat-box-approval"
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                width: "60%",
                maxHeight: "90%",
                maxWidth: "70%",
              }}
            >
              <div>
                <div className="comment-decline-header">
                  <h3>Comment & {declineModal.type}</h3>
                </div>
                <div className="comment-decline-body">
                  <RemarkTextBox
                    rows="8"
                    cols="80"
                    // value={
                    //   JSON.parse(selectedOrderToChange.item.approvalDetails)
                    //     ?.comment
                    // }
                    onUpdate={(e) => {
                      console.log("e", e.target.value);
                      let value = e.currentTarget.value;
                      value = value?.replace(/'/g, "<single-quote>");
                      value = value?.replace(/"/g, "<double-quote>");
                      value = value?.replace(/₹/g, "<rupee-symbol>");
                      value = value.replace(/\n/g, "<new-line>");
                      setComment(value);
                    }}
                    style={{ fontSize: "1.2rem", padding: "0.4em" }}
                  />
                </div>
                <div className="comment-decline-bottom">
                  <button
                    onClick={async () => {
                      setdeclineModal((prev) => {
                        return {
                          ...prev,
                          modalOpen: false,
                        };
                      });
                      setSpinner(true);
                      const objUpdated = await commentHandler(
                        selectedOrderToChange.item.OrderId,
                        selectedOrderToChange.item,
                        commenttoUpdate,
                        declineModal.type
                      );
                      setApprovalOrders((prev) => {
                        if (
                          prev[selectedOrderToChange.indexToUpdate].OrderId ===
                          selectedOrderToChange.item.OrderId
                        ) {
                          prev[
                            selectedOrderToChange.indexToUpdate
                          ].requestHistory = JSON.stringify(objUpdated);
                        }
                        return prev;
                      });

                      setSpinner(false);
                    }}
                    className="vendor-sign-btn sign-accept"
                  >
                    Comment
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}

      {requestHistoryModal && (
        <div>
          {/* <ShareModal
          setShareModal={setShareModal}
          order={shareModalOrder}
          projectId={location.pathname.split("/")[2]}
          ClientName={data.ClientName}
          isApproval={true}
        /> */}

          <RequestHistory
            requestHistoryModal={requestHistoryModal}
            setRequestHistoryModal={setRequestHistoryModal}
            selectedOrder={selectedOrderToChange}
          />
        </div>
      )}
      {chatModal && (
        <div>
          <ChatModal
            chatModal={chatModal}
            setChatModal={setChatModal}
            selectedOrder={selectedOrderToChange}
          />
        </div>
      )}
    </>
  );
};
export { Approvals };
