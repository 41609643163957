/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import "./projectRow.scss";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import getVendorPrice from "../../helpers/getVendorPrice";
import getProjectStatus from "../../helpers/getProjectStatus";
import { UserContext } from "../../Context/UserContext";
import { useHistory } from "react-router";
import { PopOverView } from "..";
import { FiMoreVertical, FiChevronDown, FiChevronUp } from "react-icons/fi";
import OpportunityService from "../../api/opportunityService";
import ProjectService from "../../api/projectService";
import { useLocation } from "react-router-dom";
import firebase from "../../config/firebase";
import environment from "../../config/config";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";

export default ({
  project,
  DuplicateCard,
  setSelectedProject,
  toggleUpdateProjetDetailsModal,
}) => {
  const { record } = useAnalyticsContext();

  const history = useHistory();

  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;

  const [showContent, toggleContent] = useState(false);

  const [upcomingAmount, setupcomingAmount] = useState(0);
  const [ongoingAmount, setongoingAmount] = useState(0);
  const [completedAmount, setcompletedAmount] = useState(0);
  const [pettyCash, setPettyCash] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const opportunityService = new OpportunityService();
  const { pathname } = useLocation();

  const updateFBRDB = (payload) =>
    firebase
      .database()
      .ref("liveBOQ/" + pathname.split("/")[2].toString())
      .set({ ...payload, rooms: JSON.stringify(payload.rooms) });

  const DateCreated = () => {
    let dateObj = new Date(project.ProjectId);
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let newdate = year + "/" + month + "/" + day;
    return newdate;
  };

  const lastOpen = new Date().getTime();

  const openProject = () => {
    const projectService = new ProjectService();
    projectService
      .setProject({ ProjectId: project.ProjectId, lastOpen })
      .then((res) => {
        if (res.status == 200) {
          if (accountType == "Finance") {
            history.push("/Finance/" + project.ProjectId, {
              selectedProject: { ...project, rooms: JSON.parse(project.Rooms) },
            });
          } else {
            history.push("/ProjectPage/" + project.ProjectId);
            record("@project_page_open_success", {
              desccription: "project page open success",
            });
          }
        } else {
          alert(res.statusMsg);
        }
      })
      .catch(() => {
        alert("somthing went wrong");
      });
  };

  useEffect(() => {
    if (project) {
      setupcomingAmount(0);
      setongoingAmount(0);
      setcompletedAmount(0);
      opportunityService
        .getOpportunitiesByProjectId(project.ProjectId)
        .then((res) => {
          setSpinner(true);
          if (res.status === 200) {
            setSpinner(false);
            res.servicesPayload?.forEach((payload) => {
              const data = JSON.parse(payload.Data).data;
              data?.forEach((item) => {
                if (item.rate.length > 0 && item.quantity.length > 0) {
                  const totalAmount =
                    parseInt(item.rate) * parseInt(item.quantity);
                  let tempcompletedAmount = 0;
                  let tempupcomingAmount = 0;
                  let tempongoingAmount = 0;
                  item.milestones?.forEach((milestone) => {
                    if (milestone.paid && milestone.paid !== "Unpaid") {
                      tempcompletedAmount =
                        tempcompletedAmount +
                        (totalAmount / 100) * parseInt(milestone.percentage);
                    } else if (
                      milestone.paid !== "Cash" &&
                      milestone.paid !== "Online" &&
                      !!milestone.status
                    ) {
                      tempongoingAmount =
                        tempongoingAmount +
                        (totalAmount / 100) * parseInt(milestone.percentage);
                    } else if (!milestone.status && !milestone.paid) {
                      tempupcomingAmount =
                        tempupcomingAmount +
                        (totalAmount / 100) * parseInt(milestone.percentage);
                    }
                  });
                  setupcomingAmount((amt) =>
                    Math.round(amt + tempupcomingAmount)
                  );
                  setongoingAmount((amt) =>
                    Math.round(amt + tempongoingAmount)
                  );
                  setcompletedAmount((amt) =>
                    Math.round(amt + tempcompletedAmount)
                  );
                }
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setSpinner(false);
        });
    } else {
      setupcomingAmount(0);
      setongoingAmount(0);
      setcompletedAmount(0);
    }
  }, [project]);

  return (
    <div className="projectRow">
      <div
        className="projectRow"
        key={project.ProjectId}
        onClick={(e) => openProject()}
      >
        <div className="projectHeader">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              marginLeft: 20,
            }}
          >
            <div>
              {showContent ? (
                <FiChevronUp
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleContent(false);
                  }}
                />
              ) : (
                <FiChevronDown
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleContent(true);
                  }}
                />
              )}
            </div>

            <div style={{}}>{project.ClientName}</div>
          </div>

          {/* <div>₹ {getComponentsPrice({ ...project, rooms: JSON.parse(project.Rooms) }).toFixed(2)}/-</div>
                    <div>₹ {getVendorPrice({ ...project, rooms: JSON.parse(project.Rooms) }).toFixed(2)}/-</div> */}

          <div
            style={
              {
                color: "grey",
                border: "1px solid grey",
                padding: "3px 8px",
                borderRadius: "4px",
                maxWidth: "45px",
              }
              // : { color: "red", border: "1px solid red", padding: "3px 8px", borderRadius: "4px", maxWidth: "45px" }
            }
            onClick={(e) => {
              e.stopPropagation();
              environment.environment == "staging"
                ? window.open(
                    "https://admiring-goldstine-70db03.netlify.app/?ProjectId=" +
                      project.ProjectId
                  )
                : window.open(
                    "https://staartlive.netlify.app?ProjectId=" +
                      project.ProjectId
                  );
              record(
                TAGS[
                  ("@live_button_click_success",
                  { description: "Live button click success" })
                ]
              );
            }}
          >
            Live
          </div>

          <div
            style={
              getComponentsPrice({
                ...project,
                rooms: JSON.parse(project.Rooms),
              }) == 0
                ? {
                    textDecoration: "underline",
                    textDecorationColor: "Green",
                    textDecorationStyle: "solid",
                    textDecorationThickness: 3,
                    WebkitTextDecorationColor: "red",
                  }
                : {
                    textDecoration: "underline",
                    textDecorationColor: "red",
                    textDecorationStyle: "solid",
                    textDecorationThickness: 3,
                    WebkitTextDecorationColor: "green",
                  }
            }
          >
            {getComponentsPrice({
              ...project,
              rooms: JSON.parse(project.Rooms),
            }) == 0
              ? "BOQ"
              : getProjectStatus({
                  ...project,
                  rooms: JSON.parse(project.Rooms),
                })}
          </div>

           </div>
        {showContent && (
          <div
            className="projectDetails"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flex: 1,
              }}
            >
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Attendance:----
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Inventory: ----
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Date Created:{DateCreated()}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flex: 1,
              }}
            >
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Upcoming:{upcomingAmount}
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Ongoing:{ongoingAmount}
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Pettycash:{pettyCash}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flex: 1,
              }}
            >
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Po Accepted: ----
              </div>
              <div style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}>
                Po Waiting:----
              </div>
              <div
                style={{ flex: 1, fontSize: "15px", fontWeight: "bold" }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
