import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

export default function AddCustomMilestones({
  customMilestones,
  setCustomMilestones,
  currentMilestoneTobeEditedIndex,
  selectedItems,
  defaultMilestones,
  setSelectedItems,
  milestoneTobeAddedIndex,
  setMilestoneTobeAddedIndex,
}) {
  console.log('This is the customMilestones from the addCustomMilestones file')
  console.log('customMilestones : ', customMilestones)
  console.log('selectedItems before : ', selectedItems);


  let totAmount =
    selectedItems[currentMilestoneTobeEditedIndex]?.quantity *
      selectedItems[currentMilestoneTobeEditedIndex]?.rate +
    selectedItems[currentMilestoneTobeEditedIndex]?.quantity *
      selectedItems[currentMilestoneTobeEditedIndex]?.rate *
      (selectedItems[currentMilestoneTobeEditedIndex]?.gst / 100);

  const [totalAmount, setTotalAmount] = useState(totAmount);

  // ((dataItem?.quantity * dataItem?.rate) + (dataItem?.quantity * dataItem?.rate * (dataItem?.gst / 100)))

  const [milestoneItems, setMilestoneItems] = useState(
    selectedItems[currentMilestoneTobeEditedIndex].milestones
  );

  const addNewMilestones = () => {
    // JSON.parse(JSON.stringify(selectedItems))
    let tempMilestones = [...milestoneItems];
    // let tempMilestones = JSON.parse(JSON.stringify(milestoneItems));

    tempMilestones.push({
      name: "Type your milestone here",
      percentage: 0,
    });

    setMilestoneItems(tempMilestones);
  };

  const assignNewMilestones = () => {
    let status = false;
    let statusQuo = false;
    for (let index = 0; index < Object.keys(milestoneItems).length; index++) {
      if (milestoneItems[index].name == "Type your milestone here") {
        status = true;
      }
    }
    for (let index = 0; index < Object.keys(milestoneItems).length; index++) {
      if (milestoneItems[index].percentage == 0) {
        statusQuo = true;
      }
    }
    console.log("status : ", status);
    if (status) {
      alert("Kindly fill all the descriptions!");
    } else if (statusQuo) {
      alert("Kindly define or delete milestones with 0% payment %");
    } else {
      if (
        selectedItems[currentMilestoneTobeEditedIndex].milestones ==
        milestoneItems
      ) {
        // write the code here
        setCustomMilestones(false);
      } else {
        // write the code here
        let tempSelectedItems = JSON.parse(JSON.stringify(selectedItems));
        // console.log('tempSelectedItems[currentMilestoneTobeEditedIndex] : ', tempSelectedItems[currentMilestoneTobeEditedIndex]);
        tempSelectedItems[currentMilestoneTobeEditedIndex].milestones =
          milestoneItems;
        console.log('tempSelectedItems : ', tempSelectedItems);
        let tempMilestoneTobeAddedIndex = [...milestoneTobeAddedIndex];
        if (
          tempMilestoneTobeAddedIndex.includes(currentMilestoneTobeEditedIndex)
        ) {
          // console.log('Life will be fucking awesome');
        } else {
          tempMilestoneTobeAddedIndex.push(currentMilestoneTobeEditedIndex);
        }
        // console.log('milestoneTobeAddedIndex from addCustomMilston : ', tempMilestoneTobeAddedIndex);
        setMilestoneTobeAddedIndex(tempMilestoneTobeAddedIndex);
        setSelectedItems(tempSelectedItems);
        setCustomMilestones(false);
      }
    }
  };

  return (
    <Modal
      open={customMilestones}
      onClose={() => {
        setCustomMilestones(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "70%",
          maxHeight: "90%",
          maxWidth: "70%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 25 }}
          >
            <p
              style={{
                width: "80%",
                maxwidth: "80%",
                minwidth: "80%",
                textAlign: "center",
                fontWeight: 700,
                fontSize: "22.5px",
                fontFamily: "Montserrat",
              }}
            >
              Set Milestones for the order
            </p>
            <div
              style={{
                display: "flex",
                width: "20%",
                maxwidth: "20%",
                minwidth: "20%",
                flexDirection: "row",
                gap: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(35, 23, 0, 0.5)",
                  fontWeight: 700,
                }}
              >
                Total PO Amount
              </p>
              <p style={{ fontWeight: 700 }}>
                {"\u20B9 "} {totalAmount}
              </p>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Sr. No
            </p>
            <p
              style={{
                width: "50%",
                maxWidth: "50%",
                minWidth: "50%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Milestone /RA Description
            </p>
            <p
              style={{
                width: "20%",
                maxWidth: "20%",
                minWidth: "20%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Payment %
            </p>
            <p
              style={{
                width: "20%",
                maxWidth: "20%",
                minWidth: "20%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Total Amount
            </p>
          </div>

          {milestoneItems.map((item, index) => (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p
                  style={{
                    width: "10%",
                    maxWidth: "10%",
                    minWidth: "10%",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {index + 1}
                </p>

                {item.name == "Type your milestone here" ? (
                  <input
                    style={{
                      width: "50%",
                      maxWidth: "50%",
                      minWidth: "50%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    placeholder="Type your milestone here"
                    // value={item.name}
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      // let tempMilestones = [...milestoneItems];
                      // JSON.parse(JSON.stringify(selectedItems))
                      let tempMilestones = JSON.parse(
                        JSON.stringify(milestoneItems)
                      );
                      tempMilestones[index].name = value;
                      setMilestoneItems(tempMilestones);
                    }}
                  />
                ) : (
                  <input
                    style={{
                      width: "50%",
                      maxWidth: "50%",
                      minWidth: "50%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    // placeholder='Type your milestone here'
                    value={item.name}
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      // let tempMilestones = [...milestoneItems];
                      // JSON.parse(JSON.stringify(selectedItems))
                      let tempMilestones = JSON.parse(
                        JSON.stringify(milestoneItems)
                      );
                      tempMilestones[index].name = value;
                      setMilestoneItems(tempMilestones);
                    }}
                  />
                )}

                <input
                  style={{
                    width: "20%",
                    maxWidth: "20%",
                    minWidth: "20%",
                    textAlign: "center",
                    padding: 5,
                    outline: "none",
                    border: "none",
                  }}
                  value={item.percentage + "%"}
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(
                      /(?!-)[^0-9.]/g,
                      ""
                    );
                    // let tempMilestones = [...milestoneItems];
                    let tempMilestones = JSON.parse(
                      JSON.stringify(milestoneItems)
                    );
                    tempMilestones[index].percentage = value;
                    tempMilestones[index].totalAmount = (
                      (value * totalAmount) /
                      100
                    ).toFixed(2);
                    setMilestoneItems(tempMilestones);
                  }}
                />
                <input
                  style={{
                    width: "20%",
                    maxWidth: "20%",
                    minWidth: "20%",
                    textAlign: "center",
                    borderRight: "1px solid #DFDFDF",
                    padding: 5,
                    fontWeight: 700,
                    outline: "none",
                    border: "none",
                  }}
                  value={
                    "\u20B9 " +
                    ((item.percentage * totalAmount) / 100).toFixed(2)
                  }
                  onChange={(e) => {
                    let value = e.currentTarget.value.replace(
                      /(?!-)[^0-9.]/g,
                      ""
                    );
                    // let tempMilestones = [...milestoneItems];
                    let tempMilestones = JSON.parse(
                      JSON.stringify(milestoneItems)
                    );
                    tempMilestones[index].percentage = (
                      (value / totalAmount) *
                      100
                    ).toFixed(0);
                    tempMilestones[index].totalAmount = value;
                    setMilestoneItems(tempMilestones);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "5%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // write the code here
                    // let tempMilestones = [...milestoneItems];
                    let tempMilestones = JSON.parse(
                      JSON.stringify(milestoneItems)
                    );
                    tempMilestones.splice(index, 1);
                    setMilestoneItems(tempMilestones);
                    console.log(
                      "Amount to subtract : ",
                      totAmount * (milestoneItems[index].percentage / 100)
                    );
                    // setTotalAmount(totalAmount - (totAmount * (milestoneItems[index].percentage / 100)))
                  }}
                >
                  <AiOutlineDelete />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#DFDFDF",
                }}
              ></div>
            </>
          ))}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
            }}
          >
            <p
              style={{
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
              }}
            >
              Total
            </p>
            <p
              style={{
                width: "50%",
                maxWidth: "50%",
                minWidth: "50%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
              }}
            ></p>
            {milestoneItems
              .reduce(
                (total, item) => (total = total + Number(item.percentage)),
                0
              )
              .toFixed(0) < 100 ? (
              <p
                style={{
                  width: "20%",
                  maxWidth: "20%",
                  minWidth: "20%",
                  textAlign: "center",
                  borderBottom: "1px solid #DFDFDF",
                  padding: 5,
                  fontWeight: 500,
                }}
              >
                {milestoneItems
                  .reduce(
                    (total, item) => (total = total + Number(item.percentage)),
                    0
                  )
                  .toFixed(0)}{" "}
                %
              </p>
            ) : milestoneItems
                .reduce(
                  (total, item) => (total = total + Number(item.percentage)),
                  0
                )
                .toFixed(0) == 100 ? (
              <p
                style={{
                  width: "20%",
                  maxWidth: "20%",
                  minWidth: "20%",
                  textAlign: "center",
                  borderBottom: "1px solid #DFDFDF",
                  padding: 5,
                  fontWeight: 500,
                  color: "green",
                }}
              >
                {milestoneItems
                  .reduce(
                    (total, item) => (total = total + Number(item.percentage)),
                    0
                  )
                  .toFixed(0)}{" "}
                %
              </p>
            ) : (
              <p
                style={{
                  width: "20%",
                  maxWidth: "20%",
                  minWidth: "20%",
                  textAlign: "center",
                  borderBottom: "1px solid #DFDFDF",
                  padding: 5,
                  fontWeight: 500,
                  color: "red",
                }}
              >
                {milestoneItems
                  .reduce(
                    (total, item) => (total = total + Number(item.percentage)),
                    0
                  )
                  .toFixed(0)}{" "}
                %
              </p>
            )}
            <p
              style={{
                width: "20%",
                maxWidth: "20%",
                minWidth: "20%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
                fontWeight: 500,
              }}
            >
              {"\u20B9 "}{" "}
              {milestoneItems
                .reduce(
                  (total, item) =>
                    (total =
                      total + Number((item.percentage / 100) * totalAmount)),
                  0
                )
                .toFixed(2)}{" "}
              /-
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={() => {
              addNewMilestones();
            }}
          >
            <p
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                lineHeight: "20px",
                fontSize: "20px",
                textAlign: "center",
                border: "1px solid #4685D4",
                color: "#4685D4",
              }}
            >
              +
            </p>
            <p style={{ color: "#4685D4" }}>Add New Milestone</p>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "0.5%" }}>
            <p style={{ width: "75%", maxWidth: "75%", minWidth: "75%" }}></p>
            <div
              style={{
                width: "12%",
                maxWidth: "12%",
                minWidth: "12%",
                textAlign: "center",
                border: "1px solid #FDD34D",
                borderRadius: "5px",
                padding: 5,
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => {
                // setTheValueOfDefaultMilestones(milestoneItems)
                setCustomMilestones(false);
              }}
            >
              Discard
            </div>
            {milestoneItems.reduce(
              (total, item) => (total = total + Number(item.percentage)),
              0
            ) == 100 ? (
              <div
                style={{
                  width: "12%",
                  maxWidth: "12%",
                  minWidth: "12%",
                  textAlign: "center",
                  backgroundColor: "#FDD34D",
                  borderRadius: "5px",
                  padding: 5,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => assignNewMilestones()}
              >
                Confirm
              </div>
            ) : (
              <div
                style={{
                  width: "12%",
                  maxWidth: "12%",
                  minWidth: "12%",
                  textAlign: "center",
                  backgroundColor: "#fdf3d3",
                  borderRadius: "5px",
                  padding: 5,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  alert("All milestone percentage is not equal to 100%")
                }
              >
                Confirm
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
}
