import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { SearchBar } from "../../components";

export default function AllVendors({
  getBankAccountDetails,
  showAllVendors,
  setShowAllVendors,
  allVendorData,
  setVendorMobile,
}) {
  const [vendorData, setVendorData] = useState(allVendorData);

  const [tempVendorData, setTempVendorData] = useState(allVendorData);

  const [search, setSearch] = useState("");

  const filterVendorsByText = (text) => {
    setSearch(text);
  };

  return (
    <Modal
      open={showAllVendors}
      onClose={() => setShowAllVendors(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "30%",
          maxHeight: "50%",
          maxWidth: "30%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // textAlign: 'center'
            // flexWrap: 'wrap',
            // minHeight: '300px', maxHeight: '300px',
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
              gap: 10,
            }}
          >
            <p style={{ fontWeight: 700 }}>Search Vendors</p>
            <SearchBar
              onChange={(text) => {
                filterVendorsByText(text);
              }}
            />
          </div>

          {vendorData.map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                marginBottom: 20,
              }}
            >
              {vendorData[index][Object.keys(item)[0]]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase()) ? (
                <p style={{ fontWeight: 700 }}>{Object.keys(item)[0]}</p>
              ) : null}
              {item[Object.keys(item)[0]].map((vItem, vIndex) =>
                vItem
                  .toString()
                  .toLowerCase()
                  .includes(search.toLowerCase()) ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 30,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      getBankAccountDetails(vItem.toString().split(' : ')[1]);
                      setVendorMobile(vItem.toString());
                      setShowAllVendors(false);
                    }}
                  >
                    <p style={{ maxWidth: 200, minWidth: 200 }}>
                      {vItem.toString().slice(0, vItem.toString().indexOf(":"))}
                    </p>
                    <p style={{ maxWidth: 200, minWidth: 200 }}>
                      {vItem
                        .toString()
                        .slice(vItem.toString().indexOf(":") + 1)}
                    </p>
                  </div>
                ) : null
              )}
            </div>
          ))}
        </div>
      </Box>
    </Modal>
  );
}
