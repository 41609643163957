import config from "../config/config";
import firebase from "../config/firebase";

class NotificationService {
  async sendNotificationsOnCategory(category, categoryType) {
    // console.log(`%c NotificationService running with category`, 'background: black; color: green', category, 'of category type', categoryType)
    try {
      if (categoryType == "materialCategory") {
        var response = await fetch(
          `${config.authService}getVendorCategoryByMobile/("${category}")`
        );
      } else {
        var response = await fetch(
          `${config.authService}getVendorCategoryByMobile/${category}`
        );
      }
      response = await response.json();
      console.log(`Response from getVendorCategoryByMobile API: `, response);
      if (response.status === 200) {
        const mobiles = response.payload.map((x) => x.Mobile.toString());
        if (mobiles.length > 0) {
          fetch("https://onesignal.com/api/v1/notifications", {
            method: "POST",
            headers: {
              Authorization:
                "Basic ZjYyMTE0NDctNTI3OS00ZDVhLWI1NDEtNTFiYjYxYWZiMTAy",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              app_id: "517250f1-585e-4428-9ed1-e25c654c78d4",
              include_external_user_ids: mobiles,
              channel_for_external_user_ids: "push",
              data: { foo: "bar" },
              contents: { en: "New opportunity - " + category },
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              console.log("Response from NotificationService API: ", res);
            })
            .catch((e) => {
              console.log("Catch from NotificationService API: ", e);
            });
        }
      }
    } catch (e) {
      console.log("Catch from sendNotificationsOnCategory(): ", e);
    }
  }

  async financeNotifications(Firm, data, accountType) {
    console.log("setting the firebase");
    firebase
      .database()
      .ref(
        "BuildNotifications/" +
          `${Firm}` +
          "/" +
          `${accountType}` +
          "/" +
          "notifications/" +
          new Date().getTime()
      )
      .set(data);
  }

  async notifySEOnOrderRelease(project, siteEnggDetails, category, orderType) {
    const { clientName, ClientName, ProjectId, Address } = project;
    // console.log("CLEINT NAME", category, orderType);
    try {
      const response = await fetch(
        `${config.notificationBilling}notifySEOnOrderRelease`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ClientName: ClientName || clientName,
            ProjectId,
            Address,
            siteEnggDetails,
            category,
            orderType,
          }),
        }
      );

      // response = await response.json();
      console.log(`Response from notiy Order Release API: `, response);
      if (response.status === 200) {
      }
    } catch (e) {
      console.log("Catch from notify order release : ", e);
    }
  }
}

export default NotificationService;
