import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import {
  calculateBalanceAmount,
  calculateDateOfPayment,
  calculateOrderTotalPaidAmount,
} from "../Finance/VendorStatement";

const VendorPDF = ({
  projectwiseOrders,
  selectedVendorDetails,
  totalVendorDetails,
  image,
}) => {
  const calculateDate = () => {
    let timestamp = new Date();
    let time = timestamp.toLocaleTimeString();
    let date = timestamp.toLocaleDateString();
    return `${date}   -   ${time} `;
  };
  const styles = StyleSheet.create({
    page: {
      fontSize: 12,
      width: "95%",
      display: "flex",
      margin: "auto",
      alignItems: "center",
      flexDirection: "column",
    },
    title: {
      backgroundColor: "#26428b",
      padding: 2,
      fontWeight: "bold",
      width: "100%",
    },
    header: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      borderStyle: "solid",
      borderBottomWidth: 3,
      borderBottomColor: "#26428b",
      marginLeft: 2,
      marginRight: 2,
      marginBottom: 3,
    },
    vendorPaymentSummary: {
      display: "flex",
      flexDirection: "column",
      width: "35%",
    },
    vendorPaymentSummaryHeader: {
      borderStyle: "solid",
      borderBottomWidth: 3,
      borderBottomColor: "#26428b",
    },
    projectContainer: {
      width: "90%",
      backgroundColor: "rgb(240, 240, 241)",
      padding: 2,
      margin: 2,
      marginTop: 10,
    },
    projectSummanyDetailPair: {
      display: "flex",
      // lineHeight: 1.5,
    },
    projectSummanyDetailPairTitle: {
      position: "relative",
      width: "50%",
      paddingRight: 4,
    },
    projectSummanyDetailPairDesc: {
      width: "50%",
      position: "absolute",
      right: "10pt",
    },
    orderContainer: {
      marginTop: 7,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#6770bc",
      fontSize: 10,
    },
    orderHeaderContainer: {
      margin: 4,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    orderHeaderLeftContainer: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
    },
    paymentDetailsTable: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderColor: "#ddd",
    },
    paymentDetailsRow: {
      margin: "auto",
      flexDirection: "row",
    },
    paymentDetailsCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderColor: "#ddd",
    },
    paymentDetailsCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
    paymentDetailsText: {
      zIndex: "100",
      width: "100%",
      fontWeight: "bold",
      backgroundColor: "#ffd34d",
      textAlign: "center",
    },
  });

  function ordersMap(vendorOrders) {
    return vendorOrders.map((sItem, sIndex) => {
      return (
        <View key={sIndex} style={styles.orderContainer}>
          {/* {sItem.Category} */}
          <View style={styles.orderHeaderContainer}>
            <View style={styles.orderHeaderLeftContainer}>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Category
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {": "}
                  {sItem.Category}
                </Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  {JSON.parse(sItem.Data)?.data[0]?.workType == "Only Work"
                    ? "Work Order "
                    : "Purchase Order "}
                  Id
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {": "}
                  {sItem.OrderId || sItem.Id}
                </Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Order Value
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {": "}
                  {sItem?.OlderVersions && sItem.OlderVersions !== "null"
                    ? JSON.parse(sItem.OlderVersions)[0].OrderTotalAmount
                    : sItem.OrderTotalAmount}
                </Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Ammended Value
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {": "}
                  {sItem.OrderTotalAmount}
                </Text>
              </View>
            </View>

            <View style={[styles.orderHeaderLeftContainer, { width: "40%" }]}>
              {/* <Text>
                    <Text>Payment Summary</Text>
                  </Text> */}
              <Text
                style={{
                  textAlign: "center",
                  borderBottom: 1,
                  borderStyle: "solid",
                  borderColor: "#6770bc",
                }}
              >
                Payment Summary
              </Text>
              {/* <hr /> */}
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Actual Bill
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>{": "}-</Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Paid Amount
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {": "}
                  {calculateOrderTotalPaidAmount(
                    JSON.parse(sItem.CommonMilestones)
                  )}
                </Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Balance Amount
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {": "}
                  {calculateBalanceAmount(
                    JSON.parse(sItem.CommonMilestones),
                    sItem.OrderTotalAmount
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={styles.paymentDetailsText}
              // style={{ backgroundColor: "#ffd34d" }}
            >
              <Text>Payment Details</Text>
            </View>

            <View style={styles.paymentDetailsTable}>
              <View style={styles.paymentDetailsRow}>
                <View style={[styles.paymentDetailsCol, { width: "15%" }]}>
                  <Text style={styles.paymentDetailsCell}>Date</Text>
                </View>
                <View style={[styles.paymentDetailsCol, { width: "20%" }]}>
                  <Text style={styles.paymentDetailsCell}>Amount</Text>
                </View>
                <View style={[styles.paymentDetailsCol, { width: "55%" }]}>
                  <Text style={styles.paymentDetailsCell}>Remarks</Text>
                </View>
                <View style={[styles.paymentDetailsCol, { width: "10%" }]}>
                  <Text style={styles.paymentDetailsCell}>Mode</Text>
                </View>
              </View>
              <View>
                {sItem.CommonMilestones &&
                JSON.parse(sItem.CommonMilestones)[0].name === "Paid" ? (
                  <View style={styles.paymentDetailsRow}>
                    <View style={[styles.paymentDetailsCol, { width: "15%" }]}>
                      <Text style={styles.paymentDetailsCell}>-</Text>
                    </View>
                    <View style={[styles.paymentDetailsCol, { width: "20%" }]}>
                      <Text style={styles.paymentDetailsCell}>
                        {JSON.parse(sItem.CommonMilestones)[0].totalAmount}
                      </Text>
                    </View>
                    <View style={[styles.paymentDetailsCol, { width: "55%" }]}>
                      <Text style={styles.paymentDetailsCell}>Paid</Text>
                    </View>
                    <View style={[styles.paymentDetailsCol, { width: "10%" }]}>
                      <Text style={styles.paymentDetailsCell}>-</Text>
                    </View>
                  </View>
                ) : (
                  <View></View>
                )}
              </View>
              <View>
                {sItem.CommonMilestones &&
                  JSON.parse(sItem.CommonMilestones).map((cmItem, cmIndex) => {
                    return cmItem.payments
                      ? "payments" in cmItem &&
                          cmItem.payments.map((pItem, pIndex) => {
                            return (
                              <View
                                style={styles.paymentDetailsRow}
                                key={`${sItem.OrderId} + ${cmIndex} + ${pIndex}`}
                              >
                                <View
                                  style={[
                                    styles.paymentDetailsCol,
                                    { width: "15%" },
                                  ]}
                                >
                                  <Text style={styles.paymentDetailsCell}>
                                    {calculateDateOfPayment(pItem.date)}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.paymentDetailsCol,
                                    { width: "20%" },
                                  ]}
                                >
                                  <Text style={styles.paymentDetailsCell}>
                                    {pItem.amount}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.paymentDetailsCol,
                                    { width: "55%" },
                                  ]}
                                >
                                  {pItem.remarks ? (
                                    <Text style={styles.paymentDetailsCell}>
                                      {pItem.remarks
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<rupee-symbol>/g, "₹")
                                        .replace(/<double-quote>/g, '"')}
                                    </Text>
                                  ) : (
                                    <Text style={styles.paymentDetailsCell}>
                                      -
                                    </Text>
                                  )}
                                </View>
                                <View
                                  style={[
                                    styles.paymentDetailsCol,
                                    { width: "10%" },
                                  ]}
                                >
                                  <Text style={styles.paymentDetailsCell}>
                                    {pItem.mode}
                                  </Text>
                                </View>
                              </View>
                            );
                          })
                      : null;
                  })}
              </View>
            </View>
          </View>
        </View>
      );
    });
  }

  return (
    <Document>
      <Page
        size="A4"
        orientation="portrait"
        style={{ width: "80vh", display: "flex", justifyContent: "center" }}
      >
        <View style={styles.page}>
          <View style={styles.title}>
            <Text style={{ textAlign: "center", color: "white" }}>
              Cash flow Statement
            </Text>
          </View>
          {/* <Image  src={image}></Image> */}
          <View style={styles.header}>
            <View style={{ fontWeight: "bold" }}>
              <Text
                style={{ fontSize: 18 }}
              >{`${selectedVendorDetails.Name.toUpperCase()} (${
                selectedVendorDetails.Mobile
              })`}</Text>
              <Text>{selectedVendorDetails.Company}</Text>
            </View>

            <View style={styles.vendorPaymentSummary}>
              <View>
                <Text
                  style={[
                    styles.vendorPaymentSummaryHeader,
                    { textAlign: "center" },
                  ]}
                >
                  Payment Summary
                </Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Total Amount
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>: 0</Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text>Total Paid</Text>

                <Text style={styles.projectSummanyDetailPairDesc}>
                  {`: ${totalVendorDetails.Projectstotalpaid}`}
                </Text>
              </View>
              <View style={styles.projectSummanyDetailPair}>
                <Text style={styles.projectSummanyDetailPairTitle}>
                  Balance
                </Text>
                <Text style={styles.projectSummanyDetailPairDesc}>
                  {`: ${totalVendorDetails.ProjectstotalBalance}`}
                </Text>
              </View>
            </View>
          </View>
          {projectwiseOrders ? (
            Object.keys(projectwiseOrders).map((projectItem, pIndex) => {
              return (
                <View style={styles.projectContainer} key={pIndex}>
                  <Text style={{ fontWeight: "bold" }}>
                    <Text>{pIndex + 1}.</Text>
                    {projectItem.toUpperCase()}:
                  </Text>
                  <View>{ordersMap(projectwiseOrders[projectItem])}</View>
                </View>
              );
            })
          ) : (
            <Text>No Orders Assigned</Text>
          )}
        </View>
        <View fixed>
          <Text
            style={{ textAlign: "center", fontSize: 8, marginTop: 15 }}
          >{`${localStorage.getItem("firm")}-${calculateDate()}`}</Text>
        </View>
      </Page>
    </Document>
  );
};
export default VendorPDF;
