import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import { display } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FiChevronDown, FiChevronUp, FiSave } from "react-icons/fi";
import DateFnsUtils from "@date-io/date-fns";
import { confirmAlert } from "react-confirm-alert";
import OpportunityService from "../../api/opportunityService";
import toast from "react-hot-toast";
import { DropDown } from "../../components";
import RemarkTextBox from "./RemarksTextBox";

export default function TransactionDetails({
  transactionDetailsModal,
  setTransactionDetailsModal,
  transactionDetailsData,
  selectedOrderMilestoneData,
  setSelectedOrderMilestoneData,
  startDate,
  endDate,
  orderId,
  setReleasedOrders,
  releasedOrderIndex
}) {

  const didMountRef = useRef(false);

  // const [showStatus, setShowStatus] = useState([]);
  const [editableitem ,setEditableItem] = useState();
  console.log("transactionDetailsData : ", transactionDetailsData);
  console.log("selectedOrderMilestoneData : ", selectedOrderMilestoneData);
  console.log("selectedOrderMilestoneData : ", releasedOrderIndex);
  // console.log('transactionDetailsData : ', transactionDetailsData, 'startdate' ,startDate,'end',endDate);

const updatedetails = async(IdtoUpdate,CommonMilestonestoUpdate) =>{
  console.log('updating the milestones ',JSON.stringify(CommonMilestonestoUpdate));
    const opportunityService = new OpportunityService();
    setReleasedOrders((prev)=>{
      console.log('released Orders',prev)
      if(prev[releasedOrderIndex].OrderId === orderId){
        prev[releasedOrderIndex].CommonMilestones =  JSON.stringify(selectedOrderMilestoneData) // directly changing the value of commonMilestones        
      }
      return prev
    })
   const response =  await opportunityService.updateOpportunityById(IdtoUpdate, {"CommonMilestones" : JSON.stringify(CommonMilestonestoUpdate)});
   if(response.status === 200){
    // console.log('response',response)
    toast.success("Updated Successfully")
   }
}

useEffect(()=>{
  if (didMountRef.current) { 
    console.log('selectedOrderMilestoneData:',selectedOrderMilestoneData)
        
  } else {
    didMountRef.current = true;
  }

},[selectedOrderMilestoneData])

  return (
    <Modal
    sx={{zIndex:99}}
      open={transactionDetailsModal}
      onClose={() => { 
        // console.log('aedittable item',editableitem)
        if(editableitem != null || editableitem != undefined){
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 5px ",
                    zIndex: "9999"
                  }}
                >
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      padding: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    Do you want to close changes will be lost?
                  </h4>
                  <div>
                    <button
                      style={{
                        padding: "5px 20px",
                        backgroundColor: " #2f4858",
                        color: "whitesmoke",
                        fontSize: "16px",
                        marginRight: "5px",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        onClose();
                        setTransactionDetailsModal(false)
                      }}
                    >
                      Yes
                    </button>
                    <button
                      onClick={onClose}
                      style={{
                        padding: "5px 20px",
                        backgroundColor: " #2f4858",
                        color: "whitesmoke",
                        fontSize: "16px",
                        marginLeft: "5px",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              );
            },overlayClassName:"transactionDetailsModal"
          });
        }
        else{
          setTransactionDetailsModal(false)
        }
      }} 
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="transactionDetailsModal"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxHeight: "90%",
          maxWidth: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          <p style={{ marginBottom: 25 }}>
            {transactionDetailsData && transactionDetailsData.VendorName} (
            {transactionDetailsData && transactionDetailsData.VendorMobile}) |{" "}
            {transactionDetailsData && transactionDetailsData.Category}
          </p>
          
          {(
                  <>
                    <div
                      style={{
                        display: "flex",
                        height: 2,
                        width: "100%",
                        backgroundColor: "#EAEAEA",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    ></div>
                    <div
                      style={{ display: "flex", flexDirection: "row", gap: 70 }}
                    >
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#C4C4C4",
                          width: "20%",
                          maxWidth: "20%",
                          minWidth: "20%",
                        }}
                      >
                        Payment Id
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#C4C4C4",
                          Width: "15%",
                          maxWidth: "13%",
                          minWidth: "13%",
                        }}
                      >
                        Date
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#C4C4C4",
                          Width: "15%",
                          maxWidth: "15%",
                          minWidth: "15%",
                        }}
                      >
                        Amount
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#C4C4C4",
                          Width: "15%",
                          maxWidth: "15%",
                          minWidth: "15%",
                        }}
                      >
                        Status
                      </p>
                      <p
                        style={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#C4C4C4",
                          Width: "15%",
                          maxWidth: "8%",
                          minWidth: "5%",
                        }}
                      >
                        Mode
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        height: 2,
                        width: "100%",
                        backgroundColor: "#EAEAEA",
                        marginTop: 10,
                        marginBottom: 25,
                      }}
                    ></div>
                    {selectedOrderMilestoneData.map((mItem, mIndex) => (
                      <div key={mIndex}
                      style={{background:`${mIndex != editableitem ?  "none" : "#FFF6DB"}`,
                      borderRadius: "10px",
                      padding: "0px 10px"}}>
                        <div
                          style={{
                            display:"flex",
                            justifyContent:"space-between",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#C4C4C4",
                            marginBottom: 10,
                            marginTop: 10,
                          }}
                        >
                          {mItem.name}
                          { "payments" in mItem && (mIndex != editableitem ? 
                            <AiOutlineEdit
                                  size={23}
                                  style={{ color: "#454545" ,cursor:"pointer"}}
                                  onClick={() => {
                                    // setVendorEditHandler(true);
                                    // setSelectedVendorDetails(val);
                                    setEditableItem(mIndex)
                                  }}/> :
                                   <FiSave
                                  color="#94919F"
                                  size={20}
                                  style={{width:"2%" ,cursor:"pointer"}}
                                  
                                 onClick={()=>{
                                  if(Number(mItem.totalAmount) - Number(mItem?.payments.reduce((total, item) => (total = Number(total) + Number(item.amount)),0)) < 0 ){
                                    alert("Entered Amount is Greater than Total Amount!");
                                  }
                                  else{
                                    setEditableItem()
                                    updatedetails(orderId,selectedOrderMilestoneData)
                                  }
                                    //  setReceived(amountHistory.reduce((totalAmount,item)=> totalAmount += +item.amount ,0))
                                    //  updateAccountDetails()
                                   }}
                                    />)}
                        </div>
                        {mItem.payments ? (
                          "payments" in mItem &&
                          mItem.payments.map(
                            (pItem, pIndex) => {
                              // setRemarks(pItem.remarks);
                             return (
                              // console.log(
                              //   "pItem : ",
                              //   new Date(pItem.date).toString().substring(4, 15)
                              // ),
                              
                              (<div 
                                key={ pIndex}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 70,
                                    marginBottom: "0.2em",
                                    alignItems:"center"
                                  }}
                                  key={ pIndex}
                                >
                                  <p
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "#353535",
                                      width: "20%",
                                      maxWidth: "20%",
                                      minWidth: "20%",
                                    }}
                                  >
                                    {pItem.paymentID}
                                  </p>
                                  <div
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "#353535",
                                      Width: "15%",
                                      maxWidth: "13%",
                                      minWidth: "13%",
                                    }}
                                  >
                                    {/* {new Date(pItem.date)
                                      .toString()
                                      .substring(4, 15)} */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disabled = {pItem.mode === "online" || mIndex != editableitem ? true : false}
                                  margin="normal"
                                  style={{margin:"-8px"}}
                                  id="date-picker-dialog"
                                  format="dd/MM/yyyy"
                                  value={pItem.date}
                                  onChange={(date) =>{
                                      // console.log('datee',date,'timestamp : ', new Date(date).getTime())
                                      let tempobj = [...selectedOrderMilestoneData]
                                      // console.log('before Update',tempobj)
                                      tempobj[mIndex].payments[pIndex] = {...pItem , date: new Date(date).getTime() }
                                      // tempobj[index] = { date: Date.now() ,amount : Number(e.currentTarget.value.replace(/(?!-)[^0-9.]/g, ""))}
                                      // console.log('after the update ',tempobj)
                                      setSelectedOrderMilestoneData(tempobj)
                                }}
                                  KeyboardButtonProps={{ "aria-label": "change date" }}
                                  minDate={new Date(startDate)}
                                />
                              </MuiPickersUtilsProvider>


                                  </div>
                                  <p
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "#353535",
                                      Width: "15%",
                                      maxWidth: "15%",
                                      minWidth: "15%",
                                    }}
                                  >
                                    <input
                                    autoFocus = {mIndex != editableitem ? false : true}
                                    value={pItem.amount}
                                    disabled = {pItem.mode === "online" || mIndex != editableitem ? true : false}
                                    style={{
                                      width: "100%",
                                      fontSize: "16px",
                                      borderWidth: 0,
                                      border : `${mIndex != editableitem ?  "none" : "1px solid lightgray"}`,
                                      outline: "none",
                                      background:`${mIndex != editableitem ?  "none" : "#FFF6DB"}`,
                                    }}
                                    type="number"
                                    onChange={(e) => {
                                      // setSelectedOrderMilestoneData()
                                      let tempobj = [...selectedOrderMilestoneData]
                                      // console.log('before Update',tempobj)
                                      tempobj[mIndex].payments[pIndex] = {...pItem , amount : Number(e.currentTarget.value)  }
                                      // tempobj[index] = { date: Date.now() ,amount : Number(e.currentTarget.value.replace(/(?!-)[^0-9.]/g, ""))}
                                      // console.log('after the update ',tempobj)
                                      // console.log('mitems',mItem,mItem.payments, Number(mItem.amount))
                                      // console.log('maxamount can be entered',Number(mItem.totalAmount) - Number(mItem?.payments.reduce((total, item) => (total = Number(total) + Number(item.amount)),0)))
                                      setSelectedOrderMilestoneData(tempobj)
                                    }}
                                    // max= {mItem.amount - mItem?.payments.reduce((total, item) => (total = total + Number(item.amount)),0)}
                                    />
                                    {/* {pItem.amount} */}
                                  </p>
                                  <p
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      color: "#43A047",
                                      Width: "15%",
                                      maxWidth: "15%",
                                      minWidth: "15%",
                                    }}
                                  >
                                    {pItem.status}
                                  </p>
                                  <div
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "#353535",
                                      Width: "15%",
                                      maxWidth: "8%",
                                      minWidth: "5%",
                                    }}
                                  >
                                    {pItem.mode != "online" ? <DropDown
                                    
                                        disabled = {pItem.mode === "online" || mIndex != editableitem ? true : false}
                                        data={["Bank","Cash", "Cheque","Other"]}
                                        value={pItem.mode}
                                        onChange={(value) => {
                                          let tempobj = [...selectedOrderMilestoneData]
                                          tempobj[mIndex].payments[pIndex] = {...pItem , mode : value  }
                                          setSelectedOrderMilestoneData(tempobj)
                                        }}
                                      ></DropDown> : "Online"}
                                    {/* {pItem.mode} */}
                                  </div>
                                  {/* <p><AiOutlineEdit
                                  size={23}
                                  style={{ color: "#454545" ,cursor:"pointer"}}
                                  onClick={() => {
                                    // setVendorEditHandler(true);
                                    // setSelectedVendorDetails(val);
                                    console.log('edit')
                                  }}
                      /></p> */}
                                </div>
                                {/* <textarea
                                // value={pItem.remarks}
                                value={changeText(pItem.remarks)}
                                
                                disabled = {pItem.mode === "online" || mIndex != editableitem ? true : false} 
                                rows="3" cols="140" 
                                // onChange={(e)=>{
                                //   setRemarks(e.currentTarget.value)
                                // }}
                                onChange={(e)=>{
                                  let tempobj = [...selectedOrderMilestoneData]
                                  let value = e.currentTarget.value
                                  value = value.replace(/'/g, "<single-quote>");
                                  value = value.replace(/"/g, "<double-quote>");
                                  value = value.replace(/₹/g, "<rupee-symbol>");
    
                                  tempobj[mIndex].payments[pIndex] = {...pItem , remarks : value  }
                            
                                  setSelectedOrderMilestoneData(tempobj)
                                }}
                                >
                                </textarea> */}
                                <RemarkTextBox
                                disabled = {pItem.mode === "online" || mIndex != editableitem ? true : false} 
                                rows="3" cols="140" 
                                value={pItem.remarks}
                                onUpdate={(e)=>{
                                  let tempobj = [...selectedOrderMilestoneData]
                                  let value = e?.currentTarget?.value
                                  value = value?.replace(/'/g, "<single-quote>");
                                  value = value?.replace(/"/g, "<double-quote>");
                                  value = value?.replace(/₹/g, "<rupee-symbol>");
                                  value = value.replace(/\n/g,"<new-line>");
    
                                  tempobj[mIndex].payments[pIndex] = {...pItem , remarks : value  }
                            
                                  setSelectedOrderMilestoneData(tempobj)
                                }}/>
                                </div>
                              )
                            )
                              })
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 70,
                              marginBottom: 10,
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                width: "20%",
                                maxWidth: "20%",
                                minWidth: "20%",
                              }}
                            >
                              -
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              -
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              -
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              -
                            </p>
                            <p
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                color: "#353535",
                                Width: "15%",
                                maxWidth: "15%",
                                minWidth: "15%",
                              }}
                            >
                              -
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
          {JSON.parse(transactionDetailsData.Data).data.map(
            (dataItem, dataIndex) => (
              <div key={dataIndex}>
                <div
                  style={{
                    display: "flex",
                    height: 2,
                    width: "100%",
                    backgroundColor: "#F6F6F6",
                    marginTop: 20,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    marginBottom: 25,
                    marginTop: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      marginBottom: 25,
                      marginTop: 5,
                      width: "98%",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      {dataItem.specification
                        ?.replace(/<single-quote>/g, "'")
                        .replace(/<double-quote>/g, '"')
                        .split("<new-line>")
                        .map((text, index) => (
                          <div key={index}>
                            {text}
                            <br />
                          </div>
                        )) ||
                        dataItem.description
                          ?.replace(/<single-quote>/g, "'")
                          .replace(/<double-quote>/g, '"')
                          .split("<new-line>")
                          .map((text, index) => (
                            <div key={index}>
                              {text}
                              <br />
                            </div>
                          ))}
                    </p>
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#CDCDCD",
                      }}
                    >
                      {dataItem["Unit Name"]}
                    </p>
                  </div>

                  {/* <div style={{ display: "flex", width: "2%" }}>
                    {showStatus && showStatus.includes(`${dataIndex}`) ? (
                      <FiChevronUp
                        onClick={() => {
                          let popStatus = [...showStatus];
                          popStatus.pop(`${dataIndex}`);
                          setShowStatus(popStatus);
                        }}
                      />
                    ) : (
                      <FiChevronDown
                        onClick={() => {
                          let pushStatus = [...showStatus];
                          pushStatus.push(`${dataIndex}`);
                          setShowStatus(pushStatus);
                        }}
                      />
                    )}
                  </div> */}
                </div>
                
              </div>
            )
          )}
        </div>
      </Box>
    </Modal>
  );
}
