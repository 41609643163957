import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { FiTruck } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import { BsCheckAll } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";
import { MdOutlinePauseCircleOutline } from "react-icons/md";

import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { FaRegPaperPlane } from "react-icons/fa";
export default ({
  placeholder,
  onChange,
  value,
  data,
  disabled,
  disabeSorting,
  size,
  custStyles,
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      disableClearable
      disablePortal
      id="combo-box-demo"
      options={disabeSorting ? data : data?.sort((a, b) => a.localeCompare(b))}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <div
            style={{
              marginRight: option === "Work + Material" ? "10px" : "25px",
            }}
          >
            {option === "All" && <BsCheckAll />}
            {option === "Only Work" && <GrUserWorker />}
            {option === "Work + Material" && (
              <div>
                <GrUserWorker />
                <FiTruck />
              </div>
            )}
            {option === "Only Material" && <FiTruck />}
            {option === "Order placed" && <BsThreeDots />}
            {option === "Order accepted" && <AiOutlineCheck />}
            {option === "Order completed" && <BsCheckCircle />}
            {option === "Quotation released" && <BiRupee />}
            {option === "On hold" && <MdOutlinePauseCircleOutline size={19} />}
            {option === "Approved" && <FaCheckCircle color="green" size={19} />}
            {option === "Declined" && <IoMdCloseCircle color="red" size={19} />}
            {option === "Requested" && (
              <FaRegPaperPlane color="steelblue" size={19} />
            )}
          </div>
          <span
            style={{
              color: `${
                option === "Requested"
                  ? "steelblue"
                  : option === "Approved"
                  ? "green"
                  : option === "Declined"
                  ? "red"
                  : "black"
              }`,
            }}
          >
            {option}
          </span>
        </Box>
      )}
      sx={{ width: 240 }}
      onChange={(event, newValue) => {
        let value = newValue == null ? "" : newValue;
        onChange(value);
      }}
      value={value}
      size={size}
      style={{ padding: "2px", ...custStyles }}
      renderInput={(params) => (
        <TextField {...params} label={placeholder} style={{ padding: "2px" }} />
      )}
    />
  );
};
