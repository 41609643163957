import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import config from "../../config/config";
import { toast } from "react-hot-toast";

export default function PaymentTerms({
  defaultMilestones,
  totalAmount,
  showPaymentTerms,
  setShowPaymentTerms,
  selectedItems,
  setTheValueOfDefaultMilestones,
  totalPaidAmountReleasedOrder,
  setTotalPaidAmountReleasedOrder,
  VendorDetailsSelected,
}) {
  const [tempTotalPaid, setTempTotalPaid] = useState(
    totalPaidAmountReleasedOrder
  );
  const [milestoneItems, setMilestoneItems] = useState(
    JSON.parse(JSON.stringify(defaultMilestones))
  );
  const [copyMilestoneItems, setCopyMilestoneItems] = useState(
    JSON.parse(JSON.stringify(defaultMilestones))
  );

  const [isTouchedForUpdatingToVendor, setIsTouchedForUpdatingToVendor] =
    useState({ Touched: false, disabled: true });

  const setAsVendorDefaultHandler = async () => {
    const Token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6MiwiTmFtZSI6IlNoaXZhIiwiTW9iaWxlIjo4MDE5NzMxNzkwLCJTdGF0ZSI6IlRTIiwiQ2l0eSI6Ikh5ZGVyYWJhZCIsIkFyZWEiOiJIaXRlY2ggY2l0eSIsIlN0cmVldCI6IktQSEIgcGhhc2UgNyIsIkJ1bGRpbmciOiJJbmR1IEZvcnR1bmUiLCJGbGF0Tm8iOiIxMDIiLCJpYXQiOjE2MDk3Mzc0NjJ9.1B0hEQ9dTmwBLv5c0pFqnH9ES4sHr4IYFcIsLzFe9FQ";

    console.log("Update as default");
    let tempMilestonesWithoutAmount = [...milestoneItems];
    for (let i = 0; i < tempMilestonesWithoutAmount.length; i++) {
      delete tempMilestonesWithoutAmount[i].totalAmount;
    }

    setIsTouchedForUpdatingToVendor({ Touched: false, disabled: true });
    const response = await fetch(
      `${config.profileService}vendor/profile/${VendorDetailsSelected.current}`,
      {
        method: "PUT",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + Token,
        },
        body: JSON.stringify({
          Milestones: tempMilestonesWithoutAmount,
        }),
      }
    ).then((res) => {
      return res.json();
    });
    if (response.status == 200) {
      toast.success("Updated Milestones!");
      console.log("successfully updated");
    }
    setIsTouchedForUpdatingToVendor({ Touched: false, disabled: true });
  };

  const addNewMilestones = () => {
    let tempMilestones = [...milestoneItems];
    tempMilestones.push({
      name: "Type your milestone here",
      percentage: 0,
    });

    setMilestoneItems(tempMilestones);
  };

  const submitMilestoneDescription = () => {
    let status = false;
    let statusQuo = false;
    for (let index = 0; index < Object.keys(milestoneItems).length; index++) {
      if (milestoneItems[index].name == "Type your milestone here") {
        status = true;
      }
    }
    for (let index = 0; index < Object.keys(milestoneItems).length; index++) {
      if (milestoneItems[index].percentage == 0) {
        statusQuo = true;
      }
    }
    if (status) {
      alert("Kindly fill all the descriptions!");
    } else if (statusQuo) {
      alert("Kindly define or delete milestones with 0% payment %");
    } else {
      let tempMilesonesTotal = milestoneItems
        .reduce((milestoneTotal, mitem) => {
          milestoneTotal += Number(mitem.totalAmount);
          return milestoneTotal;
        }, 0)
        .toFixed(2);
      if (tempMilesonesTotal != +totalAmount) {
        // console.log("amounts not equal", totalAmount, tempMilesonesTotal);
        if (
          window.confirm(
            "The difference amount will be added to the last Milestone"
          )
        ) {
          // console.log('(prev[prev.length - 1].totalAmount)' , 'diff to be added ',(totalAmount- tempMilesonesTotal))
          // setMilestoneItems((prev)=>{
          let temp = JSON.parse(JSON.stringify(milestoneItems));
          // console.log('tempe',parseFloat(temp[temp.length - 1].totalAmount) + Number((totalAmount - tempMilesonesTotal).toFixed(2)) )

          // (prev[prev.length - 1].totalAmount)  = Number(prev[prev.length - 1].totalAmount) + (totalAmount - tempMilesonesTotal)
          milestoneItems[milestoneItems.length - 1].totalAmount =
            parseFloat(temp[temp.length - 1].totalAmount) +
            Number((totalAmount - tempMilesonesTotal).toFixed(2)); // directly modifying the value to avoid multiple state transition changes
          // return temp
          // })
        } else {
          return;
        }
      }
      // else{

      //   // let tempMilestoneTobeAddedIndex = [...milestoneTobeAddedIndex];
      //   // tempMilestoneTobeAddedIndex.push(currentMilestoneTobeEditedIndex);
      //   // console.log('milestoneTobeAddedIndex : ', milestoneTobeAddedIndex);
      //   // setMilestoneTobeAddedIndex(tempMilestoneTobeAddedIndex);
      // }
      // console.log("milestoneItems from paymnt trms : ", milestoneItems);
      setTheValueOfDefaultMilestones(milestoneItems);
      setTotalPaidAmountReleasedOrder({
        isChanged: false,
        value: tempTotalPaid,
      });
      setShowPaymentTerms(false);
    }
  };

  // useEffect(()=>{
  //   console.log('totalPaid changed',totalPaidAmountReleasedOrder);
  //   setMilestoneItems((prev)=>{
  //     let temp = [];
  //     prev.map((mItem)=>{
  //       mItem.totalAmount = (mItem.percentage * (totalAmount - totalPaidAmountReleasedOrder))/100
  //       temp.push(mItem)
  //     })
  //     return temp
  //   })
  // },[totalPaidAmountReleasedOrder])
  // useEffect(()=>{

  //  if(totalPaidAmountReleasedOrder > 0){ console.log('in the useffecet totalpaidamount',totalPaidAmountReleasedOrder)
  //   setMilestoneItems((prev) =>{
  //     let temp = [{name : "Paid" , percentage : Math.ceil(((tempTotalPaid/ totalAmount)*100)), totalAmount : tempTotalPaid}];
  //     prev.splice( 0 ,1)
  //     prev.map((mitem)=>{
  //       mitem.percentage = Math.floor(((mitem.totalAmount)/totalAmount) * 100)
  //       temp.push(mitem)
  //     })
  //     return temp
  //   })}
  // },[])
  // useEffect(()=>{
  //  if(!firstrender){
  //   setMilestoneItems((prev) =>{
  //   console.log('prev before settomg the values',prev)
  //     let temp = [{name:"Paid", percentage : (tempTotalPaid/totalAmount)*100 , totalAmount:tempTotalPaid}];
  //     prev.splice(0 ,1)
  //     prev.map((mitem)=>{
  //       console.log('mitem iterating',mitem)
  //       // mitem.totalAmount = (totalAmount - totalPaidAmountReleasedOrder)
  //       // mitem.percentage = ((mitem.totalAmount)/totalAmount) * 100
  //       mitem.totalAmount = (
  //         (mitem.percentage * ( totalAmount - Number(tempTotalPaid)))  / 100
  //       ).toFixed(2);
  //       mitem.percentage = (mitem.totalAmount /(totalAmount-tempTotalPaid)) * 100
  //       temp.push(mitem)
  //     })
  //     console.log('temparray to be pushing',temp)
  //     return temp
  //   })}
  //   firstrender = false
  // },[tempTotalPaid])

  return (
    <Modal
      open={showPaymentTerms}
      onClose={() => {
        setShowPaymentTerms(false);
        setTheValueOfDefaultMilestones(defaultMilestones);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "70%",
          maxHeight: "90%",
          maxWidth: "70%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 25 }}
          >
            <p
              style={{
                width: "80%",
                maxwidth: "80%",
                minwidth: "80%",
                textAlign: "center",
                fontWeight: 700,
                fontSize: "22.5px",
                fontFamily: "Montserrat",
              }}
            >
              Set Milestones for the order
            </p>
            <div
              style={{
                display: "flex",
                width: "20%",
                maxwidth: "20%",
                minwidth: "20%",
                flexDirection: "row",
                gap: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(35, 23, 0, 0.5)",
                  fontWeight: 700,
                }}
              >
                Total PO Amount
              </p>
              <p style={{ fontWeight: 700 }}>
                {"\u20B9 "} {totalAmount}
              </p>
            </div>
          </div>
          {/* <div>
            <p>Paid Amount</p>
            <input
            value={totalPaidAmountReleasedOrder}
            onChange={(e)=>setTotalPaidAmountReleasedOrder(e.currentTarget.value)}
            />
          </div> */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Sr. No
            </p>
            <p
              style={{
                width: "50%",
                maxWidth: "50%",
                minWidth: "50%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Milestone /RA Description
            </p>
            <p
              style={{
                width: "20%",
                maxWidth: "20%",
                minWidth: "20%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Payment %
            </p>
            <p
              style={{
                width: "20%",
                maxWidth: "20%",
                minWidth: "20%",
                textAlign: "center",
                backgroundColor: "#FDD34D",
                borderRight: "1px solid #DFDFDF",
                fontWeight: 700,
                padding: 5,
              }}
            >
              Total Amount
            </p>
          </div>
          {/* <div  style={{ display: "flex", flexDirection: "row" }}>
          <p
                  style={{
                    width: "10%",
                    maxWidth: "10%",
                    minWidth: "10%",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  { 1}
                </p>
                <p style={{
                  width: "50%",
                  maxWidth: "50%",
                  minWidth: "50%",
                  textAlign: "center",
                  padding: 5,
                  outline: "none",
                  border: "none",
                }}>Paid</p>
                <p style={{  width: "20%",
                    maxWidth: "20%",
                    minWidth: "20%",
                    textAlign: "center",
                    borderRight: "1px solid #DFDFDF",
                    padding: 5,
                    fontWeight: 700,
                    outline: "none",
                    border: "none",}}>%</p>
                    <input
                    style={{maxWidth: "20%",
                    minWidth: "20%",
                    textAlign: "center",
                    borderRight: "1px solid #DFDFDF",
                    padding: 5,
                    fontWeight: 700,
                    outline: "none",
                    border: "none",}}
            value={totalPaidAmountReleasedOrder}
            onChange={(e)=>setTotalPaidAmountReleasedOrder(e.currentTarget.value)}
            />
                
          </div> */}
          {/* {console.log('milestones array', milestoneItems)} */}
          {milestoneItems.map((item, index) => (
            <div key={index}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p
                  style={{
                    width: "10%",
                    maxWidth: "10%",
                    minWidth: "10%",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {index + 1}
                </p>

                {item.name == "Type your milestone here" ? (
                  <input
                    style={{
                      width: "50%",
                      maxWidth: "50%",
                      minWidth: "50%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    placeholder="Type your milestone here"
                    // value=''
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      let tempMilestones = [...milestoneItems];
                      tempMilestones[index].name = value;
                      setMilestoneItems(tempMilestones);
                    }}
                  />
                ) : item.name === "Paid" ? (
                  <p
                    style={{
                      width: "50%",
                      maxWidth: "50%",
                      minWidth: "50%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                  >
                    Paid
                  </p>
                ) : (
                  <input
                    style={{
                      width: "50%",
                      maxWidth: "50%",
                      minWidth: "50%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    // placeholder='Type your milestone here'
                    value={item.name}
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      let tempMilestones = [...milestoneItems];
                      tempMilestones[index].name = value;
                      setMilestoneItems(tempMilestones);
                    }}
                    onBlur={() => {
                      console.log("onBluererer");
                      setIsTouchedForUpdatingToVendor((prev) => {
                        return {
                          disabled:
                            milestoneItems
                              .reduce(
                                (total, item) =>
                                  (total = total + Number(item.percentage)),
                                0
                              )
                              .toFixed(0) == 100
                              ? false
                              : true,
                          Touched: true,
                        };
                      });
                      // isTouchedForUpdatingToVendor.current = true;
                    }}
                  />
                )}
                {item.name === "Paid" ? (
                  <p
                    style={{
                      width: "20%",
                      maxWidth: "20%",
                      minWidth: "20%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                  >
                    {item.percentage}
                  </p>
                ) : (
                  <input
                    style={{
                      width: "20%",
                      maxWidth: "20%",
                      minWidth: "20%",
                      textAlign: "center",
                      padding: 5,
                      outline: "none",
                      border: "none",
                    }}
                    onBlur={() => {
                      console.log("onBluererer");
                      // setIsTouchedForUpdatingToVendor(true);
                      setIsTouchedForUpdatingToVendor((prev) => {
                        console.log("prev", prev);
                        return {
                          disabled:
                            milestoneItems
                              .reduce(
                                (total, item) =>
                                  (total = total + Number(item.percentage)),
                                0
                              )
                              .toFixed(0) == 100
                              ? false
                              : true,
                          Touched: true,
                        };
                      });

                      // isTouchedForUpdatingToVendor.current = true;
                    }}
                    value={item.percentage + "%"}
                    onChange={(e) => {
                      if (
                        tempTotalPaid > 0 &&
                        milestoneItems[0].name === "Paid"
                      ) {
                      }

                      let value = e.currentTarget.value.replace(
                        /(?!-)[^0-9.]/g,
                        ""
                      );
                      let tempMilestones = [...milestoneItems];
                      tempMilestones[index].percentage = value;
                      tempMilestones[index].totalAmount = (
                        (value * totalAmount) /
                        100
                      ).toFixed(2);
                      setMilestoneItems(tempMilestones);
                    }}
                  />
                )}

                <input
                  style={{
                    width: "20%",
                    maxWidth: "20%",
                    minWidth: "20%",
                    textAlign: "center",
                    borderRight: "1px solid #DFDFDF",
                    padding: 5,
                    fontWeight: 700,
                    outline: "none",
                    border: "none",
                  }}
                  value={
                    milestoneItems[index].name === "Paid"
                      ? tempTotalPaid
                      : "\u20B9 " + defaultMilestones.length == 1
                      ? totalAmount
                      : item.totalAmount
                  }
                  onChange={(e) => {
                    if (milestoneItems[index].name === "Paid") {
                      let value = e.currentTarget.value.replace(
                        /(?!-)[^0-9.]/g,
                        ""
                      );
                      let tempMilestones = [...milestoneItems];
                      // tempMilestones.splice(0,1);
                      // tempMilestones[index].percentage = (
                      //   (value / (totalAmount - value)) *
                      //   100
                      // ).toFixed(0);
                      tempMilestones[index].percentage = Math.ceil(
                        (value / totalAmount) * 100
                      ).toFixed(0);
                      tempMilestones[index].totalAmount = value;
                      setMilestoneItems(tempMilestones);

                      // console.log('changed the valeue of paid ',tempMilestones)

                      // setMilestoneItems((prev) =>{
                      // let temp = [{name:"Paid", percentage : Math.ceil((value/totalAmount)*100) , totalAmount:value}];
                      // tempMilestones.splice(0 ,1)
                      // tempMilestones.map((mitem)=>{
                      //   console.log('before changing the mitem',mitem)
                      //   // mitem.totalAmount = (totalAmount - totalPaidAmountReleasedOrder)
                      //   // mitem.percentage = ((mitem.totalAmount)/totalAmount) * 100
                      //   // mitem.totalAmount = (
                      //   //   (mitem.percentage * ( totalAmount - Number(value)))  / 100
                      //   // ).toFixed(2);
                      //   mitem.totalAmount = totalAmount - temp.reduce((subtractAmount,tempitem) =>{
                      //     return subtractAmount +=  tempitem.totalAmount
                      //   },0)
                      //   // mitem.percentage = ((mitem.totalAmount /(totalAmount-tempTotalPaid)) * 100)
                      //   mitem.percentage = Math.floor(100 - temp.reduce((subtractPercentage,tempitem) =>{
                      //     return subtractPercentage +=  tempitem.percentage
                      //   },0))
                      //   console.log('after changing the mitem ' ,mitem)
                      //   temp.push(mitem)
                      // })
                      // console.log('temparray to be pushing',temp)
                      // // return temp
                      // setMilestoneItems(temp);
                      setTempTotalPaid(value);
                      // })
                    } else {
                      let value = e.currentTarget.value.replace(
                        /(?!-)[^0-9.]/g,
                        ""
                      );
                      let tempMilestones = [...milestoneItems];
                      tempMilestones[index].percentage = (
                        (value / totalAmount) *
                        100
                      ).toFixed(0);
                      tempMilestones[index].totalAmount = value;
                      setMilestoneItems(tempMilestones);
                    }
                  }}
                  onBlur={() => {
                    // console.log("onBluererer");
                    // setIsTouchedForUpdatingToVendor(true);
                    setIsTouchedForUpdatingToVendor({
                      Touched: true,
                      disabled:
                        milestoneItems
                          .reduce(
                            (total, item) =>
                              (total = total + Number(item.percentage)),
                            0
                          )
                          .toFixed(0) == 100
                          ? false
                          : true,
                    });

                    // isTouchedForUpdatingToVendor.current = true;
                  }}
                />
                {item.name === "Paid" ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "5%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // write the code here
                      let tempMilestones = [...milestoneItems];
                      tempMilestones.splice(index, 1);
                      setMilestoneItems(tempMilestones);
                    }}
                  >
                    <AiOutlineDelete />
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#DFDFDF",
                }}
              ></div>
            </div>
          ))}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              boxShadow: "0px 2px 10px 4px rgba(0, 0, 0, 0.03)",
            }}
          >
            <p
              style={{
                width: "10%",
                maxWidth: "10%",
                minWidth: "10%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
              }}
            >
              Total
            </p>
            <p
              style={{
                width: "50%",
                maxWidth: "50%",
                minWidth: "50%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
              }}
            ></p>
            {milestoneItems
              .reduce(
                (total, item) => (total = total + Number(item.percentage)),
                0
              )
              .toFixed(0) < 100 ? (
              <p
                style={{
                  width: "20%",
                  maxWidth: "20%",
                  minWidth: "20%",
                  textAlign: "center",
                  borderBottom: "1px solid #DFDFDF",
                  padding: 5,
                  fontWeight: 500,
                }}
              >
                {milestoneItems
                  .reduce(
                    (total, item) => (total = total + Number(item.percentage)),
                    0
                  )
                  .toFixed(0)}{" "}
                %
              </p>
            ) : milestoneItems
                .reduce(
                  (total, item) => (total = total + Number(item.percentage)),
                  0
                )
                .toFixed(0) == 100 ? (
              <p
                style={{
                  width: "20%",
                  maxWidth: "20%",
                  minWidth: "20%",
                  textAlign: "center",
                  borderBottom: "1px solid #DFDFDF",
                  padding: 5,
                  fontWeight: 500,
                  color: "green",
                }}
              >
                {milestoneItems
                  .reduce(
                    (total, item) => (total = total + Number(item.percentage)),
                    0
                  )
                  .toFixed(0)}{" "}
                %
              </p>
            ) : (
              <p
                style={{
                  width: "20%",
                  maxWidth: "20%",
                  minWidth: "20%",
                  textAlign: "center",
                  borderBottom: "1px solid #DFDFDF",
                  padding: 5,
                  fontWeight: 500,
                  color: "red",
                }}
              >
                {milestoneItems
                  .reduce(
                    (total, item) => (total = total + Number(item.percentage)),
                    0
                  )
                  .toFixed(0)}{" "}
                %
              </p>
            )}
            <p
              style={{
                width: "20%",
                maxWidth: "20%",
                minWidth: "20%",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                padding: 5,
                fontWeight: 500,
              }}
            >
              {"\u20B9 "}{" "}
              {milestoneItems
                .reduce(
                  (total, item) => (total = total + Number(item.totalAmount)),
                  0
                )
                .toFixed(2)}{" "}
              /-
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              cursor: "pointer",
              marginTop: 20,
            }}
            onClick={() => {
              addNewMilestones();
            }}
          >
            <p
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                lineHeight: "20px",
                fontSize: "20px",
                textAlign: "center",
                border: "1px solid #4685D4",
                color: "#4685D4",
              }}
            >
              +
            </p>
            <p style={{ color: "#4685D4" }}>Add New Milestone</p>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: "0.5%" }}>
            <div style={{ width: "75%", maxWidth: "75%", minWidth: "75%" }}>
              <button
                style={{
                  width: "40%",
                  maxWidth: "40%",

                  textAlign: "center",
                  backgroundColor: `${
                    !isTouchedForUpdatingToVendor.disabled
                      ? "rgb(51, 103, 214)"
                      : "darkgray"
                  }`,
                  borderRadius: "5px",
                  border: "none",
                  padding: 5,
                  fontSize: "14px",
                  cursor: !isTouchedForUpdatingToVendor.disabled
                    ? "pointer"
                    : "not-allowed",
                  color: !isTouchedForUpdatingToVendor.disabled
                    ? "white"
                    : "black",
                }}
                disabled={
                  !isTouchedForUpdatingToVendor.disabled
                    ? !VendorDetailsSelected.current
                      ? true
                      : !isTouchedForUpdatingToVendor.Touched
                      ? true
                      : false
                    : true
                }
                onClick={setAsVendorDefaultHandler}
              >
                Set As default for Vendor
              </button>
            </div>
            <div
              style={{
                width: "12%",
                maxWidth: "12%",
                minWidth: "12%",
                textAlign: "center",
                border: "1px solid #FDD34D",
                borderRadius: "5px",
                padding: 5,
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => {
                // console.log("copyMilestoneItems : ", copyMilestoneItems);
                setTheValueOfDefaultMilestones(copyMilestoneItems);
                setShowPaymentTerms(false);
              }}
            >
              Discard
            </div>
            {milestoneItems
              .reduce(
                (total, item) => (total = total + Number(item.percentage)),
                0
              )
              .toFixed(0) == 100 ? (
              <div
                style={{
                  width: "12%",
                  maxWidth: "12%",
                  minWidth: "12%",
                  textAlign: "center",
                  backgroundColor: "#FDD34D",
                  borderRadius: "5px",
                  padding: 5,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => submitMilestoneDescription()}
              >
                Confirm
              </div>
            ) : (
              <div
                style={{
                  width: "12%",
                  maxWidth: "12%",
                  minWidth: "12%",
                  textAlign: "center",
                  backgroundColor: "#fdf3d3",
                  borderRadius: "5px",
                  padding: 5,
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  alert("All milestone percentage is not equal to 100%")
                }
              >
                Confirm
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
}
