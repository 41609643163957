import firebase from "../../config/firebase";
import React, { useState, lazy, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import Ongoing from "./Ongoing";
import Completed from "./Completed";
import { useAnalyticsContext } from "../../Context/Analytics";
// const Ongoing = React.lazy(() => import("./Ongoing"));

const Finance = () => {
  const location = useLocation();

  const { record } = useAnalyticsContext();

  const data = location.state?.selectedProject;
  // console.log("dataaaa,", data);

  const [activeTab, setActiveTab] = useState(0);
  const [projectDetails, setProjectDetails] = useState();

  useEffect(() => {
    // setSpinner(true)
    let projectId = location.pathname.split("/")[2];
    firebase
      .database()
      .ref("liveBOQ/" + projectId.toString())
      .once("value", (snapshot) => {
        // console.log('Firebase SS', snapshot.val())
        // setSpinner(false)
        if (snapshot.exists()) {
          const response = {
            ...snapshot.val(),
            rooms: JSON.parse(snapshot.val().rooms),
            Rooms: JSON.parse(snapshot.val().rooms),
          }; // has to replace with rooms or Rooms
          console.log("response :", snapshot.val());
          setProjectDetails(response);
          // setSelectedProject(response);
        }
      });
  }, []);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {/* <div style={{ margin: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', }}> */}
      <div
        style={{
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "20px",
          position: "sticky",
          top: 0,
          background: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            gap: 20,
            cursor: "pointer",
          }}
        >
          <div style={{ width: "15%" }}>
            {projectDetails && (
              <p
                style={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  cursor: "default",
                }}
              >
                {projectDetails.clientName
                  ? projectDetails.clientName
                  : projectDetails.ClientName}
              </p>
            )}{" "}
          </div>
          <div
            style={{
              width: "85%",
              display: "flex",
              flexDirection: "row",
              gap: 20,
            }}
          >
            <div
              onClick={() => {
                setActiveTab(0);
                record("@ongoing_tab_click_success", {
                  description: "ongoing tab click success",
                });
              }}
              className={activeTab === 0 ? "activeText" : ""}
            >
              <p>Ongoing</p>
              <div className={activeTab === 0 ? "activeTab" : ""}></div>
            </div>
            <div
              onClick={() => {
                setActiveTab(1);
                record("@completed_tab_click_success", {
                  description: "completed tab click success",
                });
              }}
              className={activeTab === 1 ? "activeText" : ""}
            >
              <p>Completed</p>
              <div className={activeTab === 1 ? "activeTab" : ""}></div>
            </div>
          </div>
        </div>
      </div>
      {(projectDetails || data) &&
        (activeTab == 0 ? (
          // <Suspense fallback={<div>Loading...</div>}>
          <Ongoing selectedProject={projectDetails} />
        ) : (
          // </Suspense>
          <Completed />
        ))}
    </div>
  );
};

export default drawerTemplate1(Finance);
