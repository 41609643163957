import { useHistory } from "react-router-dom";
import img1 from "../../assets/img/donestars.png";

const SuccessPage = (props) => {
  const history = useHistory();

  const successHandler = () => {
    history.push("/ReleasedOrders/" + props.info.ProjectId, {
      data: props.info,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 60px",
      }}
    >
      <h3
        style={{
          color: "#008000",
          padding: "5px",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        {props.isApproval
          ? "Oppurtunity Sent for Approval"
          : "Opportunity Released!"}
      </h3>
      <img
        style={{ width: "235px", height: "190px", margin: "15px 0" }}
        src={img1}
        alt="image"
      />
      <h4
        style={{
          fontSize: "16px",
          color: "black",
          fontWeight: "600",
          padding: "5px 0px",
        }}
      >
        {props.orderType}
      </h4>
      <h4
        style={{
          fontSize: "16px",
          color: "black",
          fontWeight: "600",
          padding: "5px 0px",
        }}
      >
        Opportunity for {props.activeCategory.toString()} category with
      </h4>
      <h4
        style={{
          fontSize: "16px",
          color: "black",
          fontWeight: "600",
          padding: "5px 0px",
        }}
      >
        {props.selectedItems.length} items{" "}
        {props.isApproval ? "Sent for Approval" : "released"}
      </h4>

      <button
        style={{
          marginTop: "25px",
          padding: "10px 50px",
          backgroundColor: "#2F4858",
          border: "none",
          color: "#ffffff",
          fontSize: "18px",
          fontWeight: "400",
        }}
        onClick={successHandler}
      >
        Continue
      </button>
    </div>
  );
};

export default SuccessPage;
