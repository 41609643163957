import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AuthService from "../../api/authService";
import config from "../../config/config";
import { UserContext } from "../../Context/UserContext";
import "./ShareModal.scss";
import SmsService from "../../api/smsService";
import environment from "../../config/config";
import { Chip } from "@material-ui/core";

// messaging_product: "whatsapp",
//             to: to,
//             type: "template",
//             template: {
//               name: "notifyunreleasedpo",
//               language: {
//                 code: "en_US",
//               },
//               components: [
//                 {
//                   type: "body",
//                   parameters: [
//                     {
//                       type: "text",
//                       text: firm,
//                     },
//                     {
//                       type: "text",
//                       text: category,
//                     },
//                     {
//                       type: "text",
//                       text: "Site Engineer",
//                     },
//                   ],
//                 },
//               ],
//             },
//           }
const ShareModal = ({
  setShareModal,
  order,
  projectId,
  ClientName,
  isApproval,
}) => {
  const [isloading, setIsloading] = useState(false);

  const SendMessageHandler = async () => {
    let link =
      environment.environment == "staging"
        ? `https://staartstaging.netlify.app/?po_id=${order.OrderId}`
        : `https://staartbuild.netlify.app/?po_id=${order.OrderId}`;
    const smsService = new SmsService();

    let componetns = [
      {
        type: "body",
        parameters: [
          {
            type: "text",
            text: isApproval
              ? userContext.user.userName || userContext.user.email
              : userContext.user.Firm,
          },
          {
            type: "text",
            text: JSON.parse(order.Data).data[0]?.workType
              ? "Work Order"
              : "Purchase Order",
          },
          {
            type: "text",
            text: order.VendorName,
          },
          {
            type: "text",
            text: ClientName,
          },
          {
            type: "text",
            text: link,
          },
        ],
      },
    ];
    setIsloading(true);
    // selectedUserstoShare.filter((user) => {
    //         console.log('user',user ,  'sdfsdf',!((typeof user === "string" && isNaN(Number(user))) || user.length < 10))
    //     return !((typeof user === "string" && isNaN(Number(user))) || user.length < 10)} ).map(user => console.log('yooodfsdfjsdlfjsdf',typeof user === "string" ? user : user.ContactNumber))
    // selectedUserstoShare.filter((user) =>  !((typeof user === "string" && isNaN(Number(user))) || user.length < 10)).map(async(user) => await smsService.whatsAppSMS({...data ,to: typeof user === "string" ? user : user.ContactNumber}).then((res)=>{
    //     // console.log('sent to user',user,'res',res)

    // }))
    selectedUserstoShare
      .filter(
        (user) =>
          !(
            (typeof user === "string" && isNaN(Number(user))) ||
            user.length < 10
          )
      )
      .map(
        async (user) =>
          await smsService
            .whatsAppSMS(
              typeof user === "string" ? user : user.ContactNumber,
              isApproval
                ? "order_approval_from_admins"
                : "notify_users_of_po_release",
              isApproval ? "en" : "en_US",
              componetns
            )
            .then((res) => {
              // console.log('sent to user',user,'res',res)
            })
      );

    // console.log("setting tge data asss",data)
    setIsloading(false);
    setShareModal(false);
    // smsService.whatsAppSMS()
  }; // this is for share the po to multiple users

  // const requestApproval = async() =>{
  //     let link =  environment.environment == "staging"
  //     ? `https://startstaging.web.app/PO/${order.OrderId}`
  //     : `https://staart.build/PO/${order.OrderId}`
  //     const smsService = new SmsService;
  //     let componetns = [
  //         {
  //             type: "body",
  //             parameters: [
  //             {
  //                 type: "text",
  //                 text: userContext.user.Firm,
  //             },
  //             {
  //                 type:"text",
  //                 text:JSON.parse(order.Data).data[0]?.workType ? "Work Order" : "Purchase Order"
  //             },
  //             {
  //                 type: "text",
  //                 text: order.VendorName,
  //             },
  //             {
  //                 type: "text",
  //                 text: ClientName,
  //             },
  //             {
  //                 type: "text",
  //                 text: link,
  //             },
  //             ],
  //         },
  //         ]
  //         setIsloading(true)
  // }

  const [optiontoshare, setOptionstoShare] = useState([]);
  const userContext = useContext(UserContext);
  const [selectedUserstoShare, setSelectedUserstoShare] = useState([]);
  const [enteredNumber, setEnteredNumber] = useState("");
  // console.log(userContext)

  // console.log('Order ',order,'projectId',projectId)
  const fetchDetailsofUsers = async () => {
    const authService = new AuthService();
    const response = await authService.getUserForShare(userContext.user.Firm);
    // console.log("response of users", response);
    if (response.status == 200) {
      //   console.log("success");
      // setOptionstoShare(()=>{
      //     let temparr=[...response.payload];

      //    return  temparr?.filter((user)=> (JSON.parse(user.AssignedProjects).filter((assignedproject)=> assignedproject.ProjectId === projectId ? true :false )) &&  user )
      // })
      setOptionstoShare(response.payload);
      // filterOptions(response.payload)
      let userList = [...response.payload];
      let admins = [
        ...response.payload.filter((user) => user.accountType === "Admin"),
      ];
      userList = userList
        .filter(
          (user) =>
            user.accountType != "Admin" &&
            user.active == "0" &&
            user.AssignedProjects !== null
        )
        .filter((user) =>
          JSON.parse(user.AssignedProjects).some(
            (aproject) => aproject.ProjectId == projectId && user
          )
        );
      //   console.log("after", userList);
      // console.log(response)
      setOptionstoShare(userList);
      setOptionstoShare((prev) => {
        return [...prev, ...admins];
      });
      // setOptions()
    }
  };
  useEffect(() => {
    fetchDetailsofUsers();
  }, []);

  return (
    <div className="share-modal-container">
      <div className="share-modal-heading-container">
        <p>Share Po to </p>
      </div>
      <div className="share-modal-send-to">
        <Autocomplete
          multiple
          freeSolo
          inputValue={enteredNumber}
          id="tags-standard"
          value={selectedUserstoShare}
          options={optiontoshare}
          getOptionLabel={(option) => option.email}
          getOptionDisabled={(option) =>
            option.ContactNumber === null || option.ContactNumber.length < 10
          }
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip
                variant="outlined"
                style={{
                  background:
                    (typeof option === "string" && isNaN(Number(option))) ||
                      option.length < 10
                      ? "#ff00005e"
                      : "",
                }}
                // sx={{
                //     background : 'black',
                //     color:(typeof option === "string" && isNaN(Number(option))) || option.length < 10 ? "red" : ""
                // }}
                label={typeof option === "string" ? option : option.email}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              value={enteredNumber}
              onChange={(e, val) => {
                // console.log("ENTERED NUMBER", e, val);
                setEnteredNumber(e.target.value);
              }}
              {...params}
              variant="standard"
              label="Send to"
              placeholder="Send to"
            />
          )}
          onChange={(e, val) => {
            setSelectedUserstoShare(val);
            setEnteredNumber("");
          }}
          onBlur={(e) => {
            const val = e.target.value;
            if (val.length === 10 && !isNaN(val)) {
              //   console.log("Val ON BLUR", val, e);
              const che = [...selectedUserstoShare, e.target.value];
              //   console.log("CHE", che);

              setSelectedUserstoShare(che);
              setEnteredNumber("");
            }
          }}
        />
      </div>
      <div className="share-modal-bottom-container">
        <button
          onClick={SendMessageHandler}
          disabled={
            (selectedUserstoShare && selectedUserstoShare.length < 1) ||
            isloading
          }
          className={`share-modal-bottom-container-send-btn ${isloading ? "loading-state-btn" : ""
            }`}
        >
          <p>{isApproval ? "Request" : " Send"}</p>
          {isloading && (
            <CircularProgress
              style={{ width: "20px", height: "", position: "absolute" }}
            />
          )}
        </button>
      </div>
    </div>
  );
};
export default ShareModal;
