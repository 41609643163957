import { useState, useContext, useMemo } from "react";
import "./Req_Rec_Card.scss";
import { UserContext } from "../../Context/UserContext";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import RemarkTextBox from "../Finance/RemarksTextBox";
import { FaHistory } from "react-icons/fa";
import OpportunityService from "../../api/opportunityService";
import toast from "react-hot-toast";
import { AiOutlineEye } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import NotificationService from "../../api/notificationService";
import PaymentOption from "../Finance/paymentOption";
import { Tooltip } from "antd";
import { MdDeleteOutline } from "react-icons/md";
import { IconButton } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import VendorAccStatement from "../Analytics/VendorAccStatement";
import { DropDownMUI } from "../../components";
import firebase from "../../config/firebase";
import { environment } from "../../config";
import config from "../../config/config";

const VendorSignModal = ({
  noVendorSignProceedModal,
  setNoVendorSignProceedModal,
  setActionModal,
  setPaymentOption,
}) => {
  return (
    <Modal
      open={noVendorSignProceedModal.modalOpen}
      onClose={() => {
        setNoVendorSignProceedModal((prev) => {
          return {
            ...prev,
            modalOpen: false,
          };
        });
      }}

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box
        className="no-vendor-sign"
        sx={{
          position: "relative",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          width: "30%",
          maxHeight: "90%",
          maxWidth: "70%",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ marginBottom: "0.5em" }}>
            Vendor has not signed the PO
          </h2>
          <h3>Do you want to continue?</h3>
          <div className="vendor-nosign-btns-container">
            <button
              onClick={() => {
                setNoVendorSignProceedModal((prev) => {
                  return {
                    ...prev,
                    modalOpen: false,
                  };
                });
              }}
              className="vendor-nosign-Btn"
            >
              Cancel
            </button>
            <button
              className={`vendor-nosign-Btn proceedBtn`}
              onClick={() => {
                setNoVendorSignProceedModal((prev) => {
                  return {
                    ...prev,
                    modalOpen: false,
                  };
                });

                if (noVendorSignProceedModal.type === "Update") {
                  setPaymentOption({
                    isModalOpen: true,
                    isRequest: false,
                    isFinanceRequest: true,
                  });
                  return;
                }
                setActionModal({
                  modalOpen: true,
                  type: noVendorSignProceedModal.type,
                });
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const Req_Rec_Card = ({
  request,
  rIndex,
  setFinRequestsData,
  isRecord,
  setRawData,
  selectedRequests,
  handleCheckboxChange,
}) => {

  const udpateTheRequestInTheFirebase = async (OrderId, RequestId) => {

    firebase
      .database()
      .ref("poRequests/" + OrderId + "/" + RequestId + '/status/')
      .set("Deleted");

  }

  const deleteRequest = async (uuid, OrderId, RequestId) => {

    const opportunityService = new OpportunityService();

    await opportunityService
      .deleteRequestByuuid(uuid)
      .then((res) => {
        if (res.status === 200) {

          toast.success("Deleted Request Successfully");

          setRawData((prev) => {
            let temp = JSON.parse(JSON.stringify(prev));
            const indexOfUpdateRequest = temp.findIndex(
              (r) => r.uuid === request.uuid
            );
            temp.splice(indexOfUpdateRequest, 1);
            return temp;
          });

          udpateTheRequestInTheFirebase(OrderId, RequestId);

        } else {
          toast.error("Error Deleting Reqeust");
        }
      })
      .catch(() => {
        toast.error("Error Deleting Request");
      });
  };

  const deleteRequestConfirmation = (uuid, OrderId, RequestId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0px 0px 5px ",
            }}
          >
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "700",
                padding: "15px",
                marginBottom: "10px",
              }}
            >
              Do you want to Delete the Request?
            </h4>
            <div>
              <button
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginRight: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  deleteRequest(uuid, OrderId, RequestId);
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                onClick={onClose}
                style={{
                  padding: "5px 20px",
                  backgroundColor: " #2f4858",
                  color: "whitesmoke",
                  fontSize: "16px",
                  marginLeft: "5px",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                No
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const [paymentOption, setPaymentOption] = useState({
    isModalOpen: false,
    isRequest: true,
    isFinanceRequest: true,
  });

  const [noVendorSignProceedModal, setNoVendorSignProceedModal] = useState({
    modalOpen: false,
    type: "",
  });

  const getDate = (timestamp) => {
    if (timestamp == undefined) return "-";
    return (
      new Date(timestamp).getDate() +
      "-" +
      (new Date(timestamp).getMonth() + 1) +
      "-" +
      new Date(timestamp).getFullYear()
    );
  };

  const getTime = (timestamp) => {
    if (timestamp == undefined) return "-";

    return (
      new Date(timestamp).getHours() + ":" + new Date(timestamp).getMinutes()
    );
  };

  const calculateDate = (dateRequested) => {
    var date1 = new Date();
    var date2 = new Date(dateRequested);
    return Math.floor((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
  };

  const userContext = useContext(UserContext);
  let CommonMilestones = !isRecord && JSON.parse(request?.CommonMilestones);
  let milestoneIndexRequested =
    !isRecord && JSON.parse(request?.details).milestoneIndexRequested;
  let History = JSON.parse(request?.History);
  let latestEDIT =
    History && Object.values(History)[Object.values(History).length - 1];

  const [showHistory, setShowHistory] = useState(false);
  const [commenttoUpdate, setComment] = useState("");
  const [actionModal, setActionModal] = useState({
    modalOpen: false,
    type: "",
  });
  const [showDetails, setShowDetails] = useState(false);
  const [showVendorStatement, setShowVendorStatement] = useState(false);
  const [priorityOfRequest, setPriorityOfRequest] = useState("P3");

  const sendPaymentMessage = async (amountToUpdate, utrNumber) => {

    console.log('sendPaymentMessage is addressed...');


    let milestoneName = "Completion"

    console.log("request : ", request);



    if (request.CommonMilestones) {

      let milestoneNameObject = JSON.parse(request.CommonMilestones)[parseInt(JSON.parse(request.details)["milestoneIndexRequested"])]
      console.log("miletsone object", milestoneNameObject)
      milestoneName = milestoneNameObject.name + " (" + milestoneNameObject.percentage + ") " + " % of amount " + parseInt(milestoneNameObject.totalAmount).toFixed(0) + " "

    }

    console.log("request.VendorMobile : ", request.VendorMobile)

    try {
      const response = await (
        await fetch(`${config.whatsAppService.URL}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${config.whatsAppService.AuthKey}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            messaging_product: "whatsapp",
            to: request.VendorMobile,
            type: "template",
            template: {
              name: environment === "staging" ? "vendor_payment_done_stg" : "vendor_payment_done",
              language: {
                code: "en",
              },
              components: [
                {
                  type: "header",
                  parameters: [
                    {
                      type: "text",
                      text: JSON.parse(localStorage.getItem("Firm")) // name of the firm
                    }
                  ]
                },
                {
                  type: "button",
                  sub_type: "url",
                  index: 0,
                  parameters: [
                    {
                      type: "text",
                      text: request.OrderId, // po_id
                    }
                  ]
                },
                {
                  type: "body",
                  parameters: [
                    {
                      type: "text",
                      text: request.VendorName ? request.VendorName : 'Dear Vendor' // name of the vendor
                    },
                    {
                      type: "text",
                      text: amountToUpdate.toLocaleString('en-IN') // paid amount
                    },
                    {
                      type: "text",
                      text: request.OrderId ? request.OrderId : 'Your order Id' // PO order ID
                    },
                    {
                      type: "text",
                      text: milestoneName // milestone name
                    },
                    {
                      type: "text",
                      text: userContext.user.email.slice(
                        0,
                        userContext.user.email.indexOf("@")
                      ) // name of the payee
                    },
                    {
                      type: "text",
                      text: request.OrderTotalAmount ? request.OrderTotalAmount.toLocaleString('en-IN') : ""     // total po amount
                    },
                    {
                      type: "text",
                      text: utrNumber.length > 0 ? utrNumber : "Not Provided"     // utr details of the transaction
                    }
                  ]
                }

              ]
            },
          }),
        })
      ).json();
      console.log("Response form sendWhatsapp API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  };


  const updateinOrder = async (
    amountToUpdate,
    modeOfPayment,
    remarks,
    date,
    utrNumber
  ) => {

    const tempMilestonesArr = [...CommonMilestones];

    if (tempMilestonesArr[milestoneIndexRequested].payments) {

      tempMilestonesArr[milestoneIndexRequested].payments.push({
        paymentID: `${modeOfPayment}_mode_of_payment`,
        date: date,
        remarks: remarks,
        amount: Number(amountToUpdate),
        status: "Done",
        mode: modeOfPayment,
        utrNumber: utrNumber,
      });

    } else {

      tempMilestonesArr[milestoneIndexRequested].payments = [
        {
          paymentID: `${modeOfPayment}_mode_of_payment`,
          date: date,
          remarks: remarks,
          amount: Number(amountToUpdate),
          status: "Done",
          mode: modeOfPayment,
          utrNumber: utrNumber,
        },
      ];

    }

    const opportunityService = new OpportunityService();
    let dataOfMilestonestoUpdate = { CommonMilestones: tempMilestonesArr };

    const responseToUpdateOrder =
      await opportunityService.updateOpportunityById(
        request.OrderId,
        dataOfMilestonestoUpdate
      );
    // console.log("respon of updated milestones", responseToUpdateOrder);
    const updateresponse = await opportunityService.updateFinanceRequestByuuId(
      request.uuid,
      {
        Status: "Record",
        details: {
          ...JSON.parse(request?.details),
          UpdatedOn: new Date().getTime(),
          paidOn: date,
        },
      }
    );

    if (responseToUpdateOrder.status === 200) {
      toast.success("Payment Updated in Order successfully");
      // write the things here for sending the message to the vendor for the payment update
      await sendPaymentMessage(amountToUpdate, utrNumber);
      setRawData((prev) => {
        let temp = JSON.parse(JSON.stringify(prev));
        const indexOfUpdateRequest = temp.findIndex(
          (r) => r.uuid === request.uuid
        );
        temp.splice(indexOfUpdateRequest, 1);
        return temp;
      });
    } else {
      toast.error("Error updating to Order");
    }

    if (updateresponse.status === 200) {

      if (request.RequestId != null) {
        await firebase
          .database()
          .ref(
            "poRequests/" +
            request.OrderId +
            "/" +
            request.RequestId +
            "/totalPaidAmount/"
          )
          .set(amountToUpdate);
        await firebase
          .database()
          .ref(
            "poRequests/" +
            request.OrderId +
            "/" +
            request.RequestId +
            "/utrDetails/"
          )
          .set(utrNumber);
        await firebase
          .database()
          .ref(
            "poRequests/" +
            request.OrderId +
            "/" +
            request.RequestId +
            "/status/"
          )
          .set("Bill Paid");
      } else {
        let RequestId = Object.keys(request.History)[0];

        await firebase
          .database()
          .ref(
            "poRequests/" +
            request.OrderId +
            "/" +
            RequestId +
            "/totalPaidAmount/"
          )
          .set(amountToUpdate);
        await firebase
          .database()
          .ref(
            "poRequests/" + request.OrderId + "/" + RequestId + "/utrDetails/"
          )
          .set(utrNumber);
        await firebase
          .database()
          .ref("poRequests/" + request.OrderId + "/" + RequestId + "/status/")
          .set("Bill Paid");
      }

      // increase the paid amount

      var useRef = firebase
        .database()
        .ref(`poRequests/${request.OrderId}/balances`);

      let current_paid_amount = amountToUpdate;

      useRef
        .once("value", function (snapshot) {
          console.log(snapshot.val(), "snaoshot vallue");
          // write the code here
          var data = snapshot.val();

          if (!data.hasOwnProperty("paidAmount")) {
            // write the code here
          } else {
            current_paid_amount =
              Number(data.paidAmount) + Number(amountToUpdate);
          }
        })
        .then(async () => {
          // write the code here
          await firebase
            .database()
            .ref("poRequests/" + request.OrderId + "/balances/paidAmount/")
            .set(current_paid_amount);
        });

      toast.success("Updated Request successfully");

    } else {
      toast.error("Error updating to Order");
    }
    setPaymentOption((prev) => {
      return {
        ...prev,
        isModalOpen: false,
      };
    });

  };

  function formatDate(timestamp) {
    var date = new Date(timestamp);

    // Extract the day, month, and year from the date object
    var day = date.getDate();
    var month = date.getMonth() + 1; // Month is zero-based, so we add 1
    var year = date.getFullYear();

    // Pad day and month with leading zeroes if they are single digits
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    // Return the formatted date string in dd/mm/yyyy format
    return day + "/" + month + "/" + year;
  }

  const updateTheDataToTheFirebase = async (status) => {
    console.log("here we are going to write the things...");
    firebase
      .database()
      .ref(
        "poRequests/" + request.OrderId + "/" + request.RequestId + "/status/"
      )
      .set(status);
  };

  const commentHandler = async () => {
    let lastTimeStamp = Object.keys(History)[Object.keys(History).length - 1];
    let newTimeStamp = new Date().getTime();

    if (newTimeStamp <= parseInt(lastTimeStamp)) {
      newTimeStamp = lastTimeStamp + 111111;
    }

    let dataObjectToUpdate = {
      History: {
        ...History,
        [newTimeStamp]: {
          remarks: commenttoUpdate,
          raised: Object.values(History)[0].raised || "", //same all the time
          status:
            actionModal.type === "Decline"
              ? "Declined"
              : actionModal.type === "Hold"
                ? "On hold"
                : "Approved",
          date: new Date().getTime(),
          userId: userContext.user.email, //  email of current  updating user
          By: userContext.user.email.slice(
            0,
            userContext.user.email.indexOf("@")
          ),
        },
      },
      Status:
        actionModal.type === "Decline"
          ? "Declined"
          : actionModal.type === "Hold"
            ? "On hold"
            : "Approved",
      details: {
        milestoneIndexRequested,
        ...(actionModal.type === "Approve" && {
          ApprovedOn: new Date().getTime(),
        }),
      },
      priority: priorityOfRequest,
    };

    setFinRequestsData((prev) => {
      let temp = [...prev];
      // console.log("rpev", prev);
      if (temp[rIndex].uuid === request.uuid) {
        // console.log("got the rIndex ", temp[projectkey]);
        temp[rIndex].History = JSON.stringify(dataObjectToUpdate.History);
        temp[rIndex].details = JSON.stringify(dataObjectToUpdate.details);
        temp[rIndex].Status =
          actionModal.type === "Decline"
            ? "Declined"
            : actionModal.type === "Hold"
              ? "On hold"
              : "Approved";
        temp[rIndex].priority = priorityOfRequest;
      }
      return temp;
    });

    const notificationService = new NotificationService();
    notificationService.financeNotifications(
      userContext.user.Firm,
      {
        message: `Request for payment of Rs.${request.AmountRequested} was ${actionModal.type === "Decline"
          ? "Declined"
          : actionModal.type === "Hold"
            ? "Kept on hold"
            : "Approved"
          } in ${Object.values(JSON.parse(request.ProjectDetails))[0]} `,
        Status: "NotRead",
        sectionSource: "Request",
        user: userContext.user.email,
        accountType: Object.values(History)[0].accountType,
        requestId: request.uuid,
        time: new Date().getTime(),
      },
      Object.values(History)[0].accountType
    );
    const opportunityService = new OpportunityService();
    const updateresponse = opportunityService.updateFinanceRequestByuuId(
      request.uuid,
      dataObjectToUpdate
    );

    // console.log("updated redponse", updateresponse);

    toast.promise(updateresponse, {
      loading: "Updating",
      success: "Updated Successfully",
      error: "Error Updating",
    });

    if (actionModal.type === "Decline") {
      updateTheDataToTheFirebase("Bill Declined");
    } else if (actionModal.type === "Hold") {
      updateTheDataToTheFirebase("Bill On hold");
    } else {
      updateTheDataToTheFirebase("Bill Approved");
    }

    // updateresponse.
    // if (updateresponse.status === 200) {
    //   toast.success("Updated Successfully");
    // }
  };

  const checkVendorSign = (actionClicked) => {
    if (!request.vendorSignature) {
      //   toast.error("Vendor has not signed yet");
      setNoVendorSignProceedModal({ modalOpen: true, type: actionClicked });
      // return;
    } else {
      // vendor has signed the po
      if (actionClicked === "Update") {
        setPaymentOption({
          isModalOpen: true,
          isRequest: false,
          isFinanceRequest: true,
        });
        return;
      }
      // else open comment box modal
      setActionModal({
        modalOpen: true,
        type: actionClicked,
      });
    }
  };

  const calculatePaidAmount = () => {
    return (
      (CommonMilestones
        ? CommonMilestones.reduce((totalReceived, item) => {
          if (item.name !== "Paid" && item.payments) {
            const paymentSum = item.payments.reduce(
              (milestoneTotal, milestonePayment) => {
                return (milestoneTotal += Number(milestonePayment.amount));
              },
              0
            );
            totalReceived += paymentSum;
          }
          return totalReceived;
        }, 0)
        : 0) +
      // console.log("received total")
      (CommonMilestones && CommonMilestones[0]?.name === "Paid"
        ? CommonMilestones[0].totalAmount
        : 0)
    );
  };

  const paidAmount = useMemo(() => calculatePaidAmount(), []);

  // const calculateReceivedAmount = CommonMilestones => {
  //   const parsedcommonMilestones = JSON.parse(CommonMilestones);
  //   // if (parsedcommonMilestones && parsedcommonMilestones[0].name === 'Paid') {
  //   //   // console.log(parsedcommonMilestones[0].totalAmount);
  //   // }
  //   return (
  //     (parsedcommonMilestones
  //       ? parsedcommonMilestones.reduce((totalReceived, item) => {
  //           if (item.name !== 'Paid' && item.payments) {
  //             const paymentSum = item.payments.reduce(
  //               (milestoneTotal, milestonePayment) => {
  //                 return (milestoneTotal += Number(milestonePayment.amount));
  //               },
  //               0,
  //             );
  //             totalReceived += paymentSum;
  //           }
  //           return totalReceived;
  //         }, 0)
  //       : 0) +
  //     (parsedcommonMilestones && parsedcommonMilestones[0].name === 'Paid'
  //       ? parsedcommonMilestones[0].totalAmount
  //       : 0)
  //   );
  // };

  // const receivedAmount = useMemo(
  //   () => calculateReceivedAmount(CommonMilestones),
  //   [CommonMilestones],
  // );

  return (

    <div tabIndex="0" className="card-container" id={request.uuid}>
      <div className="req-card-mainDetailsContainer">
        <div className="checkbox-container">
          {!isRecord && latestEDIT.status === "Approved" && (
            <input
              disabled={!request.OrderTotalAmount}
              type="checkbox"
              checked={selectedRequests[request.uuid] ? true : false}
              id="req-card-checkbox"
              onChange={() => {
                // setSelectedRequests((prev)=>{
                // })
                handleCheckboxChange(request);
              }}
            />
          )}
        </div>

        <div className="req-card-visibleContainer">

          <div className="req-card-header">
            <p>Site</p>
            <p>Category</p>
            <p>Vendor/Company</p>
            <p>Status</p>
            <p>PO Amount</p>
            <p>Requested</p>
          </div>

          <div className="req-card-mainDetails">

            <p>{Object.values(JSON.parse(request.ProjectDetails))[0]}</p>
            <p>{request.Category || "\u2013\u2013"}</p>

            <p>

              {request.VendorName || "\u2013\u2013"}

              <span>
                <IconButton
                  disabled={
                    !request.VendorName ||
                    !request.VendorMobile ||
                    request.VendorMobile === "Open Vendor"
                  }
                  style={{ padding: 0, paddingLeft: "4px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowVendorStatement(true);
                  }}
                >
                  <Tooltip title="View Statement">
                    <AiOutlineEye size={12} />
                  </Tooltip>
                </IconButton>
              </span>
              <br />
              {request.vendorCompanyName == null || request.vendorCompanyName == "undefined" || request.vendorCompanyName == undefined ? '' : JSON.parse(request.vendorCompanyName).vendorCompanyName}
            </p>
            <p
              style={{
                color: `${latestEDIT.status === "Approved"
                  ? "#117539"
                  : latestEDIT.status === "Declined"
                    ? "#C90000"
                    : "steelblue"
                  }`,
                fontWeight: "700",
              }}
              className="status-req-order"
            >
              {latestEDIT.status}
              {request.priority && <span> ({request?.priority})</span>}
              <br></br>
              <span style={{ fontSize: 10, color: "black", fontWeight: 400 }}>
                {formatDate(latestEDIT.date)}
              </span>
            </p>
            <p>{parseInt(request.OrderTotalAmount) + "/-" || "\u2013\u2013"}</p>
            <p style={{ fontWeight: "700" }}>
              {request.AmountRequested || "--"}
              {"/- "}
            </p>
          </div>
        </div>

        <div className="req-card-main-actionContainer">
          {!isRecord &&
            (latestEDIT.status === "Approved" &&
              userContext.user.accountType !== "Project" ? (
              <>
                <button
                  disabled={!request.OrderTotalAmount}
                  onClick={async () => {
                    // console.log("update modal");
                    // setPaymentModal(true);
                    checkVendorSign("Update");
                    // setPaymentOption({
                    //   isModalOpen: true,
                    //   isRequest: false,
                    //   isFinanceRequest: true,
                    // });
                    // await updateinOrder(request.OrderId);
                  }}
                  className="req-card-update-btn req-card-btn"
                >
                  Update
                </button>
                {!request.OrderTotalAmount && ( //the Order details will be null if order is unreleased
                  <p
                    style={{
                      color: "red",
                      fontSize: "x-small",
                      fontWeight: "700",
                    }}
                  >
                    Order Unreleased
                  </p>
                )}
              </>
            ) : (
              (userContext.user.accountType === "Admin" ||
                userContext.user.accountType === "Owner") && (
                <>
                  <button
                    onClick={async () => {
                      // if (!request.VendorSignature) {
                      //   toast.error("Vendor has not signed yet");
                      //   setNoVendorSignProceedModal(true);
                      //   return;
                      // }
                      checkVendorSign("Approve");
                      // setActionModal({ modalOpen: true, type: "Approve" });
                    }}
                    className="req-card-approve-btn req-card-btn"
                  >
                    Approve
                  </button>
                </>
              )
            ))}
        </div>

        <div className="reqeust-menu-items">
          <IconButton
            className="view-po-link-fin-req"
            onClick={(e) => {
              e.stopPropagation();

              // const win = window.open(`/PO/${request.OrderId}`, "_blank");
              // win.FromFinanceRequestAdmin = true;
              // win.focus();

              const url =
                environment === "staging"
                  ? `https://staartstaging.netlify.app/?po_id=${request.OrderId}&finApproval=true`
                  : `https://staartbuild.netlify.app/?po_id=${request.OrderId}&finApproval=true`;

              const win = window.open(url, "_blank");

              win.focus();
            }}
          >
            <Tooltip title="View PO">
              <AiOutlineEye size={20} />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={async () => {
              deleteRequestConfirmation(request.uuid, request.OrderId, request.RequestId);
              // console.log(JSON.parse(request.CommonMilestones)[parseInt(JSON.parse(request.details)["milestoneIndexRequested"])])
            }}
          >
            <Tooltip title="Delete">
              <MdDeleteOutline color={"red"} size={20} />
            </Tooltip>
          </IconButton>
        </div>
        <div className="req-card-toggle">
          {showDetails ? (
            <IconButton
              onClick={() => {
                setShowDetails((status) => !status);
              }}
            >
              <FiChevronUp size={24} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setShowDetails((status) => !status);
              }}
            >
              <FiChevronDown size={24} />
            </IconButton>
          )}
        </div>
      </div>

      {showDetails && (
        <div className="req-card-extraDetailsContainer">
          <div
            className="req-card-visibleContainer mainExtraDetailsDiv "
            style={{ width: "100%" }}
          >
            <div className="req-card-header">
              <p>Requested By</p>
              <p>Requested On</p>
              <p>Vendor Mobile</p>
              <p></p>
              <p>Paid Amount</p>
            </div>
            <div className="req-card-mainDetails">
              <p>{Object.values(History)[0].By}</p>

              <p>
                {new Date(request.DOFReq).toLocaleDateString("en-IN")}
                {latestEDIT.status === "Requested" && (
                  <span> ({calculateDate(request.DOFReq)}days)</span>
                )}
              </p>
              <p>{request.VendorMobile || "\u2013\u2013"}</p>
              {/* \u2013 code for - */}

              <p></p>

              <p>{paidAmount || "\u2013\u2013"}</p>
            </div>
          </div>
          {/* <div className="reqeust-menu-items">
            <button
              className="view-po-link-fin-req"
              onClick={(e) => {
                e.stopPropagation();
                const win = window.open(`/PO/${request.OrderId}`, "_blank");
                win.FromFinanceRequestAdmin = true;
                win.focus();
              }}
            >
              View PO
            </button>
            <IconButton
              onClick={() => {
                console.log("delete button click");
                deleteRequestConfirmation(request.uuid);
              }}
            >
              <Tooltip title="Delete">
                <MdDeleteOutline color={"red"} size={20} />
              </Tooltip>
            </IconButton>
          </div> */}
        </div>
      )}
      {/* <div className="reqeust-menu-items">
        <button
          className="view-po-link-fin-req"
          onClick={(e) => {
            e.stopPropagation();
            const win = window.open(`/PO/${request.OrderId}`, "_blank");
            win.FromFinanceRequestAdmin = true;
            win.focus();
          }}
        >
          View PO
        </button>
        <IconButton
          onClick={() => {
            console.log("delete button clicke");
            deleteRequestConfirmation(request.uuid);
          }}
        >
          <Tooltip title="Delete">
            <MdDeleteOutline color={"red"} size={20} />
          </Tooltip>
        </IconButton>
      </div> */}
      {showDetails && (
        <div>
          <div className="req-card-commentDetailsContainer">
            <div>
              <div>
                <h4>Comment:</h4>
                {/* {latestEDIT.By} */}
                {latestEDIT.remarks && (
                  <p>
                    <span style={{ textTransform: "capitalize" }}>
                      {latestEDIT.By}:
                    </span>
                    {latestEDIT?.remarks
                      .replace(/<single-quote>/g, "'")
                      .replace(/<double-quote>/g, '"')
                      .replace(/<rupee-symbol>/g, "₹")
                      .replace(/<new-line>/g, "\n")}
                  </p>
                )}
              </div>
              <div>
                <p>
                  Status:
                  <span
                    style={{
                      color: `${latestEDIT.status === "Approved"
                        ? "green"
                        : latestEDIT.status === "Declined"
                          ? "red"
                          : "steelblue"
                        }`,
                    }}
                    className="status-req-order"
                  >
                    {latestEDIT.status}
                    {" by " + latestEDIT.By + " on "}
                    {getDate(latestEDIT.date)}
                  </span>
                </p>
                {isRecord && (
                  <>
                    <p>
                      <span>Paid On: </span>
                      {getDate(JSON.parse(request?.details)?.paidOn) || "-"} |
                      {getTime(JSON.parse(request?.details)?.paidOn) || "-"}
                    </p>
                    <p>
                      <span>UpdatedOn: </span>
                      {getDate(JSON.parse(request?.details)?.UpdatedOn) ||
                        "-"}{" "}
                      |{getTime(JSON.parse(request?.details)?.UpdatedOn) || "-"}
                    </p>
                  </>
                )}
              </div>
            </div>

            {!isRecord && (
              <div
                className="req-card-action-btns-container"
                style={{ width: "45%" }}
              >
                {latestEDIT.status !== "Approved" &&
                  userContext.user.accountType !== "Project" &&
                  (userContext.user.accountType === "Admin" ||
                    userContext.user.accountType === "Owner") && (
                    // <div
                    //   className="req-card-action-btns-container"
                    //   // style={{
                    //   //   display: "flex",
                    //   //   justifyContent: "space-between",
                    //   //   width: "100%",
                    //   // }}
                    // >
                    <>
                      <button
                        onClick={() => {
                          // setActionModal({ modalOpen: true, type: "Hold" });
                          checkVendorSign("Hold");
                        }}
                        className="req-card-btn req-card-hold-btn"
                      >
                        Mark as Hold
                      </button>
                      <button
                        onClick={() => {
                          // setActionModal({
                          //   modalOpen: true,
                          //   type: "Decline",
                          // });
                          checkVendorSign("Decline");
                        }}
                        className="req-card-btn req-card-decline-btn"
                      >
                        Decline
                      </button>
                      {/* <button
                        onClick={async () => {
                          // if (!request.VendorSignature) {
                          //   toast.error("Vendor has not signed yet");
                          //   setNoVendorSignProceedModal(true);
                          //   return;
                          // }
                          checkVendorSign("Approve");
                          // setActionModal({ modalOpen: true, type: "Approve" });
                        }}
                        className="approval-approve-btn"
                      >
                        Approve
                      </button> */}
                    </>
                  )}
              </div>
            )}
          </div>

          <p
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              setShowHistory((status) => !status);
            }}
          >
            <FaHistory />
            Request History
            {showHistory ? <FiChevronUp /> : <FiChevronDown />}
          </p>

          {showHistory &&
            Object.values(History).map((hItem, hIndex) => {
              return (
                <div
                  className={`request-history-item ${userContext.user.email === hItem.userId
                    ? "same-user"
                    : "diff-user"
                    }`}
                  key={hIndex}
                >
                  <div
                    className="request-history-item-details"
                    style={{
                      border: "1px solid",
                      borderColor: `${hItem.status == "inApproval"
                        ? "#FDD34D"
                        : hItem.status == "Hold"
                          ? "#000000"
                          : hItem.status == "Approved"
                            ? "#43A047"
                            : "#FF4848"
                        }`,
                    }}
                  >
                    <p>
                      <span
                        style={{
                          color:
                            hItem.status == "Requested"
                              ? "#000000"
                              : hItem.status == "On Hold"
                                ? "grey"
                                : hItem.status == "Approved"
                                  ? "#43A047"
                                  : "#FF4848",
                        }}
                      >
                        {hItem.status} by :
                      </span>
                      {hItem.By}
                    </p>
                    <p>

                      {hItem?.remarks
                        ?.replace(/<single-quote>/g, "'")
                        .replace(/<double-quote>/g, '"')
                        .replace(/<rupee-symbol>/g, "₹")
                        .replace(/<new-line>/g, "\n")}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontSize: ".8em",
                      }}
                    >
                      <p style={{ fontWeight: "bold" }}>{hItem.By}</p>
                      <p>
                        {getDate(hItem.date)} | {getTime(hItem.date)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}


      {actionModal.modalOpen && (
        <div>
          <Modal
            open={actionModal.modalOpen}
            onClose={() => {
              setActionModal((prev) => {
                return {
                  ...prev,
                  modalOpen: false,
                };
              });
            }}
            // maxWidth="sm"

            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

          // fullWidth={true}
          >
            <Box
              className="chat-box-approval"
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                width: "60%",
                maxHeight: "90%",
                maxWidth: "70%",
              }}
            >
              <div>
                <div className="comment-decline-header">
                  <h3>Comment & {actionModal.type}</h3>
                </div>
                <div className="fin-req-comment-decline-body">
                  <RemarkTextBox
                    rows="8"
                    cols="80"
                    // value={
                    //   JSON.parse(selectedOrderToChange.item.approvalDetails)
                    //     ?.comment
                    // }
                    onUpdate={(e) => {
                      console.log("e", e.target.value);
                      let value = e.currentTarget.value;
                      value = value?.replace(/'/g, "<single-quote>");
                      value = value?.replace(/"/g, "<double-quote>");
                      value = value?.replace(/₹/g, "<rupee-symbol>");
                      value = value.replace(/\n/g, "<new-line>");
                      setComment(value);
                    }}
                    style={{ fontSize: "1.2rem", padding: "0.4em" }}
                  />
                  <DropDownMUI
                    custStyles={{ marginTop: "1em", width: "300px" }}
                    placeholder={"Priority"}
                    disabeSorting={true}
                    data={["P1", "P2", "P3"]}
                    value={priorityOfRequest}
                    onChange={(value) => {
                      setPriorityOfRequest(value);
                      // setProjectFilterName(value);
                    }}
                    size={"small"}
                  // custStyles={{ width: "340px" }}
                  />
                </div>
                <div className="comment-decline-bottom">
                  <button
                    onClick={async () => {
                      // setSpinner(true);
                      // const objUpdated = await commentHandler(
                      //   selectedOrderToChange.item.OrderId,
                      //   selectedOrderToChange.item,
                      //   commenttoUpdate,
                      //   actionModal.type
                      // );
                      setActionModal((prev) => {
                        return {
                          ...prev,
                          modalOpen: false,
                        };
                      });
                      await commentHandler();
                      // setApprovalOrders((prev) => {
                      //   if (
                      //     prev[selectedOrderToChange.indexToUpdate].OrderId ===
                      //     selectedOrderToChange.item.OrderId
                      //   ) {
                      //     prev[
                      //       selectedOrderToChange.indexToUpdate
                      //     ].requestHistory = JSON.stringify(objUpdated);
                      //   }
                      //   return prev;
                      // });

                      // setSpinner(false);
                    }}
                    className="vendor-sign-btn sign-accept"
                  >
                    Comment
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}

      {/* {paymentModal && (
        <div>
          <Modal
            open={paymentModal}
            onClose={() => {
              
             setPaymentModal(false)
            }}
            // maxWidth="sm"

            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"

            // fullWidth={true}
          >
            <Box
              className="chat-box-approval"
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                bgcolor: "background.paper",
                borderRadius: "10px",
                boxShadow: 24,
                p: 4,
                width: "60%",
                maxHeight: "90%",
                maxWidth: "70%",
              }}
            >
              Payment Modsl
            </Box>
          </Modal>
        </div>
      )} */}

      {noVendorSignProceedModal.modalOpen && (
        <VendorSignModal
          noVendorSignProceedModal={noVendorSignProceedModal}
          setActionModal={setActionModal}
          setNoVendorSignProceedModal={setNoVendorSignProceedModal}
          setPaymentOption={setPaymentOption}
        />
      )}

      {paymentOption.isModalOpen && (
        <PaymentOption
          // cashPay={cashPay}
          // setCashPay={setCashPay}
          // vendorMob={vendorData}
          paymentOption={paymentOption}
          setPaymentOption={setPaymentOption}
          totalOrderAmount={request.OrderTotalAmount}
          totalPaidAmount={
            // " 3023"
            CommonMilestones.length > 0 &&
            (milestoneIndexRequested != undefined &&
              "payments" in CommonMilestones[milestoneIndexRequested]
              ? CommonMilestones[milestoneIndexRequested].payments.reduce(
                (total, item) => (total = total + Number(item.amount)),
                0
              )
              : 0
            ).toFixed(2)
          }
          totalRemainingAmount={
            // "32323"
            CommonMilestones.length > 0 &&
            (
              request.OrderTotalAmount -
              (milestoneIndexRequested != undefined &&
                "payments" in CommonMilestones[milestoneIndexRequested]
                ? CommonMilestones[milestoneIndexRequested].payments.reduce(
                  (total, item) => (total = total + Number(item.amount)),
                  0
                )
                : 0)
            ).toFixed(2)
          }
          requestedAmount={request.AmountRequested}
          fromFinanceReqApprovals={true}
          PayFromFinanceApprovals={updateinOrder}
        />
      )}
      {showVendorStatement && (
        <VendorAccStatement
          showVendorStatement={showVendorStatement}
          setShowVendorStatement={setShowVendorStatement}
          selectedVendorDetails={{
            Name: request.VendorName,
            Mobile: request.VendorMobile,
          }}
        />
      )}
    </div>
  );
};
export default Req_Rec_Card;
