import { FaHistory } from "react-icons/fa";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
//styles are written in approval
const RequestHistory = ({
  requestHistoryModal,
  setRequestHistoryModal,
  selectedOrder,
}) => {
  const userContext = useContext(UserContext);
  return (
    <Modal
      open={requestHistoryModal}
      onClose={() => {
        setRequestHistoryModal(false);
      }}
      // maxWidth="sm"

      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

      // fullWidth={true}
    >
      <Box
        className="req-box-approval"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxHeight: "90%",
          maxWidth: "80%",
        }}
      >
        <div className="request-history-modal-container">
          <div className="request-history-header">
            <FaHistory />
            Request History
          </div>
          <div className="request-history-details">
            {Object.values(JSON.parse(selectedOrder.item.requestHistory)).map(
              (rItem, rIndex) => {
                return (
                  <div
                    className={`request-history-item ${
                      userContext.user.email === rItem.userId
                        ? "same-user"
                        : "diff-user"
                    }`}
                    key={rIndex}
                  >
                    <div
                      className="request-history-item-details"
                      style={{
                        border: "1px solid",
                        borderColor: `${
                          rItem.status == "inApproval"
                            ? "#FDD34D"
                            : rItem.status == "Hold"
                            ? "#000000"
                            : rItem.status == "Approved"
                            ? "#43A047"
                            : "#FF4848"
                        }`,
                      }}
                    >
                      <p>
                        <span
                          style={{
                            color:
                              rItem.status == "Requested"
                                ? "#000000"
                                : rItem.status == "On Hold"
                                ? "grey"
                                : rItem.status == "Approved"
                                ? "#43A047"
                                : "#FF4848",
                          }}
                        >
                          {rItem.status} by :
                        </span>
                        {rItem.userId}
                      </p>
                      <p>Comments: {rItem.comments}</p>
                      <p style={{ textAlign: "end", fontSize: ".8em" }}>
                        {rItem.date} | {rItem.time}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default RequestHistory;
