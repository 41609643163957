import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import AuthService from "../../api/authService";
import "./BrandsEdit.scss";
import toast from "react-hot-toast";
import { CircularProgress } from "@material-ui/core";

const BrandsEdit = ({
  editBrandsofVendorOrMaterial,
  setEditBrandsofVendorOrMaterial,
  setWorkMaterialCategories,
  setMaterialCategories,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const MaterialCategoryUpdate = async () => {
    // console.log('updating the material Category');
    setMaterialCategories((prev) => {
      prev[editBrandsofVendorOrMaterial.dataIndex].Brands =
        JSON.stringify(Brands);
      // return [...prev ,{...editBrandsofVendorOrMaterial.data , Brands:JSON.stringify(Brands)} ]
      return prev;
    });
    const authService = new AuthService();
    const response = await authService.UpdateMaterialCategoryBrands(
      editBrandsofVendorOrMaterial.data.name,
      Brands
    );
    return response;
  };
  const VendorCategoryUpdate = async () => {
    // console.log('updating the VEndor Category');
    setWorkMaterialCategories((prev) => {
      prev[editBrandsofVendorOrMaterial.dataIndex].Brands =
        JSON.stringify(Brands);
      return prev;
    });
    const authService = new AuthService();
    const response = await authService.UpdateVendorCategoryBrands(
      editBrandsofVendorOrMaterial.data.Category,
      Brands
    );
    return response;
  };

  const UpdateBtnHandler = async () => {
    // console.log('update');
    setIsUpdating(true);
    const response = editBrandsofVendorOrMaterial.isMaterialCategory
      ? await MaterialCategoryUpdate()
      : await VendorCategoryUpdate();
    setIsUpdating(false);

    if (response.status == "200") {
      toast.success("Updated Successfully");
    } else {
      toast.error("Error while updating");
    }
    setEditBrandsofVendorOrMaterial((prev) => {
      return { ...prev, isModalOpen: false };
    });
  };

  const [newlyEnteredBrandValue, setNewlyEnteredBrandValue] = useState("");
  const [Brands, setBrands] = useState(
    JSON.parse(editBrandsofVendorOrMaterial.data.Brands) || []
  );

  const handleDeleteBrandItem = (brandName, index) => {
    // Brands()
    console.log("brand to delete, ", brandName, index);
    setBrands((prev) => {
      let arr = prev.filter((item, BIndex) => BIndex !== index);
      console.log(arr);
      return arr;
    });
    editBrandsofVendorOrMaterial.isMaterialCategory
      ? setMaterialCategories((prev) => {
          prev[editBrandsofVendorOrMaterial.dataIndex].Brands =
            JSON.stringify(Brands);
          // return [...prev ,{...editBrandsofVendorOrMaterial.data , Brands:JSON.stringify(Brands)} ]
          return prev;
        })
      : setWorkMaterialCategories((prev) => {
          prev[editBrandsofVendorOrMaterial.dataIndex].Brands =
            JSON.stringify(Brands);
          return prev;
        });
  };

  return (
    <Modal
      sx={{ display: "flex" }}
      open={editBrandsofVendorOrMaterial.isModalOpen}
      onClose={() =>
        setEditBrandsofVendorOrMaterial((prev) => {
          return { ...prev, isModalOpen: false };
        })
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          padding: "10px 32px",
          height: "40%",
          minWidth: "35%",
          maxHeight: "60%",
          maxWidth: "50%",
        }}
      >
        <div className="brands-edit-container">
          <div className="brands-edit-heading">
            Brands
            <h2>
              {editBrandsofVendorOrMaterial.isMaterialCategory
                ? editBrandsofVendorOrMaterial.data.name
                : editBrandsofVendorOrMaterial.data.Category}
            </h2>
            <div className="brands-edit-brandslist">
              {Brands.map((brand, index) => (
                <Chip
                  onDelete={() => handleDeleteBrandItem(brand, index)}
                  style={{ margin: "0.6em 0.2em" }}
                  key={index}
                  label={brand}
                />
              ))}
            </div>
          </div>
          <div className="brands-edit-brand-input">
            <input
              type="text"
              value={newlyEnteredBrandValue}
              onKeyDown={(e) => {
                if (e.which === 32) return false;
              }}
              onChange={(e) => {
                setNewlyEnteredBrandValue(e.target.value.replace(/\s/g, ""));
              }}
            />
            <button
              onClick={() => {
                if (newlyEnteredBrandValue.length !== 0) {
                  setBrands((prev) => [...prev, newlyEnteredBrandValue]);
                  setNewlyEnteredBrandValue("");
                }
              }}
            >
              Add
            </button>
          </div>
          <div className="brands-edit-bottom">
            <button
              className="brands-update-button"
              onClick={() => UpdateBtnHandler()}
              disabled={isUpdating ? true : false}
            >
              Update
              {isUpdating && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    color: "rgb(70 155 240)",
                  }}
                />
              )}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default BrandsEdit;
