import { useEffect, useState } from "react";
import OpportunityService from "../../api/opportunityService";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Req_Rec_Card from "../FinanceReqRec/Req_Rec_Card";

export const getFinanceApprovalsData = async (OrderId) => {
  const opportunityService = new OpportunityService();
  const response = await opportunityService.getFinanceApprovalByOrderId(
    OrderId
  );
  if (response.results) {
    // setReqRecData(response.results);
    return response.results;
  }
};

const RequestedPayments = ({
  OrderId,
  paymentRequestedModal,
  setPaymentsRequestedModal,
}) => {
  const [reqRecData, setReqRecData] = useState([]);

  useEffect(() => {
    const financeApprovalsData = getFinanceApprovalsData(OrderId);
    financeApprovalsData && setReqRecData(financeApprovalsData);
  }, []);

  return (
    <Modal
      sx={{}}
      open={paymentRequestedModal}
      onClose={() => setPaymentsRequestedModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="transactionDetailsModal"
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          minWidth: "80%",
          maxHeight: "90%",
          maxWidth: "80%",
        }}
      >
        <div>
          <div>
            <h4 style={{ textAlign: "center" }}>Requests and Records</h4>
          </div>
          {reqRecData.length > 0 ? (
            reqRecData.map((request, rIndex) => (
              <Req_Rec_Card
                //   projectkey={project}
                key={rIndex}
                request={request}
                index={rIndex}
                isRecord={true}
                // setFinRequestsData={setReqRecData}
              />
            ))
          ) : (
            <h5 style={{ textAlign: "center" }}>No Records or Requests</h5>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default RequestedPayments;
