import { useEffect, useState, useContext, useRef } from "react";
import "./unreleasedItems.scss";
import { Tooltip, DropDownMUI, Button } from "../../components";
import { FiTruck } from "react-icons/fi";
import { GrUserWorker } from "react-icons/gr";
import { AiOutlineSearch } from "react-icons/ai";
import getCategoriesUnreleasedItems from "../../helpers/getCategoriesUnreleasedItems";
import getWorksMaterials from "../../helpers/getWorksMaterials";
import DraftService from "../../api/draftService";
import { UserContext } from "../../Context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import drawerTemplate1 from "../../hoc/drawerTemplate1";
import { useAnalyticsContext } from "../../Context/Analytics";
import { toast } from "react-toast";
import getVendorPrice from "../../helpers/getVendorPrice";
import getComponentsPrice from "../../helpers/getComponentsPrice";
import { saveWorkButtonClicked } from "./helper";
import { BiUpArrowAlt } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import firebase from "../../config/firebase";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Approvals } from "./approvals";
import config from "../../config/config";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const UnreleasedItems = (props) => {

  const highLightbrandRef = useRef(null);
  const draftService = new DraftService();
  const { record } = useAnalyticsContext();

  const location = useLocation();
  const data = location.state.data;

  const history = useHistory();

  const userContext = useContext(UserContext);
  const accountType = userContext.user.accountType;

  const [activeMainTab, setActiveMainTab] = useState(1);

  const [spinner, setSpinner] = useState(false);
  const [displayPurchaseAmount, setDisplayPurchaseAmount] = useState(true);


  const [selectedWorks, setSelectedWorks] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);

  const [revision, setRevision] = useState(data.rooms);
  const [categories, setCategories] = useState(
    getCategoriesUnreleasedItems(data)
  );
  const [activeCategory, setActiveCategory] = useState("All");
  const [materials, setMaterials] = useState([]);
  const [works, setWorks] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchItems, setSearchItems] = useState("");
  const [workType, setWorkType] = useState("All");
  const [showMore, setShowMore] = useState([]);
  const [projectDetails, setProjectDetails] = useState();
  const [approvalOrders, setApprovalOrders] = useState([]);

  const _tempClientName = sessionStorage.getItem("clientName");

  useEffect(() => {
    if (data) {
      // setRevision(data.rooms);
      setCategories(getCategoriesUnreleasedItems(data));
    }
  }, [data]);

  useEffect(() => {
    if (activeCategory) {
      setCheckedItems([]);
      const { Work, Material } = getWorksMaterials({ rooms: revision });
      const works = Work;
      const materials = Material;
      setWorks(works);
      setMaterials(materials);
    }
  }, [revision]);


  useEffect(() => {
    let ignore = false;

    if (!ignore) fetchApprovalOrders();

    return () => {
      ignore = true;
    };
  }, []);

  const fetchApprovalOrders = async () => {
    setSpinner(true);
    await fetch(`${config.orderService}getApprovalOrders/${data.ProjectId}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InVuaXdvcmtzIiwiYWRtaW5JZCI6NiwiZW1haWwiOiJhZG1pbkB1bml3b3Jrc2Rlc2lnbnMuY29tIiwicGFzc3dvcmQiOiJiYzc4ZTU4ZDU1Y2RlMTM0NmU2OGY4ZTVmZTU4OGRlZGY2MmZhNDU3YWE2NDZhNTAwYTUzMzQ3ZmFmZjZlZTI0IiwiYWNjb3VudFR5cGUiOiJBZG1pbiIsIkZpcm0iOiJ1bml3b3JrcyIsImNyZWF0ZWRBdCI6bnVsbCwibGFzdFNpZ25lZEF0IjpudWxsLCJpYXQiOjE2MzI4ODk2Njh9.yHlDqh5npMRslcTaUyVpRPRIv9jyi8jHayw9NfwIdsA",
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        console.log("Response from getApprovalOrders API: ", json);
        setApprovalOrders(json.servicesPayload);
      })
      .catch((e) => {
        console.log("getApproval catch: ", e.toString());
        toast.error("Error fetching orders data!", {
          backgroundColor: "rgb(255, 211, 77)",
          color: "black",
        });
      });

    setSpinner(false);
  };

  useEffect(() => {
    if (data) {
      fetchDrafts();
    }
  }, [data]);

  const [savedDrafts, setSavedDrafts] = useState([]);
  const [draftsOrderId, setDraftsOrderId] = useState([]);

  const fetchDrafts = async () => {
    // const draftService = new DraftService();
    const response = await draftService.getDraft(data.ProjectId);
    console.log(`Response from fetchDrafts API: `, response);
    setSavedDrafts(response.payload);
  };

  useEffect(() => {
    let allOrderIdsDrafts = [];
    savedDrafts.map((draft) =>
      JSON.parse(draft.selectedItems).map((item) =>
        allOrderIdsDrafts.push(item.OrderId)
      )
    );
    setDraftsOrderId(allOrderIdsDrafts);
  }, [savedDrafts]);

  const [backToTop, setBackTotop] = useState(false);

  useEffect(() => {
    document
      .getElementById("container")
      .addEventListener("scroll", function () {
        if (this.scrollTop === 0) {
          setBackTotop(false);
        } else {
          setBackTotop(true);
        }
      });
  }, []);

  useEffect(() => {
    // desc-highlight-brand
    let highlightTimer = setTimeout(() => {
      let textarea = (elem, regex) => {
        for (let i = 0; i < elem.length; i++) {
          elem[i].innerHTML = elem[i].innerHTML.replace(
            regex,
            "<mark>$&</mark>"
          );
        }
      };
      textarea(
        document.querySelectorAll(".desc-highlight-brand"),
        /@[a-zA-Z_]{1,}/g
      );
    }, 0);

    let testing = document.querySelectorAll(".desc-highlight-brand");
    return () => clearTimeout(highlightTimer);
  });

  const backToTopHandler = () => {
    document.getElementById("btn").addEventListener("click", function () {
      document
        .getElementById("container")
        .scroll({ top: 0, behavior: "smooth" });
    });
  };

  const [highlightWorkIndex, setHighlightWorkIndex] = useState();
  const [highlightMaterialIndex, setHighlightMaterialIndex] = useState();

  useEffect(() => {
    if (location.state.state) {
      // console.log("location.state",location.state);
      if (location.state.state.MaterialItem) {
        // console.log('material item',location.state.state.selectedIndex['selectedCategory']);
        setActiveCategory(
          location.state.state.selectedIndex["selectedCategory"]
        );
        setHighlightMaterialIndex(
          location.state.state.selectedIndex.materialIndex
        );
      } else if (location.state.state.workItem) {
        // console.log('work Item')
        setActiveCategory(
          location.state.state.selectedIndex["selectedCategory"]
        );
        setHighlightWorkIndex(location.state.state.selectedIndex.workIndex);
      }
    }
  }, [location]);

  useEffect(() => {
    // console.log('in the useEffect hightlighted',highlightWorkIndex)
    if (highlightWorkIndex != undefined) {
      let highlightingitem = document.getElementById(
        `wIndex ${location.state.state["selectedIndex"]["highLightIndexArr"]} ${highlightWorkIndex}`
      );
      highlightingitem.focus();
      highlightingitem.classList.add("highlightdiv");
    }
    if (highlightMaterialIndex != undefined) {
      // console.log('in the material changed',`mIndex ${location.state.state['selectedIndex']['highLightIndexArr']} ${highlightMaterialIndex}`);
      let highlightingMaterial = document.getElementById(
        `mIndex ${location.state.state["selectedIndex"]["highLightIndexArr"]} ${highlightMaterialIndex}`
      );
      highlightingMaterial.focus();
      highlightingMaterial.classList.add("highlightdiv");
    }
  }, [highlightWorkIndex, highlightMaterialIndex]);

  return (
    <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <div
        className={"mainTabs"}
        style={{ top: 0, width: "100%", margin: "auto", position: "sticky" }}
      >
        <div>
          <div
            className={"boqTab"}
            onClick={() => {
              setActiveMainTab(0);
              history.push("/ProjectPage/" + data.ProjectId);
              record("@boq_tab_click_success", {
                description: "BOQ tab click success",
              });
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 20}}>BOQ</div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
              <div>Rs {(data && getComponentsPrice(data).toFixed(2)) || 0}</div>
            )}
          </div>
          <div className={activeMainTab === 0 ? "active" : ""}></div>
        </div>
        <div>
          <div
            className={"indentTab"}
            onClick={() => {
              if (accountType == "BOQ" || accountType == "Finance") {
                toast.error("You don't have access to Purchase!", {
                  backgroundColor: "red",
                  color: "black",
                });
              } else {
                if (data?.rooms) {
                  setActiveMainTab(1);
                  saveWorkButtonClicked(data, setSpinner);
                } else {
                  toast.error("Empty BOQ. Please add to proceed!", {
                    backgroundColor: "red",
                    color: "black",
                  });
                }
              }
              record("@purchase_tab_click_success", {
                description: "Purchase tab click success",
              });
            }}
          >
            <div style={{ top: 0, width: "100%", margin: "auto", position: "sticky", display:"flex" }}>
              
        
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontWeight: "bold" }}>Purchase</div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (displayPurchaseAmount) {
                      setDisplayPurchaseAmount(false);
                    } else {
                      setDisplayPurchaseAmount(true);
                    }
                  }}
                  style={{ marginLeft: 10, cursor: "pointer" }}
                >
                  {!displayPurchaseAmount ? (
                    <VisibilityOff style={{marginLeft: 1}}/>
                  ) : (
                      <Visibility style={{ marginLeft: 1 }} />
                  )}
                </div>
              </div>

              </div>
            {(accountType == "Admin" ||
              accountType == "Finance" ||
              accountType == "Owner") && (
              <div>
                Rs {(data && !displayPurchaseAmount && getVendorPrice(data).toFixed(2)) || '********'}
              </div>
            )}
            </div>
     
          <div className={activeMainTab === 1 ? "active" : ""}></div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "25px",
          top: 75,
          alignItems: "center",
          position: "sticky",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            cursor: "pointer",
          }}
        >
          {data && (
            <p
              style={{
                fontWeight: "bold",
                marginRight: "20px",
                cursor: "default",
              }}
            >
              {data.clientName ? data.clientName : data.ClientName}
            </p>
          )}
          {/* <p style={{ fontWeight: 'bold', marginRight: '20px', cursor: 'default', }}>{_tempClientName}</p> */}
          <div className={"activeText"}>
            <p>Unreleased Items</p>
            <div className={"activeTab"}></div>
          </div>

          <div
            onClick={() => {
              history.push("/ReleasedOrders/" + data.ProjectId, { data: data });
              record("@releaseorder_tab_click_success", {
                description: "release order tab click success",
              });
            }}
          >
            <p>Released Orders</p>
          </div>
          <div
            onClick={() => {
              history.push("/Timeline/" + data.ProjectId, { data: data });
              record("@timeline_tab_click_success", {
                description: "timeline tab click success",
              });
            }}
          >
            <p>Timeline</p>
          </div>
          {/* <div
          onClick={() => {
            if(accountType == "Admin"){
              history.push("/Approvals/" + data.ProjectId, { 
                data: data
                 
              });
              record("@approvals_tab_click_success", {
                description: "approvals tab click success",
              });
            }
            else{
              toast.error("You Can't Approve Orders", {
                backgroundColor: "red",
                color: "black",
              })
            }
          }}
            >
            <p>Approvals</p>
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 20,
            marginBottom: "5px",
          }}
        >
          <div>
            <Button
              content="Generate PO/ WO"
              onClick={() => {
                history.push("/OrdersQuotations/" + data.ProjectId, {
                
                  data: {
                    ...data,
                    rooms: data.rooms,
                    ProjectId: data.ProjectId,
                    ClientName: data.ClientName,
                    Address: data.Address,
                    AmountRecieved: data.AmountReceived,
                    SiteEngineerId: data.SiteEngineersId,
                  },
                  selectedWorks: selectedWorks,
                  selectedMaterials: selectedMaterials,
                  orderOrQuotation: "OrderButton",
                });
                record("@generate_button_click_success", {
                  description: "generate button click success",
                });
              }}
          
            />
        
          </div>
          <div>
            <Button
              content="Generate WQ/ MQ"
              onClick={() => {
                history.push("/OrdersQuotations/" + data.ProjectId, {
                  data: {
                    ...data,
                    rooms: data.rooms,
                    ProjectId: data.ProjectId,
                    ClientName: data.ClientName,
                    Address: data.Address,
                    AmountRecieved: data.AmountReceived,
                    SiteEngineerId: data.SiteEngineersId,
                  },
                  selectedWorks: selectedWorks,
                  selectedMaterials: selectedMaterials,
                  orderOrQuotation: "QuatationButton",
                });
                record("@generate_button_click_success", {
                  description: "generate button click success",
                });
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <div
          style={{
            border: "1px solid #E1E1E1",
            width: "20%",
            height: "75% ",
            position: "absolute",
          }}
        >
          <p style={{ color: "#231700", margin: "10px", position: "sticky" }}>
            {activeCategory == "All"
              ? works.filter(
                  (work) =>
                    work.status != "ordered" &&
                    work.status != "inApproval" &&
                    work.rate != "quotation"
                ).length +
                materials.filter(
                  (material) =>
                    material.status != "ordered" &&
                    material.status != "inApproval" &&
                    material.rate != "quotation"
                ).length
              : works.filter(
                  (work) =>
                    work.status != "ordered" &&
                    work.status != "inApproval" &&
                    work.rate != "quotation" &&
                    activeCategory == work.vendorCategory
                ).length +
                materials.filter(
                  (material) =>
                    material.status != "ordered" &&
                    material.status != "inApproval" &&
                    material.rate != "quotation" &&
                    activeCategory == material.type
                ).length}
            {" unreleased items"}
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
              position: "sticky",
              top: 40,
            }}
          >
            <p style={{ fontWeight: "bold" }}>Category</p>
            <p style={{ fontWeight: "bold" }}>Amount</p>
          </div>
          <div style={{ height: "85%", width: "100%", overflowY: "scroll" }}>
            <div
              style={
                "ApprovalOrders" == activeCategory
                  ? {
                      backgroundColor: "#FFF8E1",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                setActiveCategory("ApprovalOrders");
                setSelectedWorks([]);
                setSelectedMaterials([]);
              }}
            >
              <p>
                Approval Orders {approvalOrders && `(${approvalOrders.length})`}
              </p>
              <p>
                {"\u20B9 "}
                {approvalOrders &&
                  (approvalOrders.length === 0
                    ? "0"
                    : approvalOrders
                        .reduce((ordersTotal, order) => {
                          return (ordersTotal += Number(
                            order.OrderTotalAmount
                          ));
                        }, 0)
                        .toFixed(2))}
              </p>
            </div>
            <div
              style={
                "All" == activeCategory
                  ? {
                      backgroundColor: "#FFF8E1",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 10,
                      cursor: "pointer",
                    }
              }
              onClick={() => {
                setActiveCategory("All");
                setSelectedWorks([]);
                setSelectedMaterials([]);
              }}
            >
              <p>All</p>
              <p>
                {"\u20B9 "}
                {(
                  works
                    .map(
                      (work) =>
                        work.status != "ordered" &&
                        work.status != "inApproval" &&
                        work.rate != "quotation" &&
                        (
                          work.quantity * work.rate +
                            work.quantity * work.rate * (work.gst / 100) || 0
                        ).toFixed(2)
                    )
                    .filter((item) => item != false)
                    .reduce(
                      (total, item) => (total = Number(total) + Number(item)),
                      0
                    ) +
                  materials
                    .map(
                      (material) =>
                        material.status != "ordered" &&
                        material.status != "inApproval" &&
                        material.rate != "quotation" &&
                        (
                          material.quantity * material.rate +
                            material.quantity *
                              material.rate *
                              (material.gst / 100) || 0
                        ).toFixed(2)
                    )
                    .filter((item) => item != false)
                    .reduce(
                      (total, item) => (total = Number(total) + Number(item)),
                      0
                    )
                ).toFixed(2)}
              </p>
            </div>

            {categories
              .sort((a, b) => a.localeCompare(b))
              .map((category, index) => (
                <div
                  key={index}
                  style={
                    category == activeCategory
                      ? {
                          backgroundColor: "#FFF8E1",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 10,
                          cursor: "pointer",
                        }
                      : {
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: 10,
                          cursor: "pointer",
                        }
                  }
                  onClick={() => {
                    setActiveCategory(category);
                    setCheckedItems([]);
                    setSelectedWorks([]);
                    setSelectedMaterials([]);
                  }}
                >
                  <p>{category}</p>
                  <p>
                    {"\u20B9 "}
                    {(
                      works
                        .map(
                          (work) =>
                            work.status != "ordered" &&
                            work.status != "inApproval" &&
                            work.rate != "quotation" &&
                            category == work.vendorCategory &&
                            (
                              work.quantity * work.rate +
                                work.quantity * work.rate * (work.gst / 100) ||
                              0
                            ).toFixed(2)
                        )
                        .filter((item) => item != false)
                        .reduce(
                          (total, item) =>
                            (total = Number(total) + Number(item)),
                          0
                        ) +
                      materials
                        .map(
                          (material) =>
                            material.status != "ordered" &&
                            material.status != "inApproval" &&
                            material.rate != "quotation" &&
                            category == material.type &&
                            (
                              material.quantity * material.rate +
                                material.quantity *
                                  material.rate *
                                  (material.gst / 100) || 0
                            ).toFixed(2)
                        )
                        .filter((item) => item != false)
                        .reduce(
                          (total, item) =>
                            (total = Number(total) + Number(item)),
                          0
                        )
                    ).toFixed(2)}
                  </p>
                </div>
              ))}
          </div>
        </div>

        <div
          style={{
            width: "80%",
            padding: "0px 10px 0px 10px",
            marginLeft: "21%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <DropDownMUI
                placeholder={"Work Type"}
                data={["All", "Only Work", "Work + Material", "Only Material"]}
                value={workType}
                onChange={(value) => {
                  setWorkType(value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                border: "1px solid #3535354d",
                width: "100%",
                marginBottom: 10,
                borderRadius: 5,
              }}
            >
              <AiOutlineSearch
                color="grey"
                size="20"
                style={{ margin: "0px 10px" }}
              />
              <input
                placeholder="Search by category or description"
                style={{
                  width: "100%",
                  fontSize: "16px",
                  borderWidth: 0,
                  border: "none",
                  outline: "none",
                }}
                value={searchItems}
                onChange={(e) => {
                  setSearchItems(e.currentTarget.value);
                }}
              />
            </div>
          </div>

          {activeCategory != "All" && activeCategory !== "ApprovalOrders" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
                marginBottom: 10,
              }}
            >
              <input
                type="checkbox"
                checked={
                  checkedItems.length > 0 &&
                  (checkedItems.length ==
                    works.filter(
                      (work) =>
                        work.status != "ordered" &&
                        work.status != "inApproval" &&
                        work.rate != "quotation" &&
                        !draftsOrderId.includes(work.OrderId) &&
                        activeCategory == work.vendorCategory
                    ).length ||
                    checkedItems.length ==
                      materials.filter(
                        (material) =>
                          material.status != "ordered" &&
                          material.rate != "quotation" &&
                          !draftsOrderId.includes(material.OrderId) &&
                          activeCategory == material.type
                      ).length)
                }
                style={{ minHeight: "16px", minWidth: "16px" }}
                onChange={(e) => {
                  if (
                    checkedItems.length > 0 &&
                    (checkedItems.length ==
                      works.filter(
                        (work) =>
                          work.status != "ordered" &&
                          work.status != "inApproval" &&
                          work.rate != "quotation" &&
                          !draftsOrderId.includes(work.OrderId) &&
                          activeCategory == work.vendorCategory
                      ).length ||
                      checkedItems.length ==
                        materials.filter(
                          (material) =>
                            material.status != "ordered" &&
                            material.status != "inApproval" &&
                            material.rate != "quotation" &&
                            !draftsOrderId.includes(material.OrderId) &&
                            activeCategory == material.type
                        ).length)
                  ) {
                    setCheckedItems([]);
                    setSelectedWorks([]);
                    setSelectedMaterials([]);
                  } else {
                    works.map(
                      (work, wIndex) =>
                        work.status != "ordered" &&
                        work.status != "inApproval" &&
                        work.rate != "quotation" &&
                        !draftsOrderId.includes(work.OrderId) &&
                        activeCategory == work.vendorCategory &&
                        setCheckedItems((st) => [...st, `w${wIndex}`])
                    );
                    materials.map(
                      (material, mIndex) =>
                        material.status != "ordered" &&
                        material.rate != "quotation" &&
                        !draftsOrderId.includes(material.OrderId) &&
                        activeCategory == material.type &&
                        setCheckedItems((st) => [...st, `m${mIndex}`])
                    );
                    setSelectedWorks(
                      works.filter(
                        (work) =>
                          work.status != "ordered" &&
                          work.status != "inApproval" &&
                          work.rate != "quotation" &&
                          !draftsOrderId.includes(work.OrderId) &&
                          activeCategory == work.vendorCategory
                      )
                    );
                    setSelectedMaterials(
                      materials.filter(
                        (material) =>
                          material.status != "ordered" &&
                          material.status != "inApproval" &&
                          material.rate != "quotation" &&
                          !draftsOrderId.includes(material.OrderId) &&
                          activeCategory == material.type
                      )
                    );
                  }
                }}
              />
              <p>Select All</p>
              <div style={{ paddingLeft: "30px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {selectedWorks.length + selectedMaterials.length} /{" "}
                  {works.filter(
                    (work) =>
                      work.status != "ordered" &&
                      work.status != "inApproval" &&
                      work.rate != "quotation" &&
                      activeCategory == work.vendorCategory &&
                      !draftsOrderId.includes(work.OrderId)
                  ).length +
                    materials.filter(
                      (material) =>
                        material.status != "ordered" &&
                        material.status != "inApproval" &&
                        material.rate != "quotation" &&
                        activeCategory == material.type &&
                        !draftsOrderId.includes(material.OrderId)
                    ).length}
                </span>
                <span style={{ paddingLeft: "10px" }}>Selected</span>
              </div>
            </div>
          )}

          {activeCategory !== "ApprovalOrders" && (
            <div
              style={{
                width: "100%",
                backgroundColor: "#FFF8E1",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                fontWeight: "bold",
                padding: "10px",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#FDD34D",
                borderRadius: "4px",
              }}
            >
              <p style={{ width: "50%" }}>Items</p>
              <p style={{ width: "10%" }}>Quantity</p>
              <p style={{ width: "10%" }}>Unit</p>
              <p style={{ width: "10%" }}>Rate</p>
              <p style={{ width: "10%" }}>GST</p>
              <p style={{ width: "10%" }}>Amount</p>
            </div>
          )}

          <div
            style={{
              position: "fixed",
              height: "55%",
              width: "75%",
              overflowY: "scroll",
              top: 310,
            }}
            id="container"
          >
            {activeCategory === "ApprovalOrders" &&
              (approvalOrders.length >= 1 ? (
                <Approvals
                  approvalOrders={approvalOrders}
                  setApprovalOrders={setApprovalOrders}
                  fetchApprovalOrders={fetchApprovalOrders}
                  setActiveCategory={setActiveCategory}
                />
              ) : (
                <h4 style={{ textAlign: "center" }}>No Orders</h4>
              ))}
            {activeCategory !== "ApprovalOrders" &&
              works.map(
                (work, wIndex) =>
                  work.status != "ordered" &&
                  work.status != "inApproval" &&
                  work.rate != "quotation" &&
                  (activeCategory == work.vendorCategory ||
                    activeCategory == "All") &&
                  (work.vendorCategory
                    ?.toLowerCase()
                    .includes(searchItems?.toLowerCase()) ||
                    work.description
                      ?.toLowerCase()
                      .includes(searchItems?.toLowerCase())) &&
                  (workType == work.workType || workType == "All") && (
                    <div
                      tabIndex="-1"
                      id={`wIndex ${work["indexces"]["componentIndex"]} ${work["indexces"]["workIndex"]}`}
                      key={wIndex}
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        margin: "10px 0px",
                      }}
                    >
                      {activeCategory != "All" &&
                        !draftsOrderId.includes(work.OrderId) && (
                          <Tooltip content="Click to select work">
                            <input
                              type="checkbox"
                              checked={checkedItems.includes(`w${wIndex}`)}
                              style={{ minHeight: "24px", minWidth: "24px" }}
                              onChange={(e) => {
                                setCheckedItems((st) =>
                                  st.includes(`w${wIndex}`)
                                    ? st.filter((x) => x !== `w${wIndex}`)
                                    : [...st, `w${wIndex}`]
                                );
                                setSelectedWorks((st) =>
                                  st.some((x) => x.indexces == work.indexces)
                                    ? st.filter(
                                        (x) => x.indexces != work.indexces
                                      )
                                    : [...st, work]
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 50,
                          width: 50,
                          margin: 20,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: "50%",
                          borderColor: "#FDD34D",
                        }}
                      >
                        {work.workType == "Only Work" ? (
                          <GrUserWorker size={20} />
                        ) : (
                          <>
                            <GrUserWorker size={15} />
                            <FiTruck size={15} />
                          </>
                        )}
                      </div>
                      <div style={{ width: "40%" }}>
                        <div style={{ width: "80%" }}>
                          {draftsOrderId.includes(work.OrderId) && (
                            <p style={{ color: "#4685D4", marginBottom: 5 }}>
                              Already in draft{" "}
                              <span
                                onClick={() => {
                                  console.log("Go to draft");
                                  // const draftService = new DraftService();
                                  if (work?.AssociatedID) {
                                    draftService
                                      .getDraftByDraftId(work?.AssociatedID)
                                      .then((res) => {
                                        console.log(
                                          "response of single draft",
                                          res
                                        );
                                        history.push(
                                          "/OrdersQuotations/" + data.ProjectId,
                                          {
                                            data: {
                                              ...data,
                                              rooms: data.rooms,
                                              ProjectId: data.ProjectId,
                                              ClientName: data.ClientName,
                                              Address: data.Address,
                                              AmountRecieved:
                                                data.AmountReceived,
                                              SiteEngineerId:
                                                data.SiteEngineersId,
                                            },
                                            draft: res.payload[0],
                                          }
                                        );
                                      });
                                  } else {
                                    toast.warn("This is an Old Draft ");
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                (Go to Draft
                                <OpenInNewIcon
                                  style={{ fill: "gray", fontSize: "0.85em" }}
                                  titleAccess="Go to Draft"
                                  fontSize="smaller"
                                />
                                )
                              </span>
                            </p>
                          )}
                          {work.description.length > 100 ? (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{ width: "80%", cursor: "pointer" }}
                                onClick={() => {
                                  // console.log('gng to boq page from the index', work)
                                  history.push(
                                    "/ProjectPage/" + data.ProjectId,
                                    {
                                      data: data,
                                      fromUnreleased: true,
                                      indexArr: work["indexces"],
                                    }
                                  );
                                }}
                              >
                                {showMore.includes(work.description) ? (
                                  <span>
                                    {work["Unit Name"]} |{" "}
                                    <span className="desc-highlight-brand">
                                      {work.description
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')}
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {work["Unit Name"]} |{" "}
                                    <span className="desc-highlight-brand">
                                      {work.description
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')
                                        .substring(0, 100)}
                                    </span>
                                    ....
                                  </span>
                                )}
                              </span>
                              <span>
                                <AiOutlineInfoCircle
                                  title={
                                    showMore.length > 0
                                      ? "showless"
                                      : "showmore"
                                  }
                                  onClick={() => {
                                    if (showMore.includes(work.description)) {
                                      setShowMore(
                                        showMore.filter(
                                          (item) => item !== work.description
                                        )
                                      );
                                    } else {
                                      setShowMore([
                                        ...showMore,
                                        work.description,
                                      ]);
                                    }
                                  }}
                                  style={{
                                    color: "gray",
                                    cursor: "pointer",
                                    marginRight: "25px",
                                    marginTop: "2px",
                                  }}
                                />
                              </span>
                            </span>
                          ) : (
                            <span
                              style={{ width: "80%", cursor: "pointer" }}
                              onClick={() => {
                                // console.log('gng to boq page from the index', work)
                                history.push("/ProjectPage/" + data.ProjectId, {
                                  data: data,
                                  fromUnreleased: true,
                                  indexArr: work["indexces"],
                                });
                              }}
                            >
                              {work["Unit Name"]} |{" "}
                              <span className="desc-highlight-brand">
                                {work.description
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                              </span>
                            </span>
                          )}
                          <p>{work.vendorCategory}</p>
                        </div>
                      </div>
                      <p style={{ width: "10%" }}>
                        {work.quantity.length == 0 ? 0 : work.quantity}
                      </p>
                      <p style={{ width: "10%" }}>{work.unit}</p>
                      <p style={{ width: "10%" }}>
                        {"\u20B9 "}
                        {work.rate}
                      </p>
                      <p style={{ width: "10%" }}>{work.gst} %</p>
                      <p style={{ width: "10%" }}>
                        {"\u20B9 "}
                        {(
                          work.quantity * work.rate +
                            work.quantity * work.rate * (work.gst / 100) || 0
                        ).toFixed(2)}
                      </p>
                    </div>
                  )
              )}

            {activeCategory !== "ApprovalOrders" &&
              materials.map(
                (material, mIndex) =>
                  material.status != "ordered" &&
                  material.status != "inApproval" &&
                  material.rate != "quotation" &&
                  (activeCategory == material.type ||
                    activeCategory == "All") &&
                  (material.type
                    ?.toLowerCase()
                    .includes(searchItems?.toLowerCase()) ||
                    material.specification
                      ?.toLowerCase()
                      .includes(searchItems?.toLowerCase())) &&
                  (workType == "Only Material" || workType == "All") && (
                    <div
                      tabIndex="-1"
                      id={`mIndex ${material["indexces"]["componentIndex"]} ${material["indexces"]["materialIndex"]}`}
                      key={mIndex}
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        margin: "10px 0px",
                      }}
                    >
                      {activeCategory != "All" &&
                        !draftsOrderId.includes(material.OrderId) && (
                          <Tooltip content="Click to select material">
                            <input
                              type="checkbox"
                              checked={checkedItems.includes(`m${mIndex}`)}
                              style={{ minHeight: "24px", minWidth: "24px" }}
                              onChange={() => {
                                setCheckedItems((st) =>
                                  st.includes(`m${mIndex}`)
                                    ? st.filter((x) => x !== `m${mIndex}`)
                                    : [...st, `m${mIndex}`]
                                );
                                setSelectedMaterials((st) =>
                                  st.some(
                                    (x) => x.indexces == material.indexces
                                  )
                                    ? st.filter(
                                        (x) => x.indexces != material.indexces
                                      )
                                    : [...st, material]
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 50,
                          width: 50,
                          margin: 20,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: "50%",
                          borderColor: "#FDD34D",
                        }}
                      >
                        <FiTruck size={20} />
                      </div>
                      <div style={{ width: "40%" }}>
                        <div style={{ width: "380px" }}>
                          {draftsOrderId.includes(material.OrderId) && (
                            <p style={{ color: "#4685D4", marginBottom: 5 }}>
                              Already in draft
                              <span
                                onClick={() => {
                                  console.log("Go to draft");
                                  if (material?.AssociatedID) {
                                    setSpinner(true);
                                    draftService
                                      .getDraftByDraftId(material?.AssociatedID)
                                      .then((res) => {
                                        console.log(
                                          "response of single draft",
                                          res
                                        );
                                        history.push(
                                          "/OrdersQuotations/" + data.ProjectId,
                                          {
                                            data: {
                                              ...data,
                                              rooms: data.rooms,
                                              ProjectId: data.ProjectId,
                                              ClientName: data.ClientName,
                                              Address: data.Address,
                                              AmountRecieved:
                                                data.AmountReceived,
                                              SiteEngineerId:
                                                data.SiteEngineersId,
                                            },
                                            draft: res.payload[0],
                                          }
                                        );
                                      });
                                  } else {
                                    toast.warn("This is an Old Draft ");
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                (Go to Draft
                                <OpenInNewIcon
                                  style={{ fill: "gray", fontSize: "0.85em" }}
                                  titleAccess="Go to Draft"
                                  fontSize="smaller"
                                />
                                )
                              </span>
                            </p>
                          )}
                          {material.specification.length > 100 ? (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                style={{ width: "90%", cursor: "pointer" }}
                                onClick={() => {
                                  history.push(
                                    "/ProjectPage/" + data.ProjectId,
                                    {
                                      data: data,
                                      fromUnreleased: true,
                                      materialItem: true,
                                      indexArr: material["indexces"],
                                    }
                                  );
                                }}
                              >
                                {showMore.includes(material.specification) ? (
                                  <span>
                                    {material["Unit Name"]} |{" "}
                                    <span className="desc-highlight-brand">
                                      {" "}
                                      {material.specification
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')}
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {material["Unit Name"]} |{" "}
                                    <span className="desc-highlight-brand">
                                      {material.specification
                                        .replace(/<new-line>/g, "\n")
                                        .replace(/<single-quote>/g, "'")
                                        .replace(/<double-quote>/g, '"')
                                        .substring(0, 50)}
                                    </span>
                                    ....
                                  </span>
                                )}
                              </span>
                              <span>
                                <AiOutlineInfoCircle
                                  title={
                                    showMore.length > 0
                                      ? "showless"
                                      : "showmore"
                                  }
                                  onClick={() => {
                                    if (
                                      showMore.includes(material.specification)
                                    ) {
                                      setShowMore(
                                        showMore.filter(
                                          (item) =>
                                            item !== material.specification
                                        )
                                      );
                                    } else {
                                      setShowMore([
                                        ...showMore,
                                        material.specification,
                                      ]);
                                    }
                                  }}
                                  style={{ color: "gray", cursor: "pointer" }}
                                />
                              </span>
                            </span>
                          ) : (
                            <span
                              style={{ width: "80%", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/ProjectPage/" + data.ProjectId, {
                                  data: data,
                                  fromUnreleased: true,
                                  materialItem: true,
                                  indexArr: material["indexces"],
                                });
                              }}
                            >
                              {material["Unit Name"]} |{" "}
                              <span className="desc-highlight-brand">
                                {material.specification
                                  .replace(/<new-line>/g, "\n")
                                  .replace(/<single-quote>/g, "'")
                                  .replace(/<double-quote>/g, '"')}
                              </span>
                            </span>
                          )}
                          <p>{material.type}</p>
                        </div>
                      </div>
                      <p style={{ width: "10%" }}>
                        {material.quantity.length == 0 ? 0 : material.quantity}
                      </p>
                      <p style={{ width: "10%" }}>{material.unit}</p>
                      <p style={{ width: "10%" }}>
                        {"\u20B9 "}
                        {material.rate}
                      </p>
                      <p style={{ width: "10%" }}>{material.gst} %</p>
                      <p style={{ width: "10%" }}>
                        {"\u20B9 "}
                        {(
                          material.quantity * material.rate +
                            material.quantity *
                              material.rate *
                              (material.gst / 100) || 0
                        ).toFixed(2)}
                      </p>
                    </div>
                  )
              )}
            {backToTop && (
              <button
                id="btn"
                title="back to top"
                onClick={backToTopHandler}
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: "#2f4858",
                  marginTop: "5px",
                  position: "fixed",
                  bottom: "4%",
                  left: "95%",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                <BiUpArrowAlt size={30} style={{ marginTop: "2px" }} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default drawerTemplate1(UnreleasedItems);
