import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Form from "./Form";

const ModalDialog = ({ open, handleClose, setMaterialCategories }) => {
  return (
    // props received from App.js
    <Dialog open={open} onClose={handleClose}>
      {/* form to be created */}
      <Form
        handleClose={handleClose}
        setMaterialCategories={setMaterialCategories}
      />
    </Dialog>
  );
};

export default ModalDialog;
