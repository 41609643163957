import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { BsThreeDots } from "react-icons/bs";
import { useAnalyticsContext, TAGS } from "../../Context/Analytics";
import { useRemoteConfig } from "../../Context/RemoteConfig";

export default ({
  ClientName,
  onClick,
  Address,
  Finance,
  ProjectId,
  DuplicateCard,
  DeleteCard,
}) => {
  const { theme, text } = useRemoteConfig();

  const { record } = useAnalyticsContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      id="orderCard"
      style={{
        backgroundColor: theme.dashbaordpage.OrderCard.style.backgroundColor,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "260px",
        borderRadius: "8px",
        padding: "20px",
        gap: "10px",
      }}
    >
      <div
        onClick={onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
        }}
      >
        <h5>{ClientName}</h5>
        <p style={{ fontSize: "12px", marginTop: "10%" }}>
          {Address.replace(/<new-line>/g, "\n")
            .replace(/<single-quote>/g, "'")
            .replace(/<double-quote>/g, '"')}
        </p>
      </div>
      <div
        style={{
          width: "10%",
        }}
      >
        <BsThreeDots size={22} color={"grey"} onClick={handleClick} />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              record(
                TAGS[
                  ("@duplicateProject_button_click_success",
                  { description: "Duplicate project button click success" })
                ]
              );
              DuplicateCard(ProjectId);
              handleClose();
            }}
          >
            Duplicate
          </MenuItem>
          <MenuItem
            onClick={() => {
              record(
                TAGS[
                  ("@deleteProject_button_click_success",
                  { description: "Delete project button click success" })
                ]
              );
              DeleteCard(ProjectId);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open(
                "https://staartlive.netlify.app?ProjectId=" + ProjectId
              );
              handleClose();
            }}
          >
            Live
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};
