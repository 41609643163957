import config from "../config/config";

class UtilService {
  async sendOtp(mail) {
    try {
      const response = await (
        await fetch(`${config.utilService}mail`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            to: mail,
          }),
        })
      ).json();
      console.log("Response from changePassword API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }

  async verify(Id, otp) {
    try {
      const response = await (
        await fetch(`${config.utilService}verify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Id,
            otp,
          }),
        })
      ).json();
      console.log("Response from changePassword API: ", response);
      return response;
    } catch (e) {
      console.log(e);
      return { status: 400, statusMsg: e };
    }
  }
}

export default UtilService;
